import React, { useRef } from 'react';

import { Box, Flex, HStack, useToast } from '@chakra-ui/react';
import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  formatISO,
  set,
} from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import { useMutation } from 'react-query';

import promos, { VoucherUpdatePayload } from 'api/promos';
import { DateButton } from 'components/modules/VoucherAddNew/VoucherAddNew.style';
import AppButton from 'components/primitives/Button';
import FormikDatePicker from 'components/primitives/FormikDatePicker/FormikDatePicker.view';
import FormikRadio from 'components/primitives/FormikRadio';
import FormikTextField from 'components/primitives/FormikTextField';
import FormikTimePicker from 'components/primitives/FormikTimePicker/FormikTimePicker.view';
import Typography from 'components/primitives/Typography';
import { ApiResponseError } from 'types/Api';
import { Promo } from 'types/Promos';

import { EditForm, VoucherDetailsEditProps } from './VoucherDetailsEdit.props';

const transformToPayload = (
  data: Promo,
  form: EditForm
): VoucherUpdatePayload => ({
  code: data.code !== form.code ? form.code : undefined,
  maxPerUser:
    data.maxPerUser !== Number(form.maxPerUser)
      ? Number(form.maxPerUser)
      : undefined,
  maxUseCount:
    data.maxUseCount !== Number(form.maxUseCount)
      ? Number(form.maxUseCount)
      : undefined,
  status: data.status !== form.status ? form.status : undefined,
  expiredAt: data.expiredAt !== form.expiredAt ? form.expiredAt : undefined,
});

const VoucherDetailsEditView = (
  props: VoucherDetailsEditProps
): JSX.Element => {
  const toast = useToast();
  const formRef = useRef<FormikProps<any>>(null);

  const { isLoading, mutate } = useMutation(
    (fields: EditForm) =>
      promos.doUpdate(props.data.id, transformToPayload(props.data, fields)),
    {
      onSuccess: () => {
        toast({
          description: 'Voucher updated successfully.',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
        props.onBack();
        props.refetch();
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const onClickDate = (option: number) => {
    const form = formRef.current;
    if (!form) return;

    if (option === 1)
      return form.setFieldValue(
        'expiredAt',
        formatISO(addHours(addDays(new Date(), 1), 1))
      );
    if (option === 2)
      form.setFieldValue('expiredAt', formatISO(addDays(new Date(), 2)));
    if (option === 3)
      form.setFieldValue('expiredAt', formatISO(addDays(new Date(), 3)));
    if (option === 4)
      form.setFieldValue('expiredAt', formatISO(addWeeks(new Date(), 1)));
    if (option === 5)
      form.setFieldValue('expiredAt', formatISO(addMonths(new Date(), 1)));
  };

  return (
    <Formik<EditForm>
      innerRef={formRef}
      initialValues={{
        code: props.data.code,
        maxPerUser: props.data.maxPerUser || 1,
        maxUseCount: props.data.maxUseCount || 1,
        status: props.data.status,
        expiredAt: props.data.expiredAt,
      }}
      onSubmit={(fields) => mutate(fields)}
    >
      <Form>
        <Flex mt={3} flexDir="column" border="1px solid #ddd">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px={4}
            py={4}
            borderBottom="1px solid #ddd"
          >
            <Typography variant="type8" color="shade5" weight="600">
              EDIT VOUCHER
            </Typography>
          </Flex>
          <Flex flexDirection="column" p={5}>
            <Flex mt={8} maxW="350px" flexDir="column">
              <FormikTextField fieldName="code" label="VOUCHER CODE" />
              <FormikTextField
                fieldName="maxUseCount"
                label="MAX USE PER CODE"
              />
              <FormikTextField
                fieldName="maxPerUser"
                label="MAX USE PER USER"
              />
              <Flex gridGap="10">
                <FormikDatePicker
                  flatpickrProps={{
                    options: {
                      minDate: set(addDays(new Date(), 1), {
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                        milliseconds: 0,
                      }),
                    },
                  }}
                  fieldName="expiredAt"
                  textFieldProps={{
                    label: 'EXPIRY DATE in NZ',
                  }}
                />
                <FormikTimePicker
                  fieldName="expiredAt"
                  textFieldProps={{
                    label: 'EXPIRY TIME in NZ',
                  }}
                />
              </Flex>
              <Flex gridGap="5" mb={4}>
                <DateButton onClick={() => onClickDate(1)}>1day</DateButton>
                <DateButton onClick={() => onClickDate(2)}>2days</DateButton>
                <DateButton onClick={() => onClickDate(3)}>3days</DateButton>
                <DateButton onClick={() => onClickDate(4)}>1week</DateButton>
                <DateButton onClick={() => onClickDate(5)}>1month</DateButton>
              </Flex>
              <Box mb={2}>
                <Typography variant="type9" color="shade5">
                  STATUS
                </Typography>
              </Box>
              <Flex gridGap="10">
                <FormikRadio
                  checkedValue="active"
                  fieldName="status"
                  label="Active"
                />
                <FormikRadio
                  checkedValue="inactive"
                  fieldName="status"
                  label="Deactivated"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end" mt={5}>
          <HStack spacing={5}>
            <AppButton
              type="submit"
              loading={isLoading}
              disabled={isLoading}
              variant="small"
            >
              Save
            </AppButton>
            <AppButton
              onClick={props.onBack}
              kind="secondary"
              variant="small"
              disabled={isLoading}
            >
              Cancel
            </AppButton>
          </HStack>
        </Flex>
      </Form>
    </Formik>
  );
};

export default VoucherDetailsEditView;
