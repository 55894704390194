export const YELSA_APP = 'https://app.yelsaconnect.co.nz';
export const YELSA_SUPPORT = 'support@yelsa.co.nz';
export const YELSA_WEBSITE = 'https://www.yelsaconnect.co.nz';
export const YELSA_HELP = 'https://www.yelsaconnect.co.nz/help';
export const YELSA_POLICY = 'https://www.yelsaconnect.co.nz/privacy-policy/';
export const YELSA_TERMS =
  'https://www.yelsaconnect.co.nz/terms-and-conditions/';
export const YELSA_FACEBOOK_PAGE = 'https://www.facebook.com/yelsaconnect';
export const YELSA_GOOGLE_PLAY_STORE =
  'https://play.google.com/store/apps/details?id=com.yelsa';
export const YELSA_APP_STORE =
  'https://apps.apple.com/ph/app/yelsa-connect/id1491482377';
