import React from 'react';

import Pagination from 'rc-pagination';
//@ts-ignore
import locale from 'rc-pagination/lib/locale/en_US';
import Select from 'rc-select';

import { PaginationProps } from './Pagination.props';
import 'rc-pagination/assets/index.css';
import './rc-pagination.css';
import './rc-select.css';

const PaginationView = (props: PaginationProps): JSX.Element => {
  return (
    <Pagination
      showSizeChanger
      selectComponentClass={Select}
      showQuickJumper
      showLessItems
      locale={locale}
      showTotal={(total, range) =>
        `${range[0]} - ${range[1]} of ${total} items`
      }
      {...props}
    />
  );
};

export default PaginationView;
