import React, { useRef } from 'react';

import { Box, Flex, HStack, useToast } from '@chakra-ui/react';
import { Form, Formik, FormikProps } from 'formik';
import { useMutation } from 'react-query';

import charities from 'api/charities';
import {
  AddForm,
  CharityAddNewProps,
} from 'components/modules/CharityAddNew/CharityAddNew.props';
import AppButton from 'components/primitives/Button';
import FormikRadio from 'components/primitives/FormikRadio';
import FormikTextField from 'components/primitives/FormikTextField';
import Typography from 'components/primitives/Typography';
import { ApiResponseError } from 'types/Api';

import AppModal from '../AppModal';
import { Schema } from './CharityAddNew.utils';

const CharityAddNewView = (props: CharityAddNewProps): JSX.Element => {
  const toast = useToast();
  const formRef = useRef<FormikProps<any>>(null);

  const { isLoading, mutate } = useMutation(
    (fields: AddForm) => charities.doCreate(fields as any),
    {
      onSuccess: () => {
        toast({
          description: 'Charity created successfully.',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
        props.onClose();
        props.refetch();
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  return (
    <AppModal {...props} size="xl" title="CREATE NEW CHARITY">
      <Formik<AddForm>
        innerRef={formRef}
        initialValues={{
          name: '',
          priority: 1,
          status: 'active',
        }}
        validateOnChange={false}
        validationSchema={Schema}
        onSubmit={(fields) => mutate(fields)}
      >
        <Form>
          <Flex flexDirection="column" p={2}>
            <Flex mt={2} maxW="350px" flexDir="column">
              <FormikTextField fieldName="name" label="CHARITY NAME" />
              <FormikTextField
                type="number"
                fieldName="priority"
                label="PRIORITY"
              />
              <Box mb={2}>
                <Typography variant="type9" color="shade5">
                  STATUS
                </Typography>
              </Box>
              <Flex gridGap="10">
                <FormikRadio
                  checkedValue="active"
                  fieldName="status"
                  label="Active"
                />
                <FormikRadio
                  checkedValue="inactive"
                  fieldName="status"
                  label="Deactivated"
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end" mt={5}>
            <HStack spacing={5}>
              <AppButton
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                variant="small"
              >
                Save
              </AppButton>
              <AppButton
                onClick={props.onClose}
                kind="secondary"
                variant="small"
                disabled={isLoading}
              >
                Cancel
              </AppButton>
            </HStack>
          </Flex>
        </Form>
      </Formik>
    </AppModal>
  );
};

export default CharityAddNewView;
