import React, { useMemo } from 'react';

import { ArrowBackIcon, ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { CircularProgress, Flex } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import propertiesApi from 'api/properties';
import AppModal from 'components/modules/AppModal';
import BuyListing from 'components/modules/BuyListing';
import SellListing from 'components/modules/SellListing';
import { FETCH_LISTING_SUMMARY } from 'constants/queries';
import { BuyListingSummary, SellListingSummary } from 'types/Property';
import getFullPropertyTitle from 'utils/getFullPropertyTitle';
import { useTheme } from 'utils/theme';

import { ListingProps } from './Listing.props';

const ListingView = ({ id, ...props }: ListingProps): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const { data, isLoading, refetch } = useQuery(
    `${FETCH_LISTING_SUMMARY}${id}`,
    () => (id ? propertiesApi.getListing(id) : undefined)
  );

  const Content = useMemo(() => {
    if (isLoading) {
      return (
        <Flex
          width="100%"
          height="70vh"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress
            isIndeterminate
            size="50px"
            color={theme.colors.primary}
          />
        </Flex>
      );
    }
    if (data && data.type === 'sell')
      return (
        <SellListing refetch={refetch} data={data as SellListingSummary} />
      );
    if (data && data.type === 'buy')
      return <BuyListing data={data as BuyListingSummary} refetch={refetch} />;
    return null;
  }, [isLoading, data]);

  if (!data) return <div />;

  return (
    <AppModal
      {...props}
      size="5xl"
      HeaderRight={
        <Flex
          position="absolute"
          top={4}
          right={16}
          zIndex={200}
          cursor="pointer"
          onClick={history.goBack}
        >
          <ArrowBackIcon />
        </Flex>
      }
      title={
        data.type === 'sell'
          ? getFullPropertyTitle(data.title, data.location)
          : data.user
          ? `Property Search by ${data.user.firstName} ${data.user.lastName}`
          : 'N/A'
      }
    >
      {!props.disableNext && (
        <Flex
          position="absolute"
          right="-150px"
          top="40vh"
          width="60px"
          height="60px"
          borderRadius="30px"
          zIndex="100"
          backgroundColor="#fff"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={props.onNext}
        >
          <ArrowRightIcon />
        </Flex>
      )}
      {!props.disablePrev && (
        <Flex
          position="absolute"
          left="-150px"
          top="40vh"
          width="60px"
          height="60px"
          borderRadius="30px"
          zIndex="100"
          backgroundColor="#fff"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={props.onPrev}
        >
          <ArrowLeftIcon />
        </Flex>
      )}
      {Content}
    </AppModal>
  );
};

export default ListingView;
