import React, { useMemo } from 'react';

import { Flex, HStack } from '@chakra-ui/layout';
import { format } from 'date-fns';

import StaticTable from 'components/layouts/StaticTable';
import AppButton from 'components/primitives/Button';
import Typography from 'components/primitives/Typography';

import { UserTransactionsProps } from './UserTransactions.props';

export const transactionHistoryColumns = [
  'Transaction Type',
  'Payment',
  'Executed By',
  'Executed On',
];

const UserTransactionsView = (props: UserTransactionsProps): JSX.Element => {
  const transactionHistorySummary = useMemo(
    () =>
      (props.data?.data || []).map((tr) => [
        tr.label,
        `$${tr.amount}`,
        tr.executedBy.displayName,
        format(new Date(tr.createdAt), 'dd/MM/y HH:mm:ss a'),
      ]),
    [props.data]
  );

  return (
    <>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            TRANSACTION HISTORY ({0})
          </Typography>
        </Flex>
        <StaticTable
          columns={transactionHistoryColumns}
          data={transactionHistorySummary}
        />
      </Flex>
      <Flex justifyContent="flex-end" mt={5}>
        <HStack spacing={5}>
          <AppButton onClick={props.onBack} kind="secondary" variant="small">
            Go Back
          </AppButton>
        </HStack>
      </Flex>
    </>
  );
};

export default UserTransactionsView;
