import api from 'api/instance';
import { Charity, CharityDonations } from 'types/Charities';

const URL = `${process.env.REACT_APP_API_URL}/admin/charities`;

export type GetAllCharitiesResponse = {
  data: Charity[];
  meta: {
    count: number;
    page: number;
    limit: number;
  };
};

const getAll = async (payload?: string): Promise<GetAllCharitiesResponse> => {
  const { data } = await api.get<GetAllCharitiesResponse>(`${URL}${payload}`);
  return data;
};

export type CharityDetails = {
  charity: Charity;
  donations: CharityDonations[];
};

const getDetails = async (id?: string): Promise<CharityDetails> => {
  const { data } = await api.get<{
    data: CharityDetails;
  }>(`${URL}/${id}`);
  return data.data;
};

export type CharityNewPayload = {
  name: string;
  status: string;
  priority: number;
};

const doCreate = async (fields: CharityNewPayload): Promise<void> => {
  const { data } = await api.post(`${URL}`, fields);
  return data;
};

export type CharityUpdatePayload = {
  name?: string;
  status?: string;
  priority?: number;
};

const doUpdate = async (
  id: string,
  fields: CharityUpdatePayload
): Promise<void> => {
  console.log(fields);
  const { data } = await api.post(`${URL}/${id}`, fields);
  return data;
};

export default {
  getAll,
  getDetails,
  doCreate,
  doUpdate,
};
