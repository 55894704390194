import api from 'api/instance';
import { Company } from 'types/Agent';

const URL = `${process.env.REACT_APP_API_URL}/`;

const getCompanies = async (): Promise<Company[]> => {
  const { data } = await api.get<{ data: Company[] }>(
    `${URL}/options/companies`
  );
  return data.data;
};

export default {
  getCompanies,
};
