import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const Listing = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color } = props;

  return (
    <svg width={width || '20'} height={height || '20'} viewBox={`0 0 20 20`}>
      <g id="Listings-Icon-SideMenu" transform="translate(-29 -128)">
        <rect
          id="IconBounds"
          width="20"
          height="20"
          transform="translate(29 128)"
          fill="none"
        />
        <path
          id="Listings-Icon-SideMenu-2"
          data-name="Listings-Icon-SideMenu"
          d="M1.5,15a1.5,1.5,0,1,1,0-3h17a1.5,1.5,0,0,1,0,3Zm0-6a1.5,1.5,0,1,1,0-3h17a1.5,1.5,0,0,1,0,3Zm0-6a1.5,1.5,0,0,1,0-3h17a1.5,1.5,0,0,1,0,3Z"
          transform="translate(29 131)"
          fill={theme.colors[color || 'primary']}
        />
      </g>
    </svg>
  );
};

export default Listing;
