/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo, useRef } from 'react';

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  CircularProgress,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { isEmpty } from 'ramda';
import { useLocation } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';

import AgentDetails from 'components/modules/AgentDetails';
import PaginationView from 'components/primitives/Pagination';
import { Search, Listing as ListingIcon } from 'components/primitives/SVG';
import Typography from 'components/primitives/Typography';
import ucFirst from 'utils/string/ucFirst';
import { useTheme } from 'utils/theme';

import { AgentApplicationsGeneratedProps } from './AgentApplications.props';
import { FilterOption } from './AgentApplications.style';

const widthPerAccessor = {
  undefined: undefined,
};

const headerText: Record<string, string> = {
  all: 'ALL APPLICATIONS',
  accepted: 'ALL ACCEPTED APPLICATIONS',
  declined: 'ALL DECLINED APPLICATIONS',
  pending: 'ALL PENDING APPLICATIONS',
  suspended: 'ALL SUSPENDED APPLICATIONS',
};

const AgentApplicationsView = ({
  data,
  meta,
  isLoading,
  tableOptions,
  setTableOptions,
  ...props
}: AgentApplicationsGeneratedProps): JSX.Element => {
  const theme = useTheme();
  const location = useLocation();
  const searchRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const filter = useMemo(
    () => query.get('filter_status')?.toUpperCase(),
    [location.search]
  );
  const search = useMemo(() => query.get('search'), [location.search]);
  const id = useMemo(() => query.get('id'), [location.search]);

  const currentIndex = useMemo(
    () => (id ? data.findIndex((d) => d.id === id) : -1),
    [data, id]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Agent Name',
        accessor: 'name',
        Cell: ({ row }: any) =>
          `${row.original.firstName} ${row.original.lastName}`,
      },
      {
        Header: 'Region',
        accessor: 'region',
        Cell: ({ row }: any) => `${ucFirst(row.original.region)}`,
      },
      {
        Header: 'Company',
        accessor: 'company',
      },
      {
        Header: 'Date Applied',
        accessor: 'dateApplied',
        Cell: ({ row }: any) =>
          format(new Date(row.original.dateApplied), 'MM/dd/y kk:mm:ss a'),
      },
      {
        Header: 'Date Accepted',
        accessor: 'dateAccepted',
        Cell: ({ row }: any) => {
          if (row.original.dateAccepted) {
            return format(
              new Date(row.original.dateAccepted),
              'MM/dd/y kk:mm:ss a'
            );
          }
          return '-';
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  );

  const onPrev = () => {
    if (currentIndex > 0) props.setAgent(data[currentIndex - 1]);
  };

  const onNext = () => {
    if (currentIndex < data.length - 1) {
      props.setAgent(data[currentIndex + 1]);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      data,
      // @ts-ignore
      columns,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortBy: isLoading,
      initialState: {
        sortBy: [
          {
            id: 'dateApplied',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );

  const onPaginate = (page: number, pageSize: number) => {
    setTableOptions((prevState) => {
      return {
        ...prevState,
        page: prevState.limit !== pageSize ? 1 : page,
        limit: pageSize,
      };
    });
  };

  useEffect(() => {
    setTableOptions((prevState) => {
      if (!isEmpty(sortBy)) {
        const { id, desc } = sortBy[0];
        return {
          ...prevState,
          sort: id,
          direction: desc ? 'desc' : 'asc',
        };
      } else if (isEmpty(sortBy) && !!prevState.sort) {
        const psCopy = { ...prevState };
        delete psCopy.sort;
        delete psCopy.direction;

        return psCopy;
      }

      return prevState;
    });
  }, [sortBy]);

  return (
    <Flex flexDirection="column">
      <Flex flex={1}>
        <Typography variant="type5" weight="700" color="primary">
          AGENTS
        </Typography>
        <HStack mt={1} ml={20} spacing={10} alignItems="flex-start">
          <FilterOption
            borderBottom={`5px solid ${
              !search && !filter ? theme.colors.special4 : 'transparent'
            }`}
            onClick={() => {
              searchRef.current.value = '';
              props.onClearFilter();
            }}
          >
            <Typography
              variant="type8"
              weight="600"
              color={!location.search && !filter ? 'primary' : 'shade1'}
            >
              All
            </Typography>
          </FilterOption>
          <FilterOption
            onClick={() => props.onSetFilter('accepted', 'filter')}
            borderBottom={`5px solid ${
              filter === 'APPROVED' ? theme.colors.special4 : 'transparent'
            }`}
          >
            <Typography
              variant="type8"
              weight="600"
              color={filter === 'APPROVED' ? 'primary' : 'shade1'}
            >
              Accepted
            </Typography>
          </FilterOption>
          <FilterOption
            onClick={() => props.onSetFilter('declined', 'filter')}
            borderBottom={`5px solid ${
              filter === 'REJECTED' ? theme.colors.special4 : 'transparent'
            }`}
          >
            <Typography
              variant="type8"
              weight="600"
              color={filter === 'REJECTED' ? 'primary' : 'shade1'}
            >
              Declined
            </Typography>
          </FilterOption>
          <FilterOption
            onClick={() => props.onSetFilter('pending', 'filter')}
            borderBottom={`5px solid ${
              filter === 'PENDING' ? theme.colors.special4 : 'transparent'
            }`}
          >
            <Typography
              variant="type8"
              weight="600"
              color={filter === 'PENDING' ? 'primary' : 'shade1'}
            >
              Pending
            </Typography>
          </FilterOption>
          <FilterOption
            onClick={() => props.onSetFilter('pending_update', 'filter')}
            borderBottom={`5px solid ${
              filter === 'PENDING_UPDATE'
                ? theme.colors.special4
                : 'transparent'
            }`}
          >
            <Typography
              variant="type8"
              weight="600"
              color={filter === 'PENDING_UPDATE' ? 'primary' : 'shade1'}
            >
              Pending Update
            </Typography>
          </FilterOption>
          <FilterOption
            onClick={() => props.onSetFilter('suspended', 'filter')}
            borderBottom={`5px solid ${
              filter === 'SUSPENDED' ? theme.colors.special4 : 'transparent'
            }`}
          >
            <Typography
              variant="type8"
              weight="600"
              color={filter === 'SUSPENDED' ? 'primary' : 'shade1'}
            >
              Suspended
            </Typography>
          </FilterOption>
        </HStack>
      </Flex>
      <Flex
        mt={5}
        flex={1}
        borderRadius={10}
        p={5}
        minHeight="500px"
        bg="#fff"
        filter="drop-shadow(0px 2px 10px rgba(149, 149, 149, 0.18))"
        flexDirection="column"
      >
        <Flex
          flexDir="row"
          width="100%"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Flex height="30px" alignItems="center">
            <ListingIcon color="shade10" width={12} height={12} />
            <Flex mx={3}>
              <Typography variant="type8" weight="600" color="shade10">
                {headerText[filter || 'all']}
              </Typography>
            </Flex>
            {isLoading ? (
              <CircularProgress isIndeterminate size="20px" color="primary" />
            ) : (
              <Typography variant="type10" color="shade5">
                {meta.count} agent{meta.count > 1 && 's'}
              </Typography>
            )}
          </Flex>
          <HStack spacing={5}>
            <InputGroup size="sm" width={300}>
              <InputLeftAddon borderRadius={10} backgroundColor="transparent">
                <Search width="14px" height="14px" />
              </InputLeftAddon>
              <Input
                ref={searchRef}
                onChange={(a) => props.onSearchKey(a.target.value)}
                borderLeft="none"
                placeholder="Search"
                borderRadius={10}
              />
            </InputGroup>
          </HStack>
        </Flex>
        <Table size="sm" mt={7} {...getTableProps()}>
          <Thead backgroundColor="shade12">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {
                      // @ts-ignore
                      ...column.getHeaderProps(column.getSortByToggleProps())
                    }
                  >
                    <Flex flexDirection="row">
                      <Typography variant="type9" weight="600" color="shade5">
                        {column.Header}
                      </Typography>
                      <Flex ml={2}>
                        {
                          // @ts-ignore
                          column.isSorted ? (
                            <Box>
                              {
                                // @ts-ignore
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              }
                            </Box>
                          ) : (
                            <Box width="12px" height="5px" />
                          )
                        }
                      </Flex>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  backgroundColor={i % 2 !== 0 ? 'shade18' : 'transparent'}
                  cursor="pointer"
                  onClick={() => props.setAgent(row.original)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      // @ts-ignore
                      width={widthPerAccessor[cell.column.id]}
                    >
                      <Typography variant="type8" color="shade5">
                        {cell.render('Cell')}
                      </Typography>
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <AgentDetails
          id={id}
          onClose={() => props.setAgent(undefined)}
          onNext={onNext}
          onPrev={onPrev}
          isOpen={Boolean(id)}
          disablePrev={currentIndex === 0}
          disableNext={currentIndex === data.length - 1}
        />
        <Flex mt={5} flexDir="row" width="100%" justifyContent="flex-end">
          <PaginationView
            disabled={isLoading}
            total={meta.count}
            current={meta.page}
            pageSize={tableOptions.limit}
            onChange={onPaginate}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AgentApplicationsView;
