/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo, useRef } from 'react';

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Box,
  CircularProgress,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { isEmpty } from 'ramda';
import { useLocation } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';

import Draft from 'components/modules/Draft';
import DraftFilters from 'components/modules/DraftFilters';
import DraftListingNew from 'components/modules/DraftListingNew';
import Button from 'components/primitives/Button';
import PaginationView from 'components/primitives/Pagination';
import { Search, Listing as ListingIcon } from 'components/primitives/SVG';
import Typography from 'components/primitives/Typography';
import { useTheme } from 'utils/theme';

import { DraftsGeneratedProps } from './Drafts.props';
import { FilterOption } from './Drafts.style';

const widthPerAccessor = {
  undefined: undefined,
};

const columns = [
  {
    Header: 'Property Type',
    accessor: 'propertyType',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Date Claimed',
    accessor: 'claimedAt',
  },
];

const headerText: Record<string, string> = {
  all: 'ALL DRAFTS',
  CREATED: 'ALL CLAIMED DRAFTS',
  FOR_CLAIM: 'ALL UNCLAIMED DRAFTS',
};

const DraftsView = (props: DraftsGeneratedProps): JSX.Element => {
  const theme = useTheme();
  const location = useLocation();
  const filterModal = useDisclosure();
  const newDraftModal = useDisclosure();
  const searchRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const status = useMemo(() => query.get('status'), [location.search]);
  const id = useMemo(() => query.get('id'), [location.search]);

  const currentIndex = useMemo(
    () => (id ? props.data.findIndex((d) => d.id === id) : -1),
    [props.data, id]
  );

  const onPrev = () => {
    if (currentIndex > 0) props.setDraft(props.data[currentIndex - 1].original);
  };
  const onNext = () => {
    if (currentIndex < props.data.length - 1)
      props.setDraft(props.data[currentIndex + 1].original);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      data: props.data,
      // @ts-ignore
      columns,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortBy: props.isLoading,
    },
    useSortBy
  );

  const onPaginate = (page: number, pageSize: number) => {
    props.setTableOptions((prevState) => {
      return {
        ...prevState,
        page: prevState.limit !== pageSize ? 1 : page,
        limit: pageSize,
      };
    });
  };

  useEffect(() => {
    props.setTableOptions((prevState) => {
      if (!isEmpty(sortBy)) {
        const { id, desc } = sortBy[0];
        return {
          ...prevState,
          sort: id,
          direction: desc ? 'desc' : 'asc',
        };
      } else if (isEmpty(sortBy) && !!prevState.sort) {
        const psCopy = { ...prevState };
        delete psCopy.sort;
        delete psCopy.direction;

        return psCopy;
      }

      return prevState;
    });
  }, [sortBy]);

  return (
    <Flex flexDirection="column">
      <Flex flex={1}>
        <Typography variant="type5" weight="700" color="primary">
          Drafts
        </Typography>
        <HStack mt={1} ml={20} spacing={10} alignItems="flex-start">
          <FilterOption
            borderBottom={`5px solid ${
              !location.search || location.search === '?'
                ? theme.colors.special4
                : 'transparent'
            }`}
            onClick={() => {
              searchRef.current.value = '';
              props.onClearFilter();
            }}
          >
            <Typography
              variant="type8"
              weight="600"
              color={
                !location.search || location.search === '?'
                  ? 'primary'
                  : 'shade1'
              }
            >
              All
            </Typography>
          </FilterOption>
          <FilterOption
            onClick={() => props.onSetFilter('CREATED', 'status')}
            borderBottom={`5px solid ${
              status === 'CREATED' ? theme.colors.special4 : 'transparent'
            }`}
          >
            <Typography
              variant="type8"
              weight="600"
              color={status === 'CREATED' ? 'primary' : 'shade1'}
            >
              Claimed
            </Typography>
          </FilterOption>
          <FilterOption
            onClick={() => props.onSetFilter('FOR_CLAIM', 'status')}
            borderBottom={`5px solid ${
              status === 'FOR_CLAIM' ? theme.colors.special4 : 'transparent'
            }`}
          >
            <Typography
              variant="type8"
              weight="600"
              color={status === 'FOR_CLAIM' ? 'primary' : 'shade1'}
            >
              Unclaimed
            </Typography>
          </FilterOption>
        </HStack>
        <Flex flex={1} justifyContent="flex-end">
          <Button variant="small" onClick={newDraftModal.onOpen}>
            Create New Listing
          </Button>
        </Flex>
      </Flex>
      <Flex
        mt={5}
        flex={1}
        borderRadius={10}
        p={5}
        minHeight="500px"
        bg="#fff"
        filter="drop-shadow(0px 2px 10px rgba(149, 149, 149, 0.18))"
        flexDirection="column"
      >
        <Flex
          flexDir="row"
          width="100%"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Flex height="30px" alignItems="center">
            <ListingIcon color="shade10" width={12} height={12} />
            <Flex mx={3}>
              <Typography variant="type8" weight="600" color="shade10">
                {headerText[status || 'all']}
              </Typography>
            </Flex>
            {props.isLoading ? (
              <CircularProgress
                isIndeterminate
                size="20px"
                color={theme.colors.primary}
              />
            ) : (
              <Typography variant="type10" color="shade5">
                {props.meta.count} Listings
              </Typography>
            )}
          </Flex>
          <HStack spacing={5}>
            <InputGroup size="sm" width={300}>
              <InputLeftAddon borderRadius={10} backgroundColor="transparent">
                <Search width="14px" height="14px" />
              </InputLeftAddon>
              <Input
                ref={searchRef}
                onChange={(a) => props.onSearchKey(a.target.value)}
                borderLeft="none"
                placeholder="Search"
                borderRadius={10}
              />
            </InputGroup>
            <Button variant="small" onClick={filterModal.onOpen}>
              Filter
            </Button>
          </HStack>
        </Flex>
        <Table size="sm" mt={7} {...getTableProps()}>
          <Thead backgroundColor="shade12">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {
                      // @ts-ignore
                      ...column.getHeaderProps(column.getSortByToggleProps())
                    }
                  >
                    <Flex flexDirection="row">
                      <Typography variant="type9" weight="600" color="shade5">
                        {column.Header}
                      </Typography>
                      <Flex ml={2}>
                        {
                          // @ts-ignore
                          column.isSorted ? (
                            <Box>
                              {
                                // @ts-ignore
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              }
                            </Box>
                          ) : (
                            <Box width="12px" height="5px" />
                          )
                        }
                      </Flex>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  backgroundColor={i % 2 !== 0 ? 'shade18' : 'transparent'}
                  cursor="pointer"
                  onClick={() => props.setDraft(row.original.original)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      // @ts-ignore
                      width={widthPerAccessor[cell.column.id]}
                    >
                      <Typography variant="type8" color="shade5">
                        {cell.render('Cell')}
                      </Typography>
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Flex mt={5} flexDir="row" width="100%" justifyContent="flex-end">
          <PaginationView
            disabled={props.isLoading}
            total={props.meta.count}
            current={props.meta.page}
            pageSize={props.tableOptions.limit}
            onChange={onPaginate}
          />
        </Flex>
      </Flex>
      <DraftListingNew
        {...newDraftModal}
        onClose={() => {
          newDraftModal.onClose();
          props.refetch();
        }}
      />
      <Draft
        id={id}
        isOpen={!!id}
        onClose={() => props.setDraft(undefined)}
        onPrev={onPrev}
        onNext={onNext}
        disablePrev={currentIndex === 0}
        disableNext={currentIndex === props.data.length - 1}
      />
      <DraftFilters {...filterModal} />
    </Flex>
  );
};

export default DraftsView;
