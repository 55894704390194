import { LoginPayload } from 'api/auth';
import { ApiResponseError } from 'types/Api';
import { Session } from 'types/Session';

import { SessionDomain } from './domain';

const login = SessionDomain.effect<LoginPayload, Session, ApiResponseError>();

const logout = SessionDomain.effect();

export default {
  login,
  logout,
};
