import React, { useCallback, useState } from 'react';

import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { arrayMoveImmutable } from 'array-move';
import axios from 'axios';
import { set } from 'date-fns';
import { Form, Formik } from 'formik';
import { isEmpty } from 'ramda';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import ImageViewer from 'react-simple-image-viewer';
import {
  SortableContainer,
  SortableElement,
  SortEnd,
} from 'react-sortable-hoc';

import imagesApi, { ImageContentType } from 'api/images';
import propertiestApi from 'api/properties';
import AppButton from 'components/primitives/Button';
import FormikDatePicker from 'components/primitives/FormikDatePicker';
import FormikNumberFormatField from 'components/primitives/FormikNumberFormatField/FormikNumberFormatField.view';
import FormikOption from 'components/primitives/FormikOptions';
import FormikRadio from 'components/primitives/FormikRadio';
import FormikSelect from 'components/primitives/FormikSelect';
import FormikTextArea from 'components/primitives/FormikTextArea';
import FormikTextField from 'components/primitives/FormikTextField';
import FormikTimePicker from 'components/primitives/FormikTimePicker';
import Label from 'components/primitives/Label';
import { Delete } from 'components/primitives/SVG';
import Typography from 'components/primitives/Typography';
import marketPrice from 'constants/marketPrice.json';
import {
  BedroomOptions,
  FenceOptions,
  GarageOptions,
  GlazingOptions,
  HeatingOptions,
  LandUnitOptions,
  LivingAreaOptions,
  MarketingMethodOptions,
  OtherFeaturesOptions,
  PRICE_BY_NEGOTIATION,
  PropertyTypesOptions,
  SET_DATE_OF_SALE,
  ToiletOptions,
  typesExcludingFencing,
  typesExcludingOptional,
  typesThatExcludeBedroom,
  typesThatExcludeGarage,
} from 'constants/property';
import { ApiResponseError } from 'types/Api';
import { SellFormValues } from 'types/Forms';
import { raceAll } from 'utils/functions/async';

import AppModal from '../AppModal';
import {
  FormProps,
  Photo,
  DraftListingNewProps,
  SortableListProps,
  SortablePhotoProps,
} from './DraftListingNew.props';
import {
  getDistricts,
  getRegions,
  getSuburbs,
  Schema,
  transformToDetails,
} from './DraftListingNew.utils';

const IMAGE_UPLOAD_LIMIT = 20;
const UPLOAD_TIME_OUT = 60000;
const ERROR_CODE = 'upload_failed';

const SellListingEditView = (props: DraftListingNewProps): JSX.Element => {
  const [images, setImages] = useState<Photo[]>([]);
  const [uploadProgress, setUploadProgress] = useState<Photo[]>([]);
  const [dropzoneError, setDropzoneError] = useState('');
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const toast = useToast();

  const { mutate: update, isLoading } = useMutation(
    async (values: FormProps) => {
      setUploadProgress([]);
      const uploads = await raceAll<Photo>(
        images,
        uploadItem,
        UPLOAD_TIME_OUT,
        // when an upload fails return with failed attribute
        (item) => ({
          ...item,
          failed: true,
        })
      );
      setImages(uploads);

      if (uploads.some((imgObj) => imgObj.failed)) throw Error(ERROR_CODE);

      const mainImageObj = uploads[0];

      return propertiestApi.doCreateDraft({
        ...transformToDetails(values as SellFormValues),
        mainImage: mainImageObj?.urls.path || '',
        images: uploads.slice(1).map((up) => up.urls.path),
      });
    },
    {
      onSuccess: () => {
        if (!isEmpty(images)) {
          images.forEach((img) => {
            if (img.file) {
              URL.revokeObjectURL(img.urls.medium);
              URL.revokeObjectURL(img.urls.path);
              URL.revokeObjectURL(img.urls.original);
              URL.revokeObjectURL(img.urls.thumbnail);
            }
          });
        }

        toast({
          description: 'Updated successfully',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });

        props.onClose();
      },
      onError: (error: ApiResponseError) => {
        toast({
          status: 'error',
          description: error.message,
          position: 'top-right',
        });
      },
    }
  );

  const { getInputProps, getRootProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    noKeyboard: true,
    noDrag: true,
    onDropAccepted: (acceptedFiles) => {
      if (acceptedFiles.length > IMAGE_UPLOAD_LIMIT) {
        setDropzoneError(
          `Sorry, you may upload a maximum of ${IMAGE_UPLOAD_LIMIT} images only.`
        );
        return;
      }

      setDropzoneError('');
      setImages([
        ...images,
        ...acceptedFiles.map<Photo>((file) => ({
          file,
          urls: {
            path: URL.createObjectURL(file),
            thumbnail: URL.createObjectURL(file),
            medium: URL.createObjectURL(file),
            original: URL.createObjectURL(file),
          },
        })),
      ]);
    },
  });

  const onPhotosReorder = ({ oldIndex, newIndex }: SortEnd) => {
    setImages((ps) => arrayMoveImmutable(ps, oldIndex, newIndex));
  };

  const onDeleteImage = (index: number) => {
    // remove item in images by index
    const updatedImages = [
      ...images.slice(0, index),
      ...images.slice(index + 1),
    ];
    setImages(updatedImages);
  };

  const uploadItem = async (item: Photo): Promise<Photo> => {
    try {
      // return image object not for upload
      if (!item.file) {
        setUploadProgress((progress) => [...progress, item]);
        return item;
      }
      const contentType = item.file.type as ImageContentType;
      // upload image
      const { path, url, uploadedUrl } = await imagesApi.property({
        contentType,
      });
      await axios
        .put(url, item.file, {
          headers: {
            'Content-Type': contentType,
          },
        })
        .then(() => path);
      setUploadProgress((progress) => [
        ...progress,
        {
          urls: uploadedUrl,
          failed: false,
        },
      ]);
      // return the image object with updated s3 uri instead of local path
      return {
        urls: uploadedUrl,
        failed: false,
      };
    } catch (e) {
      return {
        ...item,
        failed: true,
      };
    }
  };

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const onSave = async (values: FormProps) => {
    update(values);
  };

  // useEffect(() => {
  //   const { images: passedImages = [], mainImage } = props.data;
  //   if (passedImages.length || mainImage) {
  //     let uploadedPhotos: Photo[] = passedImages.map((up) => ({
  //       forUpload: false,
  //       isMainImage: false,
  //       mimeType: 'image/jpg',
  //       urls: up,
  //       name: up.thumbnail,
  //       path: up.path,
  //     }));
  //     if (mainImage) {
  //       const uploadedMainPhoto: Photo = {
  //         urls: mainImage,
  //       };
  //       uploadedPhotos = [uploadedMainPhoto, ...uploadedPhotos];
  //     }
  //     setImages(uploadedPhotos);
  //   }
  // }, [props.data.mainImage, props.data.images]);

  const SortablePhoto = SortableElement((props: SortablePhotoProps) => (
    <Flex
      width="220px"
      height="180px"
      key={props.value.urls.path}
      borderRadius={8}
      overflow="hidden"
      position="relative"
    >
      {props.photoIndex === 0 && (
        <Box position="absolute" left="10px" top="10px">
          <Label>Main Photo</Label>
        </Box>
      )}
      <Box
        alignItems="center"
        justifyContent="center"
        width="20px"
        height="20px"
        position="absolute"
        right="10px"
        top="10px"
        cursor="pointer"
        onClick={() => props.onDelete(props.photoIndex)}
      >
        <Delete color="special2" />
      </Box>
      <Image
        onClick={() => openImageViewer(props.photoIndex)}
        src={props.value.urls.medium}
        width={220}
        objectFit="cover"
        draggable="false"
      />
    </Flex>
  ));

  const SortableList = SortableContainer((props: SortableListProps) => (
    <HStack spacing={4} alignItems="flex-start">
      {props.images.map((value, index) => (
        <SortablePhoto
          key={`item-${value.urls.path}`}
          photoIndex={index}
          index={index}
          value={value}
          onDelete={props.onDelete}
        />
      ))}
    </HStack>
  ));

  return (
    <AppModal
      {...props}
      size="5xl"
      HeaderRight={
        <Flex
          position="absolute"
          top={4}
          right={16}
          zIndex={200}
          cursor="pointer"
          onClick={props.onClose}
        >
          <ArrowBackIcon />
        </Flex>
      }
      title="Create New Listing"
    >
      <Formik<FormProps>
        onSubmit={onSave}
        initialValues={{ isPrivate: false }}
        validationSchema={Schema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors }) => {
          return (
            <Form>
              <Flex mt={3} flexDir="column" border="1px solid #ddd">
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  px={4}
                  py={4}
                  borderBottom="1px solid #ddd"
                >
                  <Typography variant="type8" color="shade5" weight="600">
                    PROPERTY DETAILS
                  </Typography>
                </Flex>
                <Flex flexDirection="column" p={4}>
                  <SimpleGrid mt={6} columns={2} width="700px">
                    <VStack spacing={2} alignItems="flex-start">
                      <Typography variant="type9" color="shade5">
                        Property Type
                      </Typography>
                      <Flex flexDir="column" width="300px">
                        <FormikSelect
                          fieldName="propertyType"
                          options={PropertyTypesOptions}
                        />
                      </Flex>
                    </VStack>
                    <Box />
                    <VStack spacing={2} alignItems="flex-start">
                      <Typography variant="type9" color="shade5">
                        Market Price
                      </Typography>
                      <Flex flexDir="column" width="300px">
                        <FormikSelect
                          fieldName="marketPrice"
                          options={marketPrice.seller}
                        />
                      </Flex>

                      {values.marketPrice !== SET_DATE_OF_SALE &&
                        values.marketPrice !== PRICE_BY_NEGOTIATION && (
                          <>
                            <Typography variant="type9" color="shade5">
                              Display Price
                            </Typography>
                            <Flex flexDir="column" width="300px">
                              <FormikTextField
                                fieldName="displayPrice"
                                placeholder="e.g. Offers Over $586,000"
                                maxLength={40}
                              />
                            </Flex>
                          </>
                        )}

                      {values.marketPrice &&
                        [SET_DATE_OF_SALE, PRICE_BY_NEGOTIATION].includes(
                          values.marketPrice
                        ) && (
                          <>
                            <Typography variant="type9" color="shade5">
                              Preferred Price
                            </Typography>
                            <Flex flexDir="column" width="300px">
                              <FormikNumberFormatField
                                fieldName="preferredPrice"
                                placeholder="Preferred Price"
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </Flex>
                          </>
                        )}

                      {values.marketPrice === SET_DATE_OF_SALE && (
                        <>
                          <Typography variant="type9" color="shade5">
                            Marketing Method
                          </Typography>
                          <Flex flexDir="column" width="300px">
                            <FormikSelect
                              fieldName="marketingMethod"
                              options={MarketingMethodOptions}
                            />
                          </Flex>
                          <Typography variant="type9" color="shade5">
                            Closing Date
                          </Typography>
                          <HStack spacing={5} width="300px">
                            <FormikDatePicker
                              flatpickrProps={{
                                options: {
                                  minDate: set(new Date(), {
                                    hours: 0,
                                    minutes: 0,
                                    seconds: 0,
                                    milliseconds: 0,
                                  }),
                                },
                              }}
                              fieldName="closingDateTime"
                              error={errors['closingDateTime'] && ' '}
                            />
                            <FormikTimePicker fieldName="closingDateTime" />
                          </HStack>
                          <Typography variant="type9" color="shade5">
                            Unless Sold Prior
                          </Typography>
                          <HStack pb={5} pt={2} spacing={10}>
                            <FormikRadio
                              fieldName="unlessSoldPrior"
                              checkedValue={true}
                              label="Yes"
                            />
                            <FormikRadio
                              fieldName="unlessSoldPrior"
                              checkedValue={false}
                              label="No"
                            />
                          </HStack>
                        </>
                      )}
                    </VStack>

                    <VStack spacing={2} alignItems="flex-start">
                      <Typography variant="type9" color="shade5">
                        Location
                      </Typography>
                      <Flex flexDir="column" width="300px">
                        <FormikSelect
                          placeholder="- select Region -"
                          fieldName="region"
                          options={getRegions()}
                        />
                        <FormikSelect
                          placeholder="- select District -"
                          fieldName="district"
                          options={
                            values.region ? getDistricts(values.region) : []
                          }
                        />
                        {values.region !== undefined &&
                          values.district !== undefined &&
                          !isEmpty(
                            getSuburbs(values.region, values.district)
                          ) && (
                            <FormikSelect
                              placeholder="- select Suburb -"
                              fieldName="suburb"
                              options={getSuburbs(
                                values.region,
                                values.district
                              )}
                            />
                          )}
                        <FormikTextField
                          fieldName="street"
                          placeholder="Street"
                        />
                      </Flex>
                    </VStack>
                    <VStack spacing={2} alignItems="flex-start">
                      <Typography variant="type9" color="shade5">
                        Land Area (optional)
                      </Typography>
                      <HStack width="300px" spacing={2}>
                        <Flex flex={1}>
                          <FormikTextField
                            fieldName="landArea"
                            placeholder="1000"
                            type="number"
                          />
                        </Flex>
                        <Flex flex={1} flexDir="column">
                          <FormikSelect
                            fieldName="landUnit"
                            options={LandUnitOptions}
                          />
                        </Flex>
                      </HStack>
                    </VStack>

                    <Flex />

                    <VStack spacing={2} alignItems="flex-start" height="65px">
                      <Typography variant="type9" color="shade5">
                        Privacy
                      </Typography>
                      <HStack spacing={5}>
                        <FormikRadio
                          fieldName="isPrivate"
                          checkedValue={true}
                          label="Private"
                        />
                        <FormikRadio
                          fieldName="isPrivate"
                          checkedValue={false}
                          label="Public"
                        />
                      </HStack>
                    </VStack>

                    <Flex />

                    {values.propertyType &&
                      !typesThatExcludeBedroom.includes(
                        values.propertyType
                      ) && (
                        <VStack spacing={2} alignItems="flex-start">
                          <Typography variant="type9" color="shade5">
                            Bedrooms
                          </Typography>
                          <Flex flexDir="column" width="300px">
                            <FormikSelect
                              fieldName="bedrooms"
                              options={BedroomOptions}
                            />
                          </Flex>
                        </VStack>
                      )}

                    {values.propertyType &&
                      !typesThatExcludeGarage.includes(values.propertyType) && (
                        <VStack spacing={2} alignItems="flex-start">
                          <Typography variant="type9" color="shade5">
                            Car Garage
                          </Typography>
                          <Flex flexDir="column" width="300px">
                            <FormikSelect
                              fieldName="garage"
                              options={GarageOptions}
                            />
                          </Flex>
                        </VStack>
                      )}

                    {values.propertyType &&
                      !typesExcludingFencing.includes(values.propertyType) && (
                        <VStack spacing={2} alignItems="flex-start">
                          <Typography variant="type9" color="shade5">
                            Fencing (optional)
                          </Typography>
                          <Flex flexDir="column" width="300px">
                            <FormikSelect
                              fieldName="fencing"
                              options={FenceOptions}
                            />
                          </Flex>
                        </VStack>
                      )}

                    {values.propertyType &&
                      !typesExcludingOptional.includes(values.propertyType) && (
                        <>
                          <VStack spacing={2} alignItems="flex-start">
                            <Typography variant="type9" color="shade5">
                              Living Areas (optional)
                            </Typography>
                            <Flex flexDir="column" width="300px">
                              <FormikSelect
                                fieldName="livingAreas"
                                options={LivingAreaOptions}
                              />
                            </Flex>
                          </VStack>
                          <VStack spacing={2} alignItems="flex-start">
                            <Typography variant="type9" color="shade5">
                              Bathrooms (optional)
                            </Typography>
                            <Flex flexDir="column" width="300px">
                              <FormikSelect
                                fieldName="bathroom"
                                options={LivingAreaOptions}
                              />
                            </Flex>
                          </VStack>
                          <VStack spacing={2} alignItems="flex-start">
                            <Typography variant="type9" color="shade5">
                              Toilets (optional)
                            </Typography>
                            <Flex flexDir="column" width="300px">
                              <FormikSelect
                                fieldName="toilets"
                                options={ToiletOptions}
                              />
                            </Flex>
                          </VStack>
                          <VStack spacing={2} alignItems="flex-start">
                            <Typography variant="type9" color="shade5">
                              Glazing (optional)
                            </Typography>
                            <Flex flexDir="column" width="300px">
                              <FormikSelect
                                fieldName="glazing"
                                options={GlazingOptions}
                              />
                            </Flex>
                          </VStack>

                          <Flex />

                          <VStack spacing={2} alignItems="flex-start">
                            <Typography variant="type9" color="shade5">
                              Heating (optional)
                            </Typography>
                            <Flex flexDir="column" width="300px">
                              <FormikOption
                                fieldName="heating"
                                options={HeatingOptions}
                              />
                            </Flex>
                          </VStack>
                          <VStack spacing={2} alignItems="flex-start">
                            <Typography variant="type9" color="shade5">
                              Other Features (optional)
                            </Typography>
                            <Flex flexDir="column" width="300px">
                              <FormikOption
                                fieldName="otherFeatures"
                                options={OtherFeaturesOptions}
                              />
                            </Flex>
                          </VStack>
                        </>
                      )}
                  </SimpleGrid>

                  <VStack spacing={2} alignItems="flex-start">
                    <Typography variant="type9" color="shade5">
                      Heading
                    </Typography>
                    <Flex flexDir="column" width="650px">
                      <FormikTextArea
                        width="600px"
                        rows={2}
                        fieldName="heading"
                        maxLength={100}
                      />
                    </Flex>
                  </VStack>
                  <VStack spacing={2} alignItems="flex-start">
                    <Typography variant="type9" color="shade5">
                      General Description
                    </Typography>
                    <Flex flexDir="column" width="650px">
                      <FormikTextArea
                        width="600px"
                        fieldName="description"
                        rows={5}
                        maxLength={2000}
                      />
                    </Flex>
                  </VStack>
                  <VStack spacing={2} alignItems="flex-start">
                    <Typography variant="type9" color="shade5">
                      Photos (optional)
                    </Typography>
                    <HStack
                      spacing={5}
                      {...(images.length === IMAGE_UPLOAD_LIMIT
                        ? {}
                        : getRootProps({ refKey: 'innerRef' }))}
                    >
                      <AppButton
                        propagateEvent
                        variant="small"
                        disabled={images.length === IMAGE_UPLOAD_LIMIT}
                      >
                        Select Photos to Upload
                      </AppButton>
                      <input {...getInputProps()} />
                      {isLoading ? (
                        <Typography>
                          Uploading photos. Please wait...{' '}
                          {uploadProgress.length} of {images.length}
                        </Typography>
                      ) : (
                        <Typography>
                          {images.length}/{IMAGE_UPLOAD_LIMIT} Limit
                        </Typography>
                      )}
                    </HStack>
                    <Flex
                      overflowX="auto"
                      overflowY="hidden"
                      width="100%"
                      height="200px"
                    >
                      <SortableList
                        axis="x"
                        pressDelay={100}
                        images={images}
                        onSortEnd={onPhotosReorder}
                        onDelete={onDeleteImage}
                      />
                    </Flex>
                    <Typography variant="type7" color="special2">
                      {dropzoneError}
                    </Typography>
                  </VStack>
                </Flex>
              </Flex>
              <Flex justifyContent="flex-end" mt={5}>
                <HStack spacing={5}>
                  <AppButton
                    disabled={isLoading}
                    loading={isLoading}
                    variant="small"
                    type="submit"
                  >
                    Save
                  </AppButton>
                  <AppButton
                    onClick={props.onClose}
                    kind="secondary"
                    variant="small"
                  >
                    Cancel
                  </AppButton>
                </HStack>
              </Flex>
            </Form>
          );
        }}
      </Formik>

      {isViewerOpen && (
        <ImageViewer
          backgroundStyle={{ zIndex: 3000 }}
          src={images.map((img) => img.urls.original)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
        />
      )}
    </AppModal>
  );
};

export default SellListingEditView;
