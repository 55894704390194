import React from 'react';

import { CollapsibleProps } from './Collapsible.props';
import { Motion } from './Collapsible.style';

const variants = {
  visible: { height: 'auto' },
  hidden: { height: 0 },
};

const Collapsible = (props: CollapsibleProps): JSX.Element => {
  return (
    <Motion
      initial="hidden"
      animate={props.isOpen ? 'visible' : 'hidden'}
      variants={variants}
    >
      {props.children}
    </Motion>
  );
};

export default Collapsible;
