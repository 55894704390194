import React, { useCallback, useEffect, useState } from 'react';

import { Box, Flex, HStack, Img, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { partial } from 'ramda';
import { useDropzone } from 'react-dropzone';
import { useMutation, useQuery } from 'react-query';

import agentsApi from 'api/agents';
import imagesApi, { ImageContentType } from 'api/images';
import misc from 'api/misc';
import AppButton from 'components/primitives/Button';
import Checkbox from 'components/primitives/Checkbox';
import FormikSelect from 'components/primitives/FormikSelect';
import FormikTextArea from 'components/primitives/FormikTextArea';
import FormikTextField from 'components/primitives/FormikTextField';
import Typography from 'components/primitives/Typography';
import { ApiResponseError } from 'types/Api';
import { FilePreview } from 'types/File';

import { EditForm, AgentInfoEditProps } from './AgentDetailsEdit.props';
import {
  transformUserDetailsToForm,
  toUpdatePayload,
  SERVICES,
  getOrder,
  getRegionSelect,
} from './AgentDetailsEdit.utils';

const AgentDetailsEditView = (props: AgentInfoEditProps): JSX.Element => {
  const toast = useToast();

  const [file, setFile] = useState<FilePreview>({
    preview: props.data.facilitatorProfileImage?.medium || '',
  });

  const { data: companies = [] } = useQuery(`FETCH AGENT COMPANIES`, () =>
    misc.getCompanies()
  );

  const onChangeFeaturedImage = useCallback((acceptedFiles) => {
    setFile({
      file: acceptedFiles[0],
      preview: URL.createObjectURL(acceptedFiles[0]),
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onChangeFeaturedImage,
    accept: ['image/jpeg', 'image/png'],
    noKeyboard: true,
    noDrag: true,
  });

  const { mutate: update } = useMutation(
    partial(agentsApi.doUpdate, [props.data.id]),
    {
      onSuccess: () => {
        toast({
          description: 'Agent updated successfully.',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
        props.onBack();
        props.refetch();
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const onSubmit = async (
    values: EditForm,
    otherProps: FormikHelpers<EditForm>
  ) => {
    try {
      otherProps.setSubmitting(true);
      let facilitatorProfileImage;
      if (file.file) {
        const facilitatorProfileImageData = await imagesApi.profile({
          id: props.data.id,
          contentType: file.file.type as ImageContentType,
        });
        await axios.put(facilitatorProfileImageData.url, file.file, {
          headers: {
            'Content-Type': file.file.type,
          },
        });
        facilitatorProfileImage = facilitatorProfileImageData.path;
      }
      await update(toUpdatePayload(values, facilitatorProfileImage));
    } catch (e) {
      console.log(e);
    }
    otherProps.setSubmitting(false);
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      file.file && URL.revokeObjectURL(file.preview);
    },
    [file]
  );

  return (
    <Formik<EditForm>
      initialValues={transformUserDetailsToForm(props.data)}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue, values, errors }) => (
        <Form>
          <Flex mt={3} flexDir="column" border="1px solid #ddd">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              px={4}
              py={4}
              borderBottom="1px solid #ddd"
            >
              <Typography variant="type8" color="shade5" weight="600">
                EDIT AGENT PROFILE
              </Typography>
            </Flex>
            <Flex flexDirection="column" p={5}>
              <Typography variant="type9" color="shade5">
                Featured Image
              </Typography>
              <Flex mt={2} alignItems="flex-start">
                <Img width={300} height={200} src={file.preview || ''} />
                <Flex
                  flexDir="column"
                  justifyContent="center"
                  ml={5}
                  {...getRootProps()}
                >
                  <AppButton outline variant="small" propagateEvent>
                    Change
                    <input {...getInputProps()} />
                  </AppButton>
                </Flex>
              </Flex>
              <Flex mt={8} maxW="300px" flexDir="column">
                <FormikSelect
                  fieldName="company"
                  label="Company Brand"
                  options={companies}
                  placeholder="Others"
                  error={errors['company']}
                />
                {!companies.find((c) => c.value === values.company) && (
                  <FormikTextField
                    fieldName="company"
                    placeholder="Specify others"
                  />
                )}
                <FormikTextField
                  fieldName="companyLocation"
                  label="Company Location"
                />
                <FormikTextField fieldName="tradingAs" label="Trading As" />
                <FormikTextField fieldName="licenseNo" label="License Number" />
                <FormikSelect
                  fieldName="region"
                  label="Region"
                  options={getRegionSelect()}
                  error={errors['region']}
                />
                {SERVICES.map((s) => (
                  <Flex mt={2} key={s.key}>
                    <Checkbox
                      value={s.key}
                      checked={values.specialities.includes(s.key)}
                      onChange={(value) => {
                        const found = values.specialities.find(
                          (ps) => ps === value
                        );

                        if (found) {
                          const arr = values.specialities.filter(
                            (ps) => ps !== value
                          );
                          setFieldValue('specialities', arr);
                          return arr;
                        } else {
                          const arr = [...values.specialities, value];
                          setFieldValue('specialities', arr);
                          return arr;
                        }
                      }}
                      label={s.label}
                      typographyProps={{
                        color: 'shade5',
                      }}
                      inputProps={{
                        name: 'specialities',
                      }}
                    />
                    <Box ml={1}>
                      <Typography variant="type9">
                        <sup>{getOrder(s.key, values.specialities)}</sup>
                      </Typography>
                    </Box>
                  </Flex>
                ))}
                <Flex mt={6} flexDir="column">
                  <FormikTextArea
                    fieldName="about"
                    label="About"
                    rows={15}
                    maxLength={1500}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end" mt={5}>
            <HStack spacing={5}>
              <AppButton type="submit" loading={isSubmitting} variant="small">
                Save
              </AppButton>
              <AppButton
                onClick={props.onBack}
                kind="secondary"
                variant="small"
              >
                Cancel
              </AppButton>
            </HStack>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default AgentDetailsEditView;
