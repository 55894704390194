/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const delay = (t: number, val: any): Promise<any> =>
  new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    setTimeout(resolve.bind(null, val), t);
  });

export const raceAll = <T = any>(
  data: T[],
  promise: (...args: any) => Promise<T>,
  timeoutTime: number,
  generateReturn: (item: T) => void
): Promise<T[]> => {
  return Promise.all(
    data.map(
      (item: T): Promise<T> =>
        // @ts-ignore
        Promise.race([promise(item), delay(timeoutTime, generateReturn(item))])
    )
  );
};
