import React, { useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import QueryString from 'qs';
import { omit } from 'ramda';
import { useQuery, useQueryClient } from 'react-query';
import { useAsyncDebounce } from 'react-table';

import propertiesApi from 'api/properties';
import { FETCH_DRAFTS } from 'constants/queries';
import getFullPropertyTitle from 'utils/getFullPropertyTitle';
import ucFirst from 'utils/string/ucFirst';

import { DraftsProps } from './Drafts.props';
import DraftsView from './Drafts.view';

export const TABLE_OPTIONS = {
  page: 1,
  limit: 20,
};

const statusDisplay = {
  FOR_CLAIM: 'Unclaimed',
  CREATED: 'Claimed',
};

const DraftsContainer = (props: DraftsProps): JSX.Element => {
  const [tableOptions, setTableOptions] =
    useState<Record<string, any>>(TABLE_OPTIONS);

  const queryClient = useQueryClient();

  const { isFetching, data, refetch } = useQuery(FETCH_DRAFTS, async () =>
    propertiesApi.getDrafts(
      `${
        props.location.search ? props.location.search + '&' : '?'
      }${QueryString.stringify(tableOptions)}`
    )
  );

  const queries = useMemo(
    () => QueryString.parse(new URLSearchParams(location.search).toString()),
    [location.search]
  );

  const onSearchKey = useAsyncDebounce(
    (searchKey: string) =>
      props.history.push({
        pathname: props.location.pathname,
        search:
          '?' +
          new URLSearchParams({
            ...omit(['search'], queries),
            ...(searchKey ? { search: searchKey } : {}),
          }).toString(),
      }),
    600
  );

  const meta = React.useMemo(
    () =>
      data?.meta || {
        count: 0,
        limit: 10,
        page: 1,
      },
    [data]
  );

  const setDraft = (draft?: any) =>
    props.history.push({
      pathname: props.location.pathname,
      search:
        '?' +
        new URLSearchParams({
          ...omit(['id'], queries),
          ...(draft ? { id: draft.id } : {}),
        }).toString(),
    });

  const onSetFilter = (value: string, key: string) =>
    props.history.push({
      pathname: props.location.pathname,
      search: '?' + new URLSearchParams({ [key]: value }).toString(),
    });

  const onClearFilter = () =>
    props.history.push({
      pathname: props.location.pathname,
    });

  useEffect(() => {
    queryClient.cancelQueries([FETCH_DRAFTS]);
    setTimeout(refetch, 300);
  }, [props.location.search, tableOptions]);

  useEffect(() => {
    setTableOptions((opts) => ({ ...opts, page: 1 }));
  }, [
    queries.type,
    queries.search,
    queries.propertyType,
    queries.status,
    queries.locationRegion,
    queries.priceFrom,
    queries.priceTo,
    queries.createdAtFrom,
    queries.createdAtTo,
  ]);

  const drafts = React.useMemo(
    () =>
      (data?.data || []).map((lt) => ({
        propertyType: ucFirst(lt.propertyType),
        address: getFullPropertyTitle(lt.title, lt.location),
        matches: lt.matches,
        status: statusDisplay[lt.status],
        createdAt: format(parseISO(lt.createdAt), 'dd/MM/yyyy H:mm a'),
        claimedAt: lt.claimedAt
          ? format(parseISO(lt.claimedAt), 'dd/MM/yyyy H:mm a')
          : '-',
        original: lt,
        id: lt.id,
      })),
    [data]
  );

  return (
    <DraftsView
      setDraft={setDraft}
      data={drafts}
      isLoading={isFetching}
      refetch={refetch}
      meta={meta}
      onClearFilter={onClearFilter}
      onSetFilter={onSetFilter}
      onSearchKey={onSearchKey}
      setTableOptions={setTableOptions}
      tableOptions={tableOptions}
    />
  );
};

export default DraftsContainer;
