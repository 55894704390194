import React from 'react';

import { SendGroupEmailProps } from './SendGroupEmail.props';
import SendGroupEmailView from './SendGroupEmail.view';

const SendGroupEmailContainer = (props: SendGroupEmailProps): JSX.Element => {
  return <SendGroupEmailView {...props} />;
};

export default SendGroupEmailContainer;
