import React from 'react';

import { useField, useFormikContext } from 'formik';

import Radio from 'components/primitives/Radio';

import { FormikRadioProps } from './FormikRadio.props';

const FormikRadioView = ({
  fieldName,
  checkedValue,
  ...props
}: FormikRadioProps): JSX.Element => {
  const [{ value }] = useField(fieldName);
  const { setFieldValue } = useFormikContext();

  return (
    <Radio
      {...props}
      value={checkedValue}
      checked={checkedValue === value}
      onChange={(value) => setFieldValue(fieldName, value)}
    />
  );
};

export default FormikRadioView;
