import React from 'react';

import {
  Flex,
  Table,
  Tbody,
  Th,
  Tr,
  Thead,
  Td,
  HStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';

import Button from 'components/primitives/Button';
import Typography from 'components/primitives/Typography';
import { useTheme } from 'utils/theme';

import { BuyListingTransactionsProps } from './BuyListingTransactions.props';

const BuyListingTransactionsView = (
  props: BuyListingTransactionsProps
): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            TRANSACTION HISTORY ({props.transactions.count})
          </Typography>
        </Flex>
        <Flex flexDirection="column">
          <Table>
            <Thead backgroundColor={theme.colors.shade12}>
              <Th>
                <Typography variant="type9" weight="600" color="shade5">
                  Transaction Summary
                </Typography>
              </Th>
              <Th>
                <Typography variant="type9" weight="600" color="shade5">
                  Payment
                </Typography>
              </Th>
              <Th>
                <Typography variant="type9" weight="600" color="shade5">
                  Executed On
                </Typography>
              </Th>
            </Thead>
            <Tbody>
              {props?.transactions.data.map((trans, i) => (
                <Tr
                  key={trans.id}
                  backgroundColor={
                    i % 2 !== 0 ? theme.colors.shade18 : 'transparent'
                  }
                >
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {trans.label}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {trans.amount ? `$${trans.amount}` : 'Free'}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {format(new Date(trans.createdAt), 'MM/dd/y kk:mm:ss a')}
                    </Typography>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end" mt={5}>
        <HStack spacing={5}>
          <Button onClick={props.onBack} kind="secondary" variant="small">
            Go Back
          </Button>
        </HStack>
      </Flex>
    </>
  );
};

export default BuyListingTransactionsView;
