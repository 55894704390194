import React from 'react';

import { Box, SimpleGrid } from '@chakra-ui/react';
import { useField } from 'formik';
import { contains, without } from 'ramda';

import Checkbox from 'components/primitives/Checkbox';
import {
  Error,
  ErrorContainer,
} from 'components/primitives/TextField/TextField.style';

import { FormikOptionsProps } from './FormikOptions.props';

const FormikOptionsView = ({
  error,
  ...props
}: FormikOptionsProps): JSX.Element => {
  const [{ value }, , helpers] = useField<string[]>(props.fieldName);
  return (
    <Box>
      <SimpleGrid minChildWidth="120px">
        {props.options.map((option, i) => (
          <Box key={option.value} mt={i >= 2 ? 2 : 0}>
            <Checkbox
              {...option}
              checked={contains(option.value, value || [])}
              onChange={() => {
                if (contains(option.value, value || [])) {
                  helpers.setValue(without([option.value], value || []));
                } else {
                  helpers.setValue([...(value || []), option.value]);
                }
              }}
            />
          </Box>
        ))}
      </SimpleGrid>
      <ErrorContainer>
        <Error color="special2">{error}</Error>
      </ErrorContainer>
    </Box>
  );
};

export default FormikOptionsView;
