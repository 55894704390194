import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const Home = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color } = props;

  return (
    <svg
      width={width || '7.634'}
      height={height || '4.241'}
      viewBox="0 0 7.634 4.241"
    >
      <path
        d="M102.905,3.819l2.7-2.856a.426.426,0,0,0,.115-.3.427.427,0,0,0-.115-.3l-.237-.25a.381.381,0,0,0-.558,0l-3.216,3.4a.439.439,0,0,0,0,.593l3.213,3.4a.381.381,0,0,0,.559,0l.237-.25a.435.435,0,0,0,0-.591Z"
        transform="translate(0 105.721) rotate(-90)"
        fill={theme.colors[color || 'secondary']}
      />
    </svg>
  );
};

export default Home;
