import { path } from 'ramda';

import { PropertyLocation } from 'types/Property';
import ucFirstMultiple from 'utils/string/ucFirstMultiple';

const getFullPropertyTitle = (
  title: string,
  location: PropertyLocation,
  hideRegion?: boolean
): string => {
  const street = title || '';
  const suburb = path(['district', '0', 'suburb', '0'], location);
  const district = path(['district', '0', 'name'], location);
  const region = path(['region'], location);

  let str = '';

  if (street) {
    str += `${ucFirstMultiple(street as string)}, `;
  }
  if (suburb) {
    str += `${ucFirstMultiple(suburb as string)}, `;
  }
  if (district) {
    str += `${ucFirstMultiple(district as string)}, `;
  }
  if (region && !hideRegion) {
    str += `${ucFirstMultiple(region as string)}`;
  } else if (!region || hideRegion) {
    str = str.slice(0, -2);
  }

  return str;
};

export default getFullPropertyTitle;
