import React, { useMemo } from 'react';

import { useStore } from 'effector-react';

import { SessionStore } from 'effector/session/store';

import AuthRoutes from './auth';
import MainRoutes from './main';

const RootRoutes = (): JSX.Element => {
  const sessionStore = useStore(SessionStore);

  // add logic here when logged in and type of user (client, pilot)
  const Routes = useMemo(() => {
    // add logic here
    // temp check
    if (sessionStore?.session) {
      return MainRoutes;
    }
    return AuthRoutes;
  }, [sessionStore]);

  return <Routes />;
};

export default RootRoutes;
