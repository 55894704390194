import styled from '@emotion/styled';
import ReactSlider from 'react-slider';

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledThumb = styled.div`
  height: 25px;
  width: 25px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTrack = styled.div<{
  index: number;
  value: number | number[];
}>`
  background: ${({ index, value, theme }) => {
    if (typeof value === 'object')
      return index === 2
        ? theme.colors.shade8
        : index === 1
        ? theme.colors.primary
        : theme.colors.shade8;

    return index === 1 ? theme.colors.shade8 : theme.colors.primary;
  }};
  border-radius: 999px;
  height: 10px;
`;
