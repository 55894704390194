/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo, useRef } from 'react';

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  CircularProgress,
  useDisclosure,
} from '@chakra-ui/react';
import { differenceInDays, subDays } from 'date-fns';
import { isEmpty } from 'ramda';
import { useLocation } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';

import ListingModal from 'components/modules/Listing';
import ListingsFilters from 'components/modules/ListingsFilters';
import SendGroupEmail from 'components/modules/SendGroupEmail';
import Button from 'components/primitives/Button';
import PaginationView from 'components/primitives/Pagination';
import { Search, Listing as ListingIcon } from 'components/primitives/SVG';
import Typography from 'components/primitives/Typography';
import { useTheme } from 'utils/theme';

import { ListingsGeneratedProps } from './Listings.props';
import { FilterOption } from './Listings.style';

const widthPerAccessor = {
  listingType: 150,
  price: 150,
  undefined: undefined,
};

const headerText: Record<string, string> = {
  all: 'ALL LISTINGS',
  last5days: 'CREATED IN THE LAST 5 DAYS',
  buy: 'ALL BUY LISTINGS',
  sell: 'ALL SELL LISTINGS',
};

const ListingsView = ({
  data,
  meta,
  isLoading,
  tableOptions,
  setTableOptions,
  ...props
}: ListingsGeneratedProps): JSX.Element => {
  const theme = useTheme();
  const location = useLocation();
  const filterModal = useDisclosure();
  const sendEmailModal = useDisclosure();
  const searchRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const type = useMemo(() => query.get('type'), [location.search]);
  const id = useMemo(() => query.get('id'), [location.search]);

  const currentIndex = useMemo(
    () => (id ? data.findIndex((d) => d.id === id) : -1),
    [data, id]
  );

  const createdAtFrom = useMemo(
    () => query.get('createdAtFrom'),
    [location.search]
  );

  const last5DaysFilterOn = useMemo(
    () =>
      createdAtFrom &&
      differenceInDays(new Date(), new Date(createdAtFrom)) === 5,
    [createdAtFrom]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Buyer/Seller/Agent',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'propertyType',
      },
      {
        Header: 'Location',
        accessor: 'location',
      },
      {
        Header: 'Listing Type',
        accessor: 'listingType',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Matches',
        accessor: 'matches',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Date Listed',
        accessor: 'dateListing',
      },
    ],
    []
  );

  const onPrev = () => {
    if (currentIndex > 0) props.setListing(data[currentIndex - 1].original);
  };
  const onNext = () => {
    if (currentIndex < data.length - 1)
      props.setListing(data[currentIndex + 1].original);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      data,
      // @ts-ignore
      columns,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortBy: isLoading,
    },
    useSortBy
  );

  const onPaginate = (page: number, pageSize: number) => {
    setTableOptions((prevState) => {
      return {
        ...prevState,
        page: prevState.limit !== pageSize ? 1 : page,
        limit: pageSize,
      };
    });
  };

  useEffect(() => {
    setTableOptions((prevState) => {
      if (!isEmpty(sortBy)) {
        const { id, desc } = sortBy[0];
        return {
          ...prevState,
          sort: id,
          direction: desc ? 'desc' : 'asc',
        };
      } else if (isEmpty(sortBy) && !!prevState.sort) {
        const psCopy = { ...prevState };
        delete psCopy.sort;
        delete psCopy.direction;

        return psCopy;
      }

      return prevState;
    });
  }, [sortBy]);

  return (
    <Flex flexDirection="column">
      <Flex flex={1}>
        <Typography variant="type5" weight="700" color="primary">
          Listings
        </Typography>
        <HStack mt={1} ml={20} spacing={10} alignItems="flex-start">
          <FilterOption
            borderBottom={`5px solid ${
              !location.search || location.search === '?'
                ? theme.colors.special4
                : 'transparent'
            }`}
            onClick={() => {
              searchRef.current.value = '';
              props.onClearFilter();
            }}
          >
            <Typography
              variant="type8"
              weight="600"
              color={
                !location.search || location.search === '?'
                  ? 'primary'
                  : 'shade1'
              }
            >
              All
            </Typography>
          </FilterOption>
          <FilterOption
            onClick={() => props.onSetFilter('buy', 'type')}
            borderBottom={`5px solid ${
              type === 'buy' ? theme.colors.special4 : 'transparent'
            }`}
          >
            <Typography
              variant="type8"
              weight="600"
              color={type === 'buy' ? 'primary' : 'shade1'}
            >
              Buying
            </Typography>
          </FilterOption>
          <FilterOption
            onClick={() => props.onSetFilter('sell', 'type')}
            borderBottom={`5px solid ${
              type === 'sell' ? theme.colors.special4 : 'transparent'
            }`}
          >
            <Typography
              variant="type8"
              weight="600"
              color={type === 'sell' ? 'primary' : 'shade1'}
            >
              Selling
            </Typography>
          </FilterOption>
          <FilterOption
            onClick={() =>
              props.onSetFilter(
                subDays(new Date(), 5).toJSON(),
                'createdAtFrom'
              )
            }
            borderBottom={`5px solid ${
              last5DaysFilterOn ? theme.colors.special4 : 'transparent'
            }`}
          >
            <Typography
              variant="type8"
              weight="600"
              color={last5DaysFilterOn ? 'primary' : 'shade1'}
            >
              Last 5 days
            </Typography>
          </FilterOption>
        </HStack>
        <Flex flex={1} justifyContent="flex-end">
          <Button variant="small" onClick={sendEmailModal.onOpen}>
            Send a Group Email
          </Button>
        </Flex>
      </Flex>
      <Flex
        mt={5}
        flex={1}
        borderRadius={10}
        p={5}
        minHeight="500px"
        bg="#fff"
        filter="drop-shadow(0px 2px 10px rgba(149, 149, 149, 0.18))"
        flexDirection="column"
      >
        <Flex
          flexDir="row"
          width="100%"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Flex height="30px" alignItems="center">
            <ListingIcon color="shade10" width={12} height={12} />
            <Flex mx={3}>
              <Typography variant="type8" weight="600" color="shade10">
                {
                  headerText[
                    type || (last5DaysFilterOn && 'last5days') || 'all'
                  ]
                }
              </Typography>
            </Flex>
            {isLoading ? (
              <CircularProgress
                isIndeterminate
                size="20px"
                color={theme.colors.primary}
              />
            ) : (
              <Typography variant="type10" color="shade5">
                {meta.count} Listings
              </Typography>
            )}
          </Flex>
          <HStack spacing={5}>
            <InputGroup size="sm" width={300}>
              <InputLeftAddon borderRadius={10} backgroundColor="transparent">
                <Search width="14px" height="14px" />
              </InputLeftAddon>
              <Input
                ref={searchRef}
                onChange={(a) => props.onSearchKey(a.target.value)}
                borderLeft="none"
                placeholder="Search"
                borderRadius={10}
              />
            </InputGroup>
            <Button variant="small" onClick={filterModal.onOpen}>
              Filter
            </Button>
          </HStack>
        </Flex>
        <Table size="sm" mt={7} {...getTableProps()}>
          <Thead backgroundColor="shade12">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {
                      // @ts-ignore
                      ...column.getHeaderProps(column.getSortByToggleProps())
                    }
                  >
                    <Flex flexDirection="row">
                      <Typography variant="type9" weight="600" color="shade5">
                        {column.Header}
                      </Typography>
                      <Flex ml={2}>
                        {
                          // @ts-ignore
                          column.isSorted ? (
                            <Box>
                              {
                                // @ts-ignore
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              }
                            </Box>
                          ) : (
                            <Box width="12px" height="5px" />
                          )
                        }
                      </Flex>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  backgroundColor={i % 2 !== 0 ? 'shade18' : 'transparent'}
                  cursor="pointer"
                  onClick={() => props.setListing(row.original.original)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      // @ts-ignore
                      width={widthPerAccessor[cell.column.id]}
                    >
                      <Typography variant="type8" color="shade5">
                        {cell.render('Cell')}
                      </Typography>
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Flex mt={5} flexDir="row" width="100%" justifyContent="flex-end">
          <PaginationView
            disabled={isLoading}
            total={meta.count}
            current={meta.page}
            pageSize={tableOptions.limit}
            onChange={onPaginate}
          />
        </Flex>
      </Flex>
      <ListingsFilters {...filterModal} />
      <SendGroupEmail {...sendEmailModal} />
      <ListingModal
        id={id}
        isOpen={!!id}
        onClose={() => props.setListing(undefined)}
        onPrev={onPrev}
        onNext={onNext}
        disablePrev={currentIndex === 0}
        disableNext={currentIndex === data.length - 1}
      />
    </Flex>
  );
};

export default ListingsView;
