import { format } from 'date-fns';

import {
  BedroomOptions,
  BuyPropertyStatusOptions,
  FinancialStatusOptions,
  GarageOptions,
  LandAreaOptions,
} from 'constants/property';
import { BuyListingSummary } from 'types/Property';
import getFullPropertyTitle from 'utils/getFullPropertyTitle';
import formatAmount from 'utils/string/formatAmount';
import ucFirst from 'utils/string/ucFirst';

export enum MODE {
  DEFAULT = 'default',
  EDIT = 'edit',
  TRANSACTIONS = 'transactions',
  MATCHES = 'matches',
}

export const transactionHistoryColumns = [
  'Transaction Type',
  'Payment',
  'Executed On',
];

export const matchesColumns = ['Seller', 'Match', 'Matched On'];

export const displayFields = [
  {
    field: 'status',
    label: 'STATUS',
    getValue: (listingSummary: BuyListingSummary): string =>
      BuyPropertyStatusOptions.find((ss) => ss.value === listingSummary.status)
        ?.label || '-',
  },
  {
    field: 'dummy',
    label: '',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary ? '' : '',
  },
  {
    field: 'propertyType',
    label: 'PROPERTY TYPE',
    getValue: (listingSummary: BuyListingSummary): string =>
      ucFirst(listingSummary.propertyType),
  },
  {
    field: 'financeStatus',
    label: 'FINANCE STATUS',
    getValue: (listingSummary: BuyListingSummary): string =>
      FinancialStatusOptions.find(
        (a) => a.value === listingSummary.financeStatus
      )?.label || '-',
  },
  {
    field: 'landArea',
    label: 'LAND AREA',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary.landArea.from
        ? `${
            LandAreaOptions.find(
              (lao) => lao.value === listingSummary.landArea.from
            )?.label
          } - ${
            LandAreaOptions.find(
              (lao) => lao.value === listingSummary.landArea.to
            )?.label
          }`
        : '-',
  },
  {
    field: 'marketPrice',
    label: 'PRICE (MAX)',
    getValue: (listingSummary: BuyListingSummary): string =>
      formatAmount(listingSummary.marketPrice),
  },
  {
    field: 'bedrooms',
    label: 'BEDROOMS',
    getValue: (listingSummary: BuyListingSummary): string =>
      `${
        BedroomOptions.find((a) => a.value === listingSummary?.bedrooms.from)
          ?.label || ''
      } - ${
        BedroomOptions.find((a) => a.value === listingSummary?.bedrooms.to)
          ?.label || ''
      }`,
  },
  {
    field: 'garage',
    label: 'CAR GARAGE',
    getValue: (listingSummary: BuyListingSummary): string =>
      `${
        GarageOptions.find((a) => a.value === listingSummary?.garage.from)
          ?.label || ''
      } - ${
        GarageOptions.find((a) => a.value === listingSummary?.garage.to)
          ?.label || ''
      }`,
  },
  {
    field: 'location',
    label: 'LOCATION',
    getValue: (listingSummary: BuyListingSummary): string =>
      getFullPropertyTitle('', listingSummary.location),
  },
  {
    field: 'dummy',
    label: '',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary ? '' : '',
  },
  {
    field: 'noOfResidents',
    label: 'RESIDENTS',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary.noOfResidents || '-',
  },
  {
    field: 'dummy',
    label: '',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary ? '' : '',
  },
  {
    field: 'petsAnimals',
    label: 'PETS & ANIMALS',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary.petsAnimals || '-',
  },
  {
    field: 'dummy',
    label: '',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary ? '' : '',
  },
  {
    field: 'parkingSpace',
    label: 'VEHICLE PARKING',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary.parkingSpace || '-',
  },
  {
    field: 'dummy',
    label: '',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary ? '' : '',
  },
  {
    field: 'specificRequirement',
    label: 'BUYING PROFILE SUMMARY',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary.specificRequirement || '-',
  },
  {
    field: 'dummy',
    label: '',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary ? '' : '',
  },
  {
    field: 'createdAt',
    label: 'DATE LISTED',
    getValue: (listingSummary: BuyListingSummary): string =>
      format(new Date(listingSummary.createdAt), 'MM/dd/y kk:mm:ss a'),
  },
  {
    field: 'updatedAt',
    label: 'LAST UPDATED',
    getValue: (listingSummary: BuyListingSummary): string =>
      listingSummary.updatedAt
        ? format(new Date(listingSummary.updatedAt), 'MM/dd/y kk:mm:ss a')
        : '-',
  },
];
