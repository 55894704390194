import React from 'react';

import {
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

import properties from 'api/properties';
import AppButton from 'components/primitives/Button';
import Typography from 'components/primitives/Typography';
import { FETCH_LISTING_AD_ACTIVITIES } from 'constants/queries';
import { useTheme } from 'utils/theme';

import { SellListingAdActivitiesProps } from './SellListingAdActivities.props';

const columnGroup1 = [
  'Buyer Name',
  'Email address',
  'Phone Number',
  'Linked to a Yelsa Connect Account',
  'Added on',
];

const columnGroup2 = [
  'Email address',
  'Linked to a Yelsa Connect Account',
  'Entered on',
];

const columnGroup3 = [
  'Enquirer Name',
  'Email address',
  'Phone Number',
  'Preferred method of contact',
  'Enquiry Type',
  'Enquiry Sent On',
];

const SellListingAdActivitiesView = (
  props: SellListingAdActivitiesProps
): JSX.Element => {
  const theme = useTheme();

  const { data } = useQuery(`${FETCH_LISTING_AD_ACTIVITIES}${props.id}`, () =>
    properties.getAdActivities(props.id)
  );

  const event1 = data?.pageEvents.AD_PAGE_CLICKED_REQUEST_INFO_AND_PHOTOS || 0;
  const event2 = data?.pageEvents.AD_PAGE_CLICKED_SEE_ALL_PHOTOS || 0;
  const event3 = data?.pageEvents.AD_PAGE_CLICKED_SEND_INQUERY || 0;
  const event4 =
    data?.pageEvents.AD_PAGE_CLICKED_RECEIVE_UPDATE_ON_PROPERTY || 0;
  const event5 = data?.pageEvents.AD_PAGE_CLICKED_RUN_A_SELLER_MATCH || 0;
  const event6 = data?.pageEvents.AD_PAGE_CLICKED_RUN_A_BUYER_MATCH || 0;

  const eventTotal = event1 + event2 + event3 + event4 + event5 + event6;

  const eventMap = [
    {
      event: 'REQUEST INFO & PHOTOS',
      count: event1,
    },
    {
      event: 'SEE ALL PHOTOS',
      count: event2,
    },
    {
      event: 'SEND ENQUIRY',
      count: event3,
    },
    {
      event: 'RECEIVE UPDATES ON THIS PROPERTY',
      count: event4,
    },
    {
      event: 'RUN A PROPERTY MATCH',
      count: event5,
    },
    {
      event: 'RUN A BUYER MATCH',
      count: event6,
    },
  ];

  return (
    <>
      <Flex mt={3} flexDir="column" border="1px solid #ddd" width="40%">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            PROPERTY CODE AD ACTIVITY ({props.total})
          </Typography>
        </Flex>
        <HStack p={2} spacing={1}>
          <VStack
            width="100%"
            spacing={2}
            py={2}
            backgroundColor={theme.colors.shade4}
          >
            <Typography variant="type10" color="shade1" weight="500">
              AD PAGE VISITS
            </Typography>
            <Typography variant="type8" color="shade5" weight="600">
              {props.adPageVisits}
            </Typography>
          </VStack>
          <VStack
            width="100%"
            spacing={2}
            py={2}
            backgroundColor={theme.colors.shade4}
          >
            <Typography variant="type10" color="shade1" weight="500">
              ADDED TO LIST
            </Typography>
            <Typography variant="type8" color="shade5" weight="600">
              {props.addedToList}
            </Typography>
          </VStack>
          <VStack
            width="100%"
            spacing={2}
            py={2}
            backgroundColor={theme.colors.shade4}
          >
            <Typography variant="type10" color="shade1" weight="500">
              ENTERED EMAIL
            </Typography>
            <Typography variant="type8" color="shade5" weight="600">
              {props.enteredEmail}
            </Typography>
          </VStack>
        </HStack>
      </Flex>

      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            IN PAGE CLICKS ({eventTotal})
          </Typography>
        </Flex>
        <HStack p={2} spacing={1}>
          {eventMap.map((e) => (
            <VStack
              key={e.event}
              width="100%"
              height="70px"
              justifyContent="space-between"
              spacing={2}
              py={2}
              backgroundColor={theme.colors.shade4}
            >
              <Typography
                variant="type10"
                color="shade1"
                weight="500"
                align="center"
              >
                {e.event}
              </Typography>
              <Typography variant="type8" color="shade5" weight="600">
                {e.count}
              </Typography>
            </VStack>
          ))}
        </HStack>
      </Flex>

      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            CODE ACTIVITY - ADDED TO THEIR WATCHLIST (
            {data?.addedToWatchList.length || 0})
          </Typography>
        </Flex>
        <Flex flexDirection="column">
          <Table>
            <Thead backgroundColor={theme.colors.shade12}>
              {columnGroup1.map((col) => (
                <Th key={col}>
                  <Typography variant="type9" weight="600" color="shade5">
                    {col}
                  </Typography>
                </Th>
              ))}
            </Thead>
            <Tbody>
              {(data?.addedToWatchList || []).map((item, i) => (
                <Tr
                  key={item.id}
                  backgroundColor={
                    i % 2 !== 0 ? theme.colors.shade18 : 'transparent'
                  }
                >
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {item.firstName} {item.lastName}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {item.email}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      +{item.phoneCode}
                      {item.phone}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {item.isRegistered ? 'Yes' : 'No'}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {format(new Date(item.createdAt), 'MM/dd/y kk:mm:ss a')}
                    </Typography>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Flex>

      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            CODE ACTIVITY - ENTERED EMAIL FOR DETAILS (
            {data?.enteredEmail.length || 0})
          </Typography>
        </Flex>
        <Flex flexDirection="column">
          <Table>
            <Thead backgroundColor={theme.colors.shade12}>
              {columnGroup2.map((col) => (
                <Th key={col}>
                  <Typography variant="type9" weight="600" color="shade5">
                    {col}
                  </Typography>
                </Th>
              ))}
            </Thead>
            <Tbody>
              {(data?.enteredEmail || []).map((item, i) => (
                <Tr
                  key={item.id}
                  backgroundColor={
                    i % 2 !== 0 ? theme.colors.shade18 : 'transparent'
                  }
                >
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {item.email}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {item.isRegistered ? 'Yes' : 'No'}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {format(new Date(item.createdAt), 'MM/dd/y kk:mm:ss a')}
                    </Typography>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Flex>

      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            ENQUIRIES ({data?.inquiries.length || 0})
          </Typography>
        </Flex>
        <Flex flexDirection="column">
          <Table>
            <Thead backgroundColor={theme.colors.shade12}>
              {columnGroup3.map((col) => (
                <Th key={col}>
                  <Typography variant="type9" weight="600" color="shade5">
                    {col}
                  </Typography>
                </Th>
              ))}
            </Thead>
            <Tbody>
              {(data?.inquiries || []).map((item, i) => (
                <>
                  <Tr
                    key={item.id}
                    backgroundColor={
                      i % 2 !== 0 ? theme.colors.shade18 : 'transparent'
                    }
                  >
                    <Td>
                      <Typography variant="type8" color="shade5">
                        {item.name}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography variant="type8" color="shade5">
                        {item.from}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography variant="type8" color="shade5">
                        +{item.phone}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography variant="type8" color="shade5">
                        {item.preferredMethod}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography variant="type8" color="shade5">
                        {item.reason}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography variant="type8" color="shade5">
                        {format(new Date(item.createdAt), 'MM/dd/y kk:mm:ss a')}
                      </Typography>
                    </Td>
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Flex>

      <Flex justifyContent="flex-end" mt={5}>
        <HStack spacing={5}>
          <AppButton onClick={props.onBack} kind="secondary" variant="small">
            Go Back
          </AppButton>
        </HStack>
      </Flex>
    </>
  );
};

export default SellListingAdActivitiesView;
