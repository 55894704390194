import { useContext, Context } from 'react';

import { ThemeContext as TContext } from '@emotion/react';

import { Theme } from 'types/Theme';

const ThemeContext = TContext as Context<Theme>;

export const useTheme = (): Theme => useContext(ThemeContext);

const rootFontSizePx = 16;
export const pxToRem = (n: number): string => `${n / rootFontSizePx}rem`;

export const theme: Theme = {
  colors: {
    primary: '#1C0066',
    secondary: '#ffffff',
    background1: '#ffffff',
    background2: '#FAFBFF',
    background3: '#F2F4FD',
    transparent: 'transparent',
    special1: '#1DE589', // indicator 1
    special2: '#FF4A14', // indicator 2
    special3: '#31C2D5', // valocity
    special4: '#B4FFDC', // indicator 1
    special5: '#00C585', // badge
    special6: '#F7C11E',
    special7: '#FF6C00',
    shade1: '#525F7F', // medium violet
    shade2: '#AEB8F0', // color for disabled form component
    shade3: '#BBB5D4', // light violet 1
    shade4: '#F2F5FF', // light violet 2
    shade5: '#000000', // black
    shade6: '#3E3E3E', // black 1
    shade7: '#888888', // black 2
    shade8: '#E9ECEF',
    shade9: '#CAD1D7',
    shade10: '#8898AA', // color for disabled text in form component
    shade11: '#F4F4F4',
    shade12: '#E5ECFF', // light violet 3
    shade13: '#F2EDFF', // light violet 3
    shade14: '#E5E5E5', // light grey
    shade15: 'rgba(50, 50, 93, 0.20)', // for box shadow
    shade16: '#EAF4FF', // light blue
    shade17: '#6677A3', // dark blue
    shade18: '#F8FAFC', // disabled button
    error: '#FB6340',
    facebook: '#3B5998',
  },
};

export default theme;
