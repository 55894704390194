import React, { useEffect, useState, useMemo } from 'react';

import { Box, Flex, HStack, SimpleGrid } from '@chakra-ui/react';
import { parseISO, set } from 'date-fns';
import {
  filter,
  intersection,
  isEmpty,
  isNil,
  omit,
  reject,
  uniq,
} from 'ramda';
import { useHistory, useLocation } from 'react-router-dom';

import AppModal from 'components/modules/AppModal';
import Button from 'components/primitives/Button';
import Checkbox from 'components/primitives/Checkbox';
import DatePicker from 'components/primitives/DatePicker';
import Select from 'components/primitives/Select';
import Typography from 'components/primitives/Typography';
import locations from 'constants/locations';
import marketPrice from 'constants/marketPrice.json';
import {
  BuyPropertyStatus,
  PRICE_BY_NEGOTIATION,
  PropertyTypes,
  SellPropertyStatus,
  SET_DATE_OF_SALE,
} from 'constants/property';
import { ListingsFilters } from 'types/Property';

import { ListingsFiltersProps } from './ListingsFilters.props';

const regions = locations.map((loc) => ({
  label: loc.region,
  value: loc.region,
}));

const ListingsFiltersView = (props: ListingsFiltersProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState<
    Partial<Record<keyof ListingsFilters, string>>
  >({});
  const [createdAtFrom, setCreatedAtFrom] = useState<string>();
  const [createdAtTo, setCreatedAtTo] = useState<string>();

  const onPropertyTypeSelect = (pt: PropertyTypes) => {
    if (filters.propertyType?.match(pt)) {
      setFilters({
        ...filters,
        propertyType: filter(
          (type) => type !== pt,
          [...(filters.propertyType || '').split(','), pt]
        ).join(','),
      });
    } else {
      setFilters({
        ...filters,
        propertyType: uniq([
          ...(filters.propertyType || '').split(','),
          pt,
        ]).join(','),
      });
    }
  };

  const onStatusSelect = (status: SellPropertyStatus | BuyPropertyStatus) => {
    if (filters.status?.match(status)) {
      setFilters({
        ...filters,
        status: filter(
          (stat) => stat !== status,
          [...(filters.status || '').split(','), status]
        ).join(','),
      });
    } else {
      setFilters({
        ...filters,
        status: uniq([...(filters.status || '').split(','), status]).join(','),
      });
    }
  };

  const onApply = () => {
    props.onClose();
    history.push({
      pathname: location.pathname,
      search:
        '?' +
        new URLSearchParams(
          reject(isNil)(
            reject(isEmpty)({ ...filters, createdAtFrom, createdAtTo })
          ) as Record<string, string>
        ).toString(),
    });
  };

  const isSpecialPrice = useMemo(
    () =>
      intersection(
        [filters.priceFrom, filters.priceTo],
        [PRICE_BY_NEGOTIATION, SET_DATE_OF_SALE]
      ).length > 0,
    [filters]
  );

  useEffect(() => {
    if (!props.isOpen) {
      setFilters({});
      setCreatedAtFrom(undefined);
      setCreatedAtTo(undefined);
    } else {
      const searches = Object.fromEntries(new URLSearchParams(location.search));
      setFilters(searches);
      setCreatedAtFrom(searches.createdAtFrom || undefined);
      setCreatedAtTo(searches.createdAtTo || undefined);
    }
  }, [props.isOpen]);

  return (
    <AppModal
      {...props}
      size="xl"
      title="Filter"
      FooterComponent={
        <HStack spacing={5}>
          <Button kind="secondary" onClick={() => setFilters({})}>
            Reset
          </Button>
          <Button onClick={onApply}>Apply</Button>
        </HStack>
      }
    >
      <Typography variant="type8" weight="700" color="shade5">
        Property Type
      </Typography>
      <Flex my={2} flexWrap="wrap">
        <Flex m={1}>
          <Button
            onClick={() => onPropertyTypeSelect(PropertyTypes.HOUSE)}
            outline={!filters.propertyType?.match(PropertyTypes.HOUSE)}
            variant="tiny"
          >
            House
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() => onPropertyTypeSelect(PropertyTypes.SECTION)}
            outline={!filters.propertyType?.match(PropertyTypes.SECTION)}
            variant="tiny"
          >
            Section
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() => onPropertyTypeSelect(PropertyTypes.UNIT)}
            outline={!filters.propertyType?.match(PropertyTypes.UNIT)}
            variant="tiny"
          >
            Unit
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() => onPropertyTypeSelect(PropertyTypes.APARTMENT)}
            outline={!filters.propertyType?.match(PropertyTypes.APARTMENT)}
            variant="tiny"
          >
            Apartment
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() =>
              onPropertyTypeSelect(PropertyTypes.LIFESTYLE_BARELAND)
            }
            outline={
              !filters.propertyType?.match(PropertyTypes.LIFESTYLE_BARELAND)
            }
            variant="tiny"
          >
            Lifestyle Bareland
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() =>
              onPropertyTypeSelect(PropertyTypes.LIFESTYLE_DWELLING)
            }
            outline={
              !filters.propertyType?.match(PropertyTypes.LIFESTYLE_DWELLING)
            }
            variant="tiny"
          >
            Lifestyle Dwelling
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() =>
              onPropertyTypeSelect(PropertyTypes.HORTICULTURAL_PROPERTY)
            }
            outline={
              !filters.propertyType?.match(PropertyTypes.HORTICULTURAL_PROPERTY)
            }
            variant="tiny"
          >
            Horticultural
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() =>
              onPropertyTypeSelect(PropertyTypes.AGRICULTURAL_PROPERTY)
            }
            outline={
              !filters.propertyType?.match(PropertyTypes.AGRICULTURAL_PROPERTY)
            }
            variant="tiny"
          >
            Agricultural
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() =>
              onPropertyTypeSelect(PropertyTypes.COMMERCIAL_PROPERTY)
            }
            outline={
              !filters.propertyType?.match(PropertyTypes.COMMERCIAL_PROPERTY)
            }
            variant="tiny"
          >
            Commercial
          </Button>
        </Flex>
      </Flex>
      <Typography variant="type8" weight="700" color="shade5">
        Listing Type
      </Typography>
      <Flex my={2} flexWrap="wrap">
        <Flex m={1}>
          <Button
            outline={filters.type !== 'buy'}
            onClick={() =>
              setFilters({ ...omit(['status'], filters), type: 'buy' })
            }
            variant="tiny"
          >
            Buying
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            outline={filters.type !== 'sell'}
            onClick={() =>
              setFilters({ ...omit(['status'], filters), type: 'sell' })
            }
            variant="tiny"
          >
            Selling
          </Button>
        </Flex>
      </Flex>
      <Typography variant="type8" weight="700" color="shade5">
        Listing Status - Buying
      </Typography>
      <Flex my={2} flexWrap="wrap">
        <Flex m={1}>
          <Button
            outline={!filters.status?.match(BuyPropertyStatus.LOOKING_TO_BUY)}
            disabled={!filters.type || filters.type !== 'buy'}
            variant="tiny"
            onClick={() => onStatusSelect(BuyPropertyStatus.LOOKING_TO_BUY)}
          >
            Looking to Buy
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            outline={!filters.status?.match(BuyPropertyStatus.PURCHASED)}
            disabled={!filters.type || filters.type !== 'buy'}
            variant="tiny"
            onClick={() => onStatusSelect(BuyPropertyStatus.PURCHASED)}
          >
            Purchased
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            outline={
              !filters.status?.match(BuyPropertyStatus.NO_LONGER_LOOKING)
            }
            disabled={!filters.type || filters.type !== 'buy'}
            variant="tiny"
            onClick={() => onStatusSelect(BuyPropertyStatus.NO_LONGER_LOOKING)}
          >
            No Longer Looking to Buy
          </Button>
        </Flex>
      </Flex>
      <Typography variant="type8" weight="700" color="shade5">
        Listing Status - Selling
      </Typography>
      <Flex my={2} flexWrap="wrap">
        <Flex m={1}>
          <Button
            outline={!filters.status?.match(SellPropertyStatus.FOR_SALE)}
            disabled={!filters.type || filters.type !== 'sell'}
            variant="tiny"
            onClick={() => onStatusSelect(SellPropertyStatus.FOR_SALE)}
          >
            For Sale
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            outline={
              !filters.status?.match(SellPropertyStatus.PRE_REGISTRATION)
            }
            disabled={!filters.type || filters.type !== 'sell'}
            variant="tiny"
            onClick={() => onStatusSelect(SellPropertyStatus.PRE_REGISTRATION)}
          >
            Pre-registration
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            outline={!filters.status?.match(SellPropertyStatus.SOLD)}
            disabled={!filters.type || filters.type !== 'sell'}
            variant="tiny"
            onClick={() => onStatusSelect(SellPropertyStatus.SOLD)}
          >
            Sold
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            outline={!filters.status?.match(SellPropertyStatus.WITHDRAWN)}
            disabled={!filters.type || filters.type !== 'sell'}
            variant="tiny"
            onClick={() => onStatusSelect(SellPropertyStatus.WITHDRAWN)}
          >
            Withdrawn
          </Button>
        </Flex>
      </Flex>
      <SimpleGrid columns={2} spacingX={5} spacingY={2}>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Location
          </Typography>
          <Flex flexDir="column" my={1}>
            <Select
              options={regions}
              onChange={(val) =>
                setFilters({ ...filters, locationRegion: val.target.value })
              }
              value={filters.locationRegion || ''}
              placeholder="Select Region"
            />
          </Flex>
        </Box>
        <Box />
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Price From
          </Typography>
          <Flex flexDir="column" my={1}>
            <Select
              disabled={isSpecialPrice}
              options={marketPrice.buyer}
              onChange={(val) =>
                setFilters({ ...filters, priceFrom: val.target.value })
              }
              value={filters.priceFrom || ''}
              placeholder="Select Price"
            />
          </Flex>
        </Box>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Price To
          </Typography>
          <Flex flexDir="column" my={1}>
            <Select
              disabled={isSpecialPrice}
              options={marketPrice.buyer}
              onChange={(val) =>
                setFilters({ ...filters, priceTo: val.target.value })
              }
              value={filters.priceTo || ''}
              placeholder="Select Price"
            />
          </Flex>
        </Box>
        <Box pb={3}>
          <Checkbox
            disabled={[filters.priceFrom, filters.priceTo].includes(
              SET_DATE_OF_SALE
            )}
            value={PRICE_BY_NEGOTIATION}
            checked={[filters.priceFrom, filters.priceTo].includes(
              PRICE_BY_NEGOTIATION
            )}
            label="Price by Negotiation"
            onChange={(val) =>
              [filters.priceFrom, filters.priceTo].includes(
                PRICE_BY_NEGOTIATION
              )
                ? setFilters(omit(['priceTo', 'priceFrom'], filters))
                : setFilters({ ...filters, priceTo: val })
            }
          />
        </Box>
        <Box>
          <Checkbox
            disabled={[filters.priceFrom, filters.priceTo].includes(
              PRICE_BY_NEGOTIATION
            )}
            value={SET_DATE_OF_SALE}
            checked={[filters.priceFrom, filters.priceTo].includes(
              SET_DATE_OF_SALE
            )}
            label="Set Date of Sale"
            onChange={(val) =>
              [filters.priceFrom, filters.priceTo].includes(SET_DATE_OF_SALE)
                ? setFilters(omit(['priceTo', 'priceFrom'], filters))
                : setFilters({ ...filters, priceTo: val })
            }
          />
        </Box>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Date from
          </Typography>
          <Flex flexDir="column" my={1}>
            <DatePicker
              value={createdAtFrom ? parseISO(createdAtFrom) : undefined}
              textFieldProps={{
                placeholder: 'MM/DD/YYYY',
              }}
              onChange={([date]) =>
                setCreatedAtFrom(
                  set(date, {
                    seconds: 0,
                    milliseconds: 0,
                  }).toISOString()
                )
              }
            />
          </Flex>
        </Box>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Date to
          </Typography>
          <Flex flexDir="column" my={1}>
            <DatePicker
              value={createdAtTo ? parseISO(createdAtTo) : undefined}
              textFieldProps={{
                placeholder: 'MM/DD/YYYY',
              }}
              onChange={([date]) =>
                setCreatedAtTo(
                  set(date, {
                    seconds: 0,
                    milliseconds: 0,
                  }).toISOString()
                )
              }
            />
          </Flex>
        </Box>
      </SimpleGrid>
    </AppModal>
  );
};

export default ListingsFiltersView;
