import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useField } from 'formik';
import { isEmpty } from 'ramda';

import Checkbox from 'components/primitives/Checkbox';
import Collapsible from 'components/primitives/Collapsible';
import locations from 'constants/locations';
import { PropertyLocation } from 'types/Property';

import { FormikLocationProps } from './FormikLocation.props';

const FormikLocation = (props: FormikLocationProps): any => {
  const [{ value: location }, , helpers] = useField<PropertyLocation>(
    props.fieldName
  );

  const [openedCollapsibles, setOpenedCollapsibles] = useState<string[]>([]);

  const onToggleLocation = (collapsible: string, status: boolean) => {
    const isOpened = openedCollapsibles.includes(collapsible);
    if (!status || (isOpened && !status)) {
      setOpenedCollapsibles(
        openedCollapsibles.filter((item) => item !== collapsible)
      );
    } else {
      setOpenedCollapsibles([...openedCollapsibles, collapsible]);
    }
  };

  useEffect(() => {
    if (location) {
      setOpenedCollapsibles([
        location.region,
        ...location.district.map((d: any) => `${location.region}-${d.name}`),
      ]);
    }
  }, []);

  const renderRegion = useCallback(
    (item: PropertyLocation) => {
      const isChecked =
        location.region.toLowerCase() === item.region.toLowerCase();
      const isCollapsed = openedCollapsibles.includes(item.region);

      return (
        <Box
          key={item.region}
          my={2}
          borderWidth={props.error ? 1 : 0}
          borderColor="special1"
        >
          <Flex
            justifyContent="space-between"
            cursor="pointer"
            onClick={(e) => {
              e.preventDefault();
              onToggleLocation(item.region, !isCollapsed);
            }}
          >
            <Checkbox
              value={item.region}
              checked={isChecked}
              label={item.region}
              onChange={() => {
                helpers.setValue({
                  region: item.region,
                  district: [],
                });
              }}
            />
            {isCollapsed ? (
              <span aria-hidden>&ndash;</span>
            ) : (
              <span aria-hidden>&#43;</span>
            )}
          </Flex>
          <Collapsible isOpen={isCollapsed}>
            <Box p={2}>
              {item.district.map((dstrc) => renderDistrict(item.region, dstrc))}
            </Box>
          </Collapsible>
        </Box>
      );
    },
    [location, openedCollapsibles]
  );

  const renderDistrict = useCallback(
    (region, dstrc) => {
      const id = `${region}-${dstrc.name}`;
      const isChecked = !!location.district.find((d) => d.name === dstrc.name);
      const isCollapsed = openedCollapsibles.includes(id);
      const hasSuburb = !isEmpty(dstrc.suburb);

      return (
        <Box key={id} my={2}>
          <Flex
            justifyContent="space-between"
            cursor={hasSuburb ? 'pointer' : 'default'}
            onClick={(e) => {
              if (!hasSuburb) return;

              e.preventDefault();
              onToggleLocation(id, !isCollapsed);
            }}
          >
            <Checkbox
              value={dstrc.name.toLowerCase()}
              checked={isChecked}
              label={dstrc.name}
              onChange={() => {
                if (isChecked) {
                  helpers.setValue({
                    region,
                    district: location.district.filter(
                      (d) => d.name !== dstrc.name
                    ),
                  });
                } else {
                  helpers.setValue({
                    region,
                    district: [
                      ...(location.district || []),
                      {
                        name: dstrc.name,
                        suburb: [],
                      },
                    ],
                  });
                }
              }}
            />
            {hasSuburb && (
              <>
                {isCollapsed ? (
                  <span aria-hidden>&ndash;</span>
                ) : (
                  <span aria-hidden>&#43;</span>
                )}
              </>
            )}
          </Flex>
          {isCollapsed && (
            <Collapsible isOpen={isCollapsed}>
              <Box p={2}>
                {dstrc.suburb.map((suburb: string) =>
                  renderSuburb(region, dstrc, suburb)
                )}
              </Box>
            </Collapsible>
          )}
        </Box>
      );
    },
    [location, openedCollapsibles]
  );

  const renderSuburb = useCallback(
    (region, dstrc, suburb) => {
      const id = `${dstrc.name}-${suburb}`;
      const isChecked = location.district
        .map((d) => d.suburb.map((s) => `${d.name}-${s}`))
        .flat()
        .includes(id);

      return (
        <Box key={id} my={2}>
          <Flex
            justifyContent="space-between"
            cursor="pointer"
            onClick={(e) => {
              e.preventDefault();
              onToggleLocation(id, !isChecked);
            }}
          >
            <Checkbox
              value={suburb.toLowerCase()}
              checked={isChecked}
              label={suburb}
              onChange={() => {
                if (isChecked) {
                  const dstrcIndex = location.district.findIndex(
                    (d) => d.name === dstrc.name
                  );
                  const updatedDstrc = location.district[dstrcIndex] || {
                    name: dstrc.name,
                  };
                  updatedDstrc.suburb = (updatedDstrc.suburb || []).filter(
                    (s) => s !== suburb
                  );
                  const newDstrcArray = [
                    ...location.district.slice(0, dstrcIndex),
                    updatedDstrc,
                    ...location.district.slice(dstrcIndex + 1),
                  ];
                  helpers.setValue({
                    region,
                    district: newDstrcArray,
                  });
                } else {
                  let existingDstrc = location.district;
                  if (location.region && location.region !== region) {
                    // clear districts on different region;
                    existingDstrc = [];
                  }

                  const dstrcIndex = existingDstrc.findIndex(
                    (d) => d.name === dstrc.name
                  );
                  const updatedDstrc = existingDstrc[dstrcIndex] || {
                    name: dstrc.name,
                  };
                  updatedDstrc.suburb = [
                    ...(updatedDstrc.suburb || []),
                    suburb,
                  ];

                  const newDstrcArray = [
                    ...existingDstrc.slice(0, dstrcIndex),
                    updatedDstrc,
                    ...existingDstrc.slice(dstrcIndex + 1),
                  ];

                  helpers.setValue({
                    region,
                    district: newDstrcArray,
                  });
                }
              }}
            />
          </Flex>
        </Box>
      );
    },
    [location, openedCollapsibles]
  );

  const renderLocations = useMemo(
    () => locations.map(renderRegion),
    [location && location.district, openedCollapsibles]
  );

  return renderLocations;
};

export default FormikLocation;
