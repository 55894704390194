import React from 'react';

import {
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

import propertiesApi from 'api/properties';
import AppButton from 'components/primitives/Button';
import Typography from 'components/primitives/Typography';
import { FETCH_LISTING_PREREG_ACTIVITIES } from 'constants/queries';
import { useTheme } from 'utils/theme';

import { SellPreRegActivitiesProps } from './SellPreRegActivities.props';

const SellPreRegActivitiesView = (
  props: SellPreRegActivitiesProps
): JSX.Element => {
  const { data = [] } = useQuery(
    `${FETCH_LISTING_PREREG_ACTIVITIES}${props.id}`,
    () => propertiesApi.getListingPreregActivities(props.id)
  );
  const theme = useTheme();

  return (
    <>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            PRE-REGISTRATION ACTIVITY ({data.length})
          </Typography>
        </Flex>
        <Flex flexDirection="column">
          <Table>
            <Thead backgroundColor={theme.colors.shade12}>
              <Th>
                <Typography variant="type9" weight="600" color="shade5">
                  Activity
                </Typography>
              </Th>
              <Th>
                <Typography variant="type9" weight="600" color="shade5">
                  Invited on
                </Typography>
              </Th>
              <Th>
                <Typography variant="type9" weight="600" color="shade5">
                  Responded on
                </Typography>
              </Th>
              <Th>
                <Typography variant="type9" weight="600" color="shade5">
                  Response Type
                </Typography>
              </Th>
            </Thead>
            <Tbody>
              {data.map((act, i) => (
                <Tr
                  key={act.activity + i}
                  backgroundColor={
                    i % 2 !== 0 ? theme.colors.shade18 : 'transparent'
                  }
                >
                  <Td>
                    <Typography variant="type8" color="shade5">
                      INVITED{' '}
                      <Typography
                        component="span"
                        variant="type8"
                        color="primary"
                        weight="700"
                      >
                        {act.buyer.first_name} {act.buyer.last_name}
                      </Typography>{' '}
                      to Pre-register {act.activity}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {format(new Date(act.invitedAt), 'MM/dd/y kk:mm:ss a')}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {act.responedAt
                        ? format(new Date(act.responedAt), 'MM/dd/y kk:mm:ss a')
                        : '-'}
                    </Typography>
                  </Td>
                  <Td>
                    <Typography variant="type8" color="shade5">
                      {act.response || '-'}
                    </Typography>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end" mt={5}>
        <HStack spacing={5}>
          <AppButton onClick={props.onBack} kind="secondary" variant="small">
            Go Back
          </AppButton>
        </HStack>
      </Flex>
    </>
  );
};

export default SellPreRegActivitiesView;
