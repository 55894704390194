import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const Feedback = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color, accent } = props;

  return (
    <svg
      width={width || '20'}
      height={height || '20.002'}
      viewBox="0 0 20 20.002"
    >
      <g data-name="Group 1513">
        <path
          data-name="Path 1629"
          d="M19.633 7.121a1.448 1.448 0 00-.881-.471l-5.316-.734L11.333.888a1.444 1.444 0 00-2.664 0l-2.1 5.027-5.316.734a1.448 1.448 0 00-.768 2.515l4.005 3.572-.823 5.6a1.445 1.445 0 002.241 1.409L10 16.962l4.092 2.786a1.445 1.445 0 002.241-1.409l-.823-5.607 4.005-3.572a1.448 1.448 0 00.118-2.039zm-5.356 4.808a.714.714 0 00-.231.637l.857 6-4.494-3.056a.714.714 0 00-.8 0l-4.532 3.038.879-5.984a.714.714 0 00-.231-.634L1.443 8.066l5.72-.789a.714.714 0 00.561-.432l2.284-5.416a.032.032 0 01.006.011l2.261 5.4a.714.714 0 00.561.432l5.727.824z"
          fill={theme.colors[color || 'shade1']}
        />
      </g>
    </svg>
  );
};

export default Feedback;
