import ucFirst from './ucFirst';

const ucFirstMultiple = (text: string): string => {
  if (!text) return '';

  return text
    .split(' ')
    .map(ucFirst)
    .join(' ')
    .split('/')
    .map(ucFirst)
    .join('/')
    .split('(')
    .map(ucFirst)
    .join('(');
};

export default ucFirstMultiple;
