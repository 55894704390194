import styled from '@emotion/styled';

import Typography from 'components/primitives/Typography';
import { pxToRem } from 'utils/theme';

export const Container = styled.div<{ disabled?: boolean }>`
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const InputContainer = styled.div<{ error: string }>`
  background-color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.special2 : theme.colors.shade9};
  border-radius: 4px;
  padding: 13px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Input = styled.input<{ align?: string }>`
  font-size: ${pxToRem(14)};
  line-height: 19px;
  border: none;
  outline: none;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.colors.shade10};
  }
  text-align: ${({ align }) => align || 'left'};
`;

export const Count = styled(Typography)`
  font-size: ${pxToRem(8)};
  line-height: 11px;
  position: absolute;
  right: 10px;
  bottom: 25px;
`;

export const ErrorContainer = styled.div`
  min-height: ${pxToRem(14)};
  margin-top: 4px;
  padding-left: 1px;
`;

export const Error = styled(Typography)`
  font-size: ${pxToRem(10)};
  line-height: 14px;
`;
