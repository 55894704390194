import api from 'api/instance';
import { ImageLinks } from 'types/Image';

export type ImageContentType = 'image/png' | 'image/jpeg';

export interface ImagesPayload {
  contentType: ImageContentType;
  id?: string;
}

export interface ImagesResponse {
  url: string;
  path: string;
  uploadedUrl: ImageLinks;
}

const URL = `${process.env.REACT_APP_API_URL}/admin/images`;

const profile = async ({
  contentType,
  id,
}: ImagesPayload): Promise<ImagesResponse> => {
  const { data } = await api.post<{ data: ImagesResponse }>(
    `${URL}/${id}/profile`,
    { contentType }
  );
  return data.data;
};

const property = async ({
  contentType,
  id,
}: ImagesPayload): Promise<ImagesResponse> => {
  const { data } = await api.post(`${URL}/${id}/property`, { contentType });
  return data;
};

const images = {
  profile,
  property,
};

export default images;
