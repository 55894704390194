import React from 'react';

import { Box, Flex, Link, VStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import {
  Feedback,
  Listing,
  User,
  AgentApplications,
  Logo,
  Voucher,
} from 'components/primitives/SVG';
import Typography from 'components/primitives/Typography';
import { routesSettings } from 'constants/routes';
import { useTheme } from 'utils/theme';

const SidebarView = (): JSX.Element => {
  const theme = useTheme();
  const location = useLocation();

  return (
    <Flex
      width={82}
      minWidth={82}
      py={8}
      zIndex={99}
      minHeight="100vh"
      position="sticky"
      boxShadow="0px 3px 6px rgba(0, 0, 0, 0.16)"
      flexDirection="column"
      bgColor="background1"
    >
      <Box alignSelf="center">
        <Logo width={35} height={35} />
      </Box>
      <VStack mt={20} spacing={5}>
        <Link
          py={3}
          href={routesSettings.MAIN_LISTINGS.path}
          borderLeft={`5px solid ${
            location.pathname === routesSettings.MAIN_LISTINGS.path
              ? theme.colors.primary
              : 'transparent'
          }`}
          width="100%"
        >
          <Flex flexDir="column" alignItems="center">
            <Listing
              color={
                location.pathname === routesSettings.MAIN_LISTINGS.path
                  ? 'primary'
                  : 'shade1'
              }
              width="20px"
              height="20px"
            />
            <Box mt={1}>
              <Typography
                color={
                  location.pathname === routesSettings.MAIN_LISTINGS.path
                    ? 'primary'
                    : 'shade1'
                }
                variant="type10"
                weight="600"
                align="center"
              >
                Listings
              </Typography>
            </Box>
          </Flex>
        </Link>
        <Link
          py={3}
          href={routesSettings.MAIN_DRAFTS.path}
          borderLeft={`5px solid ${
            location.pathname === routesSettings.MAIN_DRAFTS.path
              ? theme.colors.primary
              : 'transparent'
          }`}
          width="100%"
        >
          <Flex flexDir="column" alignItems="center">
            <Listing
              color={
                location.pathname === routesSettings.MAIN_DRAFTS.path
                  ? 'primary'
                  : 'shade1'
              }
              width="20px"
              height="20px"
            />
            <Box mt={1}>
              <Typography
                color={
                  location.pathname === routesSettings.MAIN_DRAFTS.path
                    ? 'primary'
                    : 'shade1'
                }
                variant="type10"
                weight="600"
                align="center"
              >
                Drafts
              </Typography>
            </Box>
          </Flex>
        </Link>
        <Link
          py={3}
          href={routesSettings.MAIN_USERS.path}
          width="100%"
          borderLeft={`5px solid ${
            location.pathname === routesSettings.MAIN_USERS.path
              ? theme.colors.primary
              : 'transparent'
          }`}
        >
          <Flex flexDir="column" alignItems="center">
            <User
              color={
                location.pathname === routesSettings.MAIN_USERS.path
                  ? 'primary'
                  : 'shade1'
              }
              width="20px"
              height="20px"
            />
            <Box mt={1}>
              <Typography
                color={
                  location.pathname === routesSettings.MAIN_USERS.path
                    ? 'primary'
                    : 'shade1'
                }
                variant="type10"
                weight="600"
                align="center"
              >
                Users
              </Typography>
            </Box>
          </Flex>
        </Link>
        <Link
          py={3}
          href={routesSettings.MAIN_AGENT_APPLICATIONS.path}
          width="100%"
          borderLeft={`5px solid ${
            location.pathname === routesSettings.MAIN_AGENT_APPLICATIONS.path
              ? theme.colors.primary
              : 'transparent'
          }`}
        >
          <Flex flexDir="column" alignItems="center">
            <AgentApplications
              color={
                location.pathname ===
                routesSettings.MAIN_AGENT_APPLICATIONS.path
                  ? 'primary'
                  : 'shade1'
              }
              width="20px"
              height="20px"
            />
            <Box mt={1}>
              <Typography
                color={
                  location.pathname ===
                  routesSettings.MAIN_AGENT_APPLICATIONS.path
                    ? 'primary'
                    : 'shade1'
                }
                variant="type10"
                weight="600"
                align="center"
              >
                Agents
              </Typography>
            </Box>
          </Flex>
        </Link>
        <Link
          py={3}
          href={routesSettings.MAIN_FEEDBACKS.path}
          width="100%"
          borderLeft={`5px solid ${
            location.pathname === routesSettings.MAIN_FEEDBACKS.path
              ? theme.colors.primary
              : 'transparent'
          }`}
        >
          <Flex flexDir="column" alignItems="center">
            <Feedback
              color={
                location.pathname === routesSettings.MAIN_FEEDBACKS.path
                  ? 'primary'
                  : 'shade1'
              }
              width="20px"
              height="20px"
            />
            <Box mt={1}>
              <Typography
                color={
                  location.pathname === routesSettings.MAIN_FEEDBACKS.path
                    ? 'primary'
                    : 'shade1'
                }
                variant="type10"
                weight="600"
                align="center"
              >
                Feedbacks
              </Typography>
            </Box>
          </Flex>
        </Link>
        <Link
          py={3}
          href={routesSettings.MAIN_VOUCHERS.path}
          width="100%"
          borderLeft={`5px solid ${
            location.pathname === routesSettings.MAIN_VOUCHERS.path
              ? theme.colors.primary
              : 'transparent'
          }`}
        >
          <Flex flexDir="column" alignItems="center">
            <Voucher
              color={
                location.pathname === routesSettings.MAIN_VOUCHERS.path
                  ? 'primary'
                  : 'shade1'
              }
              width="20px"
              height="20px"
            />
            <Box mt={1}>
              <Typography
                color={
                  location.pathname === routesSettings.MAIN_VOUCHERS.path
                    ? 'primary'
                    : 'shade1'
                }
                variant="type10"
                weight="600"
                align="center"
              >
                Vouchers
              </Typography>
            </Box>
          </Flex>
        </Link>
        <Link
          py={3}
          href={routesSettings.MAIN_CHARITIES.path}
          width="100%"
          borderLeft={`5px solid ${
            location.pathname === routesSettings.MAIN_CHARITIES.path
              ? theme.colors.primary
              : 'transparent'
          }`}
        >
          <Flex flexDir="column" alignItems="center">
            <Listing
              color={
                location.pathname === routesSettings.MAIN_CHARITIES.path
                  ? 'primary'
                  : 'shade1'
              }
              width="20px"
              height="20px"
            />
            <Box mt={1}>
              <Typography
                color={
                  location.pathname === routesSettings.MAIN_CHARITIES.path
                    ? 'primary'
                    : 'shade1'
                }
                variant="type10"
                weight="600"
                align="center"
              >
                Charities
              </Typography>
            </Box>
          </Flex>
        </Link>
      </VStack>
    </Flex>
  );
};

export default SidebarView;
