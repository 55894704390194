import api from 'api/instance';
import { Promo, PromoUsage } from 'types/Promos';

const URL = `${process.env.REACT_APP_API_URL}/admin/promos`;

export type GetAllPromosResponse = {
  data: Promo[];
  meta: {
    count: number;
    page: number;
    limit: number;
  };
};

const getAll = async (payload?: string): Promise<GetAllPromosResponse> => {
  const { data } = await api.get<GetAllPromosResponse>(`${URL}${payload}`);
  return data;
};

export type PromoDetails = {
  promo: Promo;
  usages: PromoUsage[];
};

const getDetails = async (id?: string): Promise<PromoDetails> => {
  const { data } = await api.get<{
    data: PromoDetails;
  }>(`${URL}/${id}`);
  return data.data;
};

export type VoucherUpdatePayload = {
  maxUseCount?: number;
  maxPerUser?: number;
  status?: string;
  code?: string;
  expiredAt?: string;
};

const doUpdate = async (
  id: string,
  fields: VoucherUpdatePayload
): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}`, fields);
  return data;
};

export type VoucherNewPayload = {
  maxUseCount: number;
  maxPerUser: number;
  code: string;
  status: string;
  expiredAt?: string;
};

const doCreate = async (fields: VoucherNewPayload): Promise<void> => {
  const { data } = await api.post(`${URL}`, fields);
  return data;
};

export default {
  getAll,
  getDetails,
  doUpdate,
  doCreate,
};
