import React from 'react';

import DOMPurify from 'dompurify';

import { TypographyProps } from './Typography.props';
import { P } from './Typography.style';

const Typography = ({
  component,
  dangerouslySetInnerHTML,
  ...props
}: TypographyProps): JSX.Element => {
  //@ts-ignore
  const Component = component ? P.withComponent(component) : P;

  return (
    <Component
      {...props}
      {...(dangerouslySetInnerHTML
        ? {
            dangerouslySetInnerHTML: {
              __html: DOMPurify.sanitize(dangerouslySetInnerHTML, {
                ADD_ATTR: ['target'],
              }),
            },
          }
        : {})}
    />
  );
};

export default Typography;
