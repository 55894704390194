import styled from 'utils/styled';

export const DateButton = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;
