import React from 'react';

import { useField } from 'formik';

import NumberFormatField from '../NumberFormatField/NumberFormatField.view';
import { FormikNumberFormatFieldProps } from './FormikNumberFormatField.props';

const FormikNumberFormatField = ({
  error,
  fieldName,
  ...props
}: FormikNumberFormatFieldProps): JSX.Element => {
  const [field, meta, { setValue }] = useField(fieldName);
  return (
    <NumberFormatField
      {...field}
      {...props}
      onChange={undefined}
      onValueChange={(val) => setValue(val.floatValue)}
      error={meta.error || error}
    />
  );
};

export default FormikNumberFormatField;
