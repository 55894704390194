import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const Search = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color, accent } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
    >
      <g id="Vouchers-Icon-SideMenu" transform="translate(-29 -128)">
        <rect
          id="IconBounds"
          width="20"
          height="20"
          transform="translate(29 128)"
          fill="none"
        />
        <g id="coupon-svgrepo-com" transform="translate(29 58.478)">
          <g
            id="Group_1779"
            data-name="Group 1779"
            transform="translate(14.783 81.478)"
          >
            <g
              id="Group_1778"
              data-name="Group 1778"
              transform="translate(0 0)"
            >
              <path
                id="Path_2203"
                data-name="Path 2203"
                d="M379.086,306.087a.652.652,0,1,0,.652.652A.653.653,0,0,0,379.086,306.087Z"
                transform="translate(-378.434 -306.087)"
                fill={theme.colors[color || 'shade1']}
              />
            </g>
          </g>
          <g
            id="Group_1781"
            data-name="Group 1781"
            transform="translate(9.565 76.261)"
          >
            <g
              id="Group_1780"
              data-name="Group 1780"
              transform="translate(0 0)"
            >
              <path
                id="Path_2204"
                data-name="Path 2204"
                d="M245.521,172.522a.652.652,0,1,0,.652.652A.653.653,0,0,0,245.521,172.522Z"
                transform="translate(-244.869 -172.522)"
                fill={theme.colors[color || 'shade1']}
              />
            </g>
          </g>
          <g
            id="Group_1783"
            data-name="Group 1783"
            transform="translate(0 72.348)"
          >
            <g
              id="Group_1782"
              data-name="Group 1782"
              transform="translate(0 0)"
            >
              <path
                id="Path_2205"
                data-name="Path 2205"
                d="M19.348,72.348H5.652V74.3a.652.652,0,0,1-1.3,0V72.348H.652A.652.652,0,0,0,0,73V86.044a.652.652,0,0,0,.652.652h3.7V84.739a.652.652,0,0,1,1.3,0V86.7h13.7A.652.652,0,0,0,20,86.044V73A.652.652,0,0,0,19.348,72.348Zm-13.7,9.783a.652.652,0,0,1-1.3,0v-1.3a.652.652,0,0,1,1.3,0Zm0-3.913a.652.652,0,0,1-1.3,0v-1.3a.652.652,0,0,1,1.3,0Zm2.609-1.3a1.957,1.957,0,1,1,1.957,1.957A1.959,1.959,0,0,1,8.261,76.913Zm1.8,6.028A.652.652,0,0,1,9.6,81.828l5.534-5.534a.652.652,0,0,1,.922.922L10.52,82.75A.65.65,0,0,1,10.059,82.942Zm5.376,1.146a1.957,1.957,0,1,1,1.957-1.957A1.959,1.959,0,0,1,15.435,84.087Z"
                transform="translate(0 -72.348)"
                fill={theme.colors[color || 'shade1']}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Search;
