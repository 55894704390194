import React from 'react';

import { useField } from 'formik';

import TextField from 'components/primitives/TextField';

import { FormikTextFieldProps } from './FormikTextField.props';

const FormikTextFieldView = ({
  error,
  fieldName,
  ...props
}: FormikTextFieldProps): JSX.Element => {
  const [field, meta] = useField(fieldName);

  return <TextField {...field} {...props} error={meta.error || error} />;
};

export default FormikTextFieldView;
