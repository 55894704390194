import React, { useEffect, useMemo, useState } from 'react';

import QueryString from 'qs';
import { omit } from 'ramda';
import { useQuery, useQueryClient } from 'react-query';

import promosApi from 'api/promos';
import { FETCH_VOUCHERS } from 'constants/queries';
import { Promo } from 'types/Promos';

import { VouchersProps } from './Vouchers.props';
import VouchersView from './Vouchers.view';

export const TABLE_OPTIONS = {
  page: 1,
  limit: 20,
};

const VouchersContainer = (props: VouchersProps): JSX.Element => {
  const [tableOptions, setTableOptions] =
    useState<Record<string, any>>(TABLE_OPTIONS);

  const queryClient = useQueryClient();

  const { isFetching, data, refetch } = useQuery(FETCH_VOUCHERS, async () =>
    promosApi.getAll(
      `${
        props.location.search ? props.location.search + '&' : '?'
      }${QueryString.stringify(tableOptions)}`
    )
  );

  const queries = useMemo(
    () => QueryString.parse(new URLSearchParams(location.search).toString()),
    [location.search]
  );

  const setVoucher = (voucher?: Promo) =>
    props.history.push({
      pathname: props.location.pathname,
      search:
        '?' +
        new URLSearchParams({
          ...omit(['id'], queries),
          ...(voucher ? { id: voucher.id } : {}),
        }).toString(),
    });

  useEffect(() => {
    setTableOptions((opts) => ({ ...opts, page: 1 }));
  }, [queries.filter, queries.search]);

  const meta = React.useMemo(
    () =>
      data?.meta || {
        count: 0,
        limit: 10,
        page: 1,
      },
    [data]
  );

  useEffect(() => {
    queryClient.cancelQueries([FETCH_VOUCHERS]);
    setTimeout(refetch, 300);
  }, [props.location.search, tableOptions]);

  return (
    <VouchersView
      data={data?.data || []}
      meta={meta}
      isLoading={isFetching}
      tableOptions={tableOptions}
      setTableOptions={setTableOptions}
      setVoucher={setVoucher}
      refetch={refetch}
    />
  );
};

export default VouchersContainer;
