import React from 'react';

import { Button, Flex, HStack } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import authEffects from 'effector/session/effects';

const TopSettingsView = (): JSX.Element => {
  const { mutate: logout, isLoading } = useMutation(authEffects.logout);

  return (
    <HStack spacing={10} justifyContent="flex-end" mb={10}>
      <Flex alignItems="center">
        <Button disabled={isLoading} onClick={logout}>
          Logout
        </Button>
      </Flex>
    </HStack>
  );
};

export default TopSettingsView;
