import React, { useEffect, useMemo, useState } from 'react';

import QueryString from 'qs';
import { useQuery, useQueryClient } from 'react-query';

import feedbacksApi from 'api/feedbacks';
import { FETCH_FEEDBACKS } from 'constants/queries';

import { FeedbacksProps } from './Feedbacks.props';
import FeedbacksView from './Feedbacks.view';

export const TABLE_OPTIONS = {
  page: 1,
  limit: 20,
};

const FeebacksContainer = (props: FeedbacksProps): JSX.Element => {
  const [tableOptions, setTableOptions] =
    useState<Record<string, any>>(TABLE_OPTIONS);

  const queryClient = useQueryClient();

  const { isFetching, data, refetch } = useQuery(FETCH_FEEDBACKS, async () =>
    feedbacksApi.getAll(
      `${
        props.location.search ? props.location.search + '&' : '?'
      }${QueryString.stringify(tableOptions)}`
    )
  );

  const queries = useMemo(
    () => QueryString.parse(new URLSearchParams(location.search).toString()),
    [location.search]
  );

  useEffect(() => {
    setTableOptions((opts) => ({ ...opts, page: 1 }));
  }, [queries.filter, queries.search]);

  const meta = React.useMemo(
    () =>
      data?.meta || {
        count: 0,
        limit: 10,
        page: 1,
      },
    [data]
  );

  useEffect(() => {
    queryClient.cancelQueries([FETCH_FEEDBACKS]);
    setTimeout(refetch, 300);
  }, [props.location.search, tableOptions]);

  return (
    <FeedbacksView
      data={data?.data || []}
      meta={meta}
      isLoading={isFetching}
      tableOptions={tableOptions}
      setTableOptions={setTableOptions}
    />
  );
};

export default FeebacksContainer;
