import React from 'react';

import { Box } from '@chakra-ui/react';

import Typography from '../Typography';
import { TextAreaPublicProps } from './Textarea.props';
import {
  Container,
  InputContainer,
  Input,
  Count,
  ErrorContainer,
  Error,
} from './Textarea.style';

const TextAreaView = ({
  error = '',
  errorProps,
  label,
  maxLength = 1000,
  ...props
}: TextAreaPublicProps): JSX.Element => {
  return (
    <Box position="relative">
      {label && (
        <Box mb={2}>
          <Typography variant="type9" color="shade5">
            {label}
          </Typography>
        </Box>
      )}
      <Container disabled={props.disabled}>
        <InputContainer error={error}>
          <Input maxLength={maxLength} {...props} as={undefined} />
          {maxLength && (
            <Count color="shade10">
              {props.value ? String(props.value).length : 0}/{maxLength}
            </Count>
          )}
        </InputContainer>
        <ErrorContainer>
          <Error color="special2" {...errorProps}>
            {error}
          </Error>
        </ErrorContainer>
      </Container>
    </Box>
  );
};

export default TextAreaView;
