import React from 'react';

import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import propertiesApi from 'api/properties';
import { ApiResponseError } from 'types/Api';

import { SellListingProps } from './SellListing.props';
import SellListingView from './SellListing.view';

const SellListingContainer = (props: SellListingProps): JSX.Element => {
  const toast = useToast();

  const onSuccess = (description: string) => {
    props.refetch();
    toast({
      description,
      title: 'Success',
      position: 'top-right',
      status: 'success',
    });
  };

  const onError = (e: ApiResponseError) => {
    toast({
      description: e.message,
      title: 'Error',
      position: 'top-right',
      status: 'error',
    });
  };

  const { mutate: lock } = useMutation(propertiesApi.doLock, {
    onSuccess: () => onSuccess('Property has been LOCKED successfully.'),
    onError,
  });

  const { mutate: unlock } = useMutation(propertiesApi.doUnlock, {
    onSuccess: () => onSuccess('Property has been UNLOCKED successfully.'),
    onError,
  });

  const { mutate: verify } = useMutation(propertiesApi.doVerify, {
    onSuccess: () => onSuccess('Property has been set to VERIFIED.'),
    onError,
  });

  const { mutate: unverify } = useMutation(propertiesApi.doUnverify, {
    onSuccess: () => onSuccess('Property has been set to UNVERIFIED.'),
    onError,
  });

  const onLock = () => {
    lock(props.data.id);
  };

  const onUnlock = () => {
    unlock(props.data.id);
  };

  const onVerify = () => {
    verify(props.data.id);
  };

  const onUnverify = () => {
    unverify(props.data.id);
  };

  return (
    <SellListingView
      data={props.data}
      onUnlock={onUnlock}
      onLock={onLock}
      onUnverify={onUnverify}
      onVerify={onVerify}
      refetch={props.refetch}
    />
  );
};

export default SellListingContainer;
