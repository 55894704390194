import React from 'react';

import { useField } from 'formik';

import Textarea from 'components/primitives/Textarea';

import { FormikTextAreaPublicProps } from './FormikTextArea.props';

const FormikTextAreaView = ({
  fieldName,
  error,
  ...props
}: FormikTextAreaPublicProps): JSX.Element => {
  const [field, meta] = useField(fieldName);

  return <Textarea {...field} {...props} error={meta.error || error} />;
};

export default FormikTextAreaView;
