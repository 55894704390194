import React from 'react';

import { Flex, Box } from '@chakra-ui/react';

import Typography from 'components/primitives/Typography';

import { TabsPublicProps } from './Tabs.props';

const TabsView = ({ px = 8, ...props }: TabsPublicProps): JSX.Element => {
  return (
    <Flex mt={4} borderBottomWidth="1px" borderBottomColor="shade8">
      {props.tabItems.map((item, i) => (
        <Box
          key={item.value}
          py={4}
          px={px}
          ml={i === 0 ? 4 : 2}
          borderRadius="5px 5px 0 0"
          borderWidth="1px"
          borderColor={item.value === props.activeTab ? 'shade8' : 'secondary'}
          borderBottomColor={
            item.value === props.activeTab ? 'secondary' : 'shade8'
          }
          bgColor={item.value === props.activeTab ? 'secondary' : 'shade4'}
          cursor="pointer"
          mb="-1px"
          onClick={() => props.onActiveTabChange(item.value)}
        >
          <Typography
            variant="type8"
            weight="bold"
            color={item.value === props.activeTab ? 'primary' : 'shade1'}
            {...props.tabItemTypography}
          >
            {item.label}
          </Typography>
        </Box>
      ))}
    </Flex>
  );
};

export default TabsView;
