import React from 'react';

import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import Typography from 'components/primitives/Typography';
import { useTheme } from 'utils/theme';

import { StaticTableProps } from './StaticTable.props';

const StaticTableView = (props: StaticTableProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Table>
      <Thead backgroundColor={theme.colors.shade12}>
        <Tr>
          {props.columns.map((col) => (
            <Th key={col}>
              {typeof col === 'string' ? (
                <Typography variant="type10" color="shade5">
                  {col}
                </Typography>
              ) : (
                col
              )}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {props.data.map((values, i) => (
          <Tr
            key={values.toString()}
            backgroundColor={i % 2 === 0 ? 'transparent' : theme.colors.shade4}
            cursor={props.onRowClick ? 'pointer' : undefined}
            onClick={() => props.onRowClick && props.onRowClick(i)}
          >
            {values.map((val, valI) => (
              <Td key={`${val}${valI}`}>
                <Typography variant="type10" color="shade5">
                  {val}
                </Typography>
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default StaticTableView;
