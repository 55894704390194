import React from 'react';

import { Logo } from 'components/primitives/SVG';

import { SpinnerPublicProps } from './Spinner.props';
import { Container } from './Spinner.style';

const Spinner = ({ width, height }: SpinnerPublicProps): JSX.Element => {
  return (
    <Container
      animate={{ rotate: 360 }}
      transition={{
        duration: 1.1,
        loop: Infinity,
        repeatDelay: 0.5,
        ease: 'easeInOut',
      }}
    >
      <Logo width={width || 50} height={height || 46} />
    </Container>
  );
};

export default Spinner;
