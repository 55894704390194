import styled from '@emotion/styled';

import Typography from 'components/primitives/Typography';
import { pxToRem } from 'utils/theme';

export const Container = styled.div<{ disabled?: boolean }>`
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  //add default margins here
`;

export const SelectContainer = styled.div<{ error: string }>`
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.special2 : theme.colors.shade9};
  border-radius: 4px;
  padding: 11px 13px;
`;

export const Select = styled.select<{ value: string }>`
  font-size: ${pxToRem(14)};
  line-height: 19px;
  border: none;
  outline: none;
  width: 100%;
  color: ${({ theme, value }) =>
    value === '' ? theme.colors.shade10 : 'black'};
`;

export const ErrorContainer = styled.div`
  min-height: ${pxToRem(14)};
  margin-top: 4px;
  padding-left: 1px;
`;

export const Error = styled(Typography)`
  font-size: ${pxToRem(10)};
  line-height: 14px;
`;
