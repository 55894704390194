import React, { useEffect, useState } from 'react';

import { Box, Flex, HStack } from '@chakra-ui/react';
import { parseISO, set } from 'date-fns';
import { filter, isEmpty, isNil, reject, uniq } from 'ramda';
import { useHistory, useLocation } from 'react-router-dom';

import AppModal from 'components/modules/AppModal';
import Button from 'components/primitives/Button';
import DatePicker from 'components/primitives/DatePicker';
import Typography from 'components/primitives/Typography';
import { UsersFilters } from 'types/User';

import { UsersFiltersProps } from './UsersFilters.props';

const UsersFiltersView = (props: UsersFiltersProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState<
    Partial<Record<keyof UsersFilters, string>>
  >({});
  const [filterDateFrom, setCreatedAtFrom] = useState<string>();
  const [filterDateTo, setCreatedAtTo] = useState<string>();

  const onApply = () => {
    props.onClose();
    history.push({
      pathname: location.pathname,
      search:
        '?' +
        new URLSearchParams(
          reject(isNil)(
            reject(isEmpty)({
              ...filters,
              filterDateFrom,
              filterDateTo: filterDateTo,
            })
          ) as Record<string, string>
        ).toString(),
    });
  };

  useEffect(() => {
    if (!props.isOpen) {
      setFilters({});
      setCreatedAtFrom(undefined);
      setCreatedAtTo(undefined);
    } else {
      const searches = Object.fromEntries(new URLSearchParams(location.search));
      if (searches.filterDateFrom) {
        setCreatedAtFrom(searches.filterDateFrom);
      }
      if (searches.filterDateTo) {
        setCreatedAtTo(searches.filterDateTo);
      }
      setFilters(searches);
    }
  }, [props.isOpen]);

  return (
    <AppModal
      {...props}
      size="xl"
      title="Filter"
      FooterComponent={
        <HStack spacing={5}>
          <Button kind="secondary" onClick={() => setFilters({})}>
            Reset
          </Button>
          <Button onClick={onApply}>Apply</Button>
        </HStack>
      }
    >
      <Typography variant="type8" weight="700" color="shade5">
        Account Status
      </Typography>
      <Flex my={2} flexWrap="wrap">
        <Flex m={1}>
          <Button
            outline={filters.filter !== 'active'}
            onClick={() => setFilters({ ...filters, filter: 'active' })}
            variant="tiny"
          >
            Active
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            outline={filters.filter !== 'suspended'}
            onClick={() => setFilters({ ...filters, filter: 'suspended' })}
            variant="tiny"
          >
            Suspended
          </Button>
        </Flex>
      </Flex>
      <Typography variant="type8" weight="700" color="shade5">
        Listings
      </Typography>
      <Flex my={2} flexWrap="wrap">
        <Flex m={1}>
          <Button
            outline={filters.filterPropertyType !== 'buying'}
            onClick={() =>
              setFilters({ ...filters, filterPropertyType: 'buying' })
            }
            variant="tiny"
          >
            Buying
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            outline={filters.filterPropertyType !== 'selling'}
            onClick={() =>
              setFilters({ ...filters, filterPropertyType: 'selling' })
            }
            variant="tiny"
          >
            Selling
          </Button>
        </Flex>
      </Flex>
      <Box>
        <Typography variant="type8" weight="700" color="shade5">
          Date from
        </Typography>
        <Flex flexDir="column" my={1}>
          <DatePicker
            value={filterDateFrom ? parseISO(filterDateFrom) : undefined}
            textFieldProps={{
              placeholder: 'MM/DD/YYYY',
            }}
            onChange={([date]) =>
              setCreatedAtFrom(
                set(date, {
                  seconds: 0,
                  milliseconds: 0,
                }).toISOString()
              )
            }
          />
        </Flex>
      </Box>
      <Box>
        <Typography variant="type8" weight="700" color="shade5">
          Date to
        </Typography>
        <Flex flexDir="column" my={1}>
          <DatePicker
            value={filterDateTo ? parseISO(filterDateTo) : undefined}
            textFieldProps={{
              placeholder: 'MM/DD/YYYY',
            }}
            onChange={([date]) =>
              setCreatedAtTo(
                set(date, {
                  seconds: 0,
                  milliseconds: 0,
                }).toISOString()
              )
            }
          />
        </Flex>
      </Box>
    </AppModal>
  );
};

export default UsersFiltersView;
