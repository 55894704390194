import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  code: Yup.string().required('This field is required.'),
  maxUseCount: Yup.number()
    .required('This field is required.')
    .positive('Invalid field.'),
  maxPerUser: Yup.number()
    .required('This field is required.')
    .positive('Invalid field.'),
  status: Yup.string().required('This field is required.'),
});
