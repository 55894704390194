import { UserDetails } from 'types/User';

import { EditForm } from './UserInfoEdit.props';

export const transformUserDetailsToForm = (data: UserDetails): EditForm => ({
  firstName: data.firstName,
  lastName: data.lastName,
  phone: data.phoneCode + data.phone,
  phoneCode: data.phoneCode,
  profilePic: data.profilePic?.medium,
});

export const toUpdatePayload = (
  values: EditForm,
  phoneCode: string,
  countryCode: string,
  profilePic?: string
): EditForm => {
  const obj = {
    firstName: values.firstName,
    lastName: values.lastName,
    phone: String((values.phone || '').substring(phoneCode.length)),
    phoneCode: phoneCode,
    countryCode: countryCode.toUpperCase(),
  };

  return profilePic ? { ...obj, profilePic } : obj;
};
