import { number } from 'yup';

import api from 'api/instance';
import { Exemption } from 'types/Misc';
import { Note, UserDetails, UserLogs, UserRow } from 'types/User';

const URL = `${process.env.REACT_APP_API_URL}/admin/users`;

export type GetAllUsersResponse = {
  data: UserRow[];
  meta: {
    count: number;
    page: number;
    limit: number;
  };
};

const getAll = async (payload?: string): Promise<GetAllUsersResponse> => {
  const { data } = await api.get<GetAllUsersResponse>(`${URL}${payload}`);
  return data;
};

const getDetails = async (id?: string): Promise<UserDetails> => {
  const { data } = await api.get<{ data: UserDetails }>(`${URL}/${id}`);
  return data.data;
};

const doMakePremium = async (id: string): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}/premium`);
  return data;
};

const doMakeNonPremium = async (id: string): Promise<void> => {
  const { data } = await api.delete(`${URL}/${id}/premium`);
  return data;
};

const doSuspend = async (id: string): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}/suspend`);
  return data;
};

const undoSuspend = async (id: string): Promise<void> => {
  const { data } = await api.delete(`${URL}/${id}/suspend`);
  return data;
};

type UpdateFields = {
  firstName?: string;
  lastName?: string;
  phoneCode?: string;
  phone?: string;
  countryCode?: string;
  profilePic?: string;
};

const doUpdate = async (id: string, fields: UpdateFields): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}`, fields);
  return data;
};

const doCreateNote = async (id: string, content: string): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}/notes`, {
    content,
  });
  return data;
};

const getNotes = async (id: string): Promise<Note[]> => {
  const { data } = await api.get<{ data: Note[] }>(`${URL}/${id}/notes`);
  return data.data;
};

const getLogs = async (
  id: string,
  page: number,
  limit: number
): Promise<{
  data: UserLogs;
  meta: { count: number; page: number; limit: number };
}> => {
  const { data } = await api.get<{
    data: UserLogs;
    meta: { count: number; page: number; limit: number };
  }>(`${URL}/${id}/logs?page=${page}&limit=${limit}`);

  return data;
};

const doVerifyPhone = async (id: string): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}/verifyPhone`);
  return data;
};

const doUnVerifyPhone = async (id: string): Promise<void> => {
  const { data } = await api.delete(`${URL}/${id}/verifyPhone`);
  return data;
};

const doAskForRating = async (id: string): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}/feedback`);
  return data;
};
const doCancelAskForRating = async (id: string): Promise<void> => {
  const { data } = await api.delete(`${URL}/${id}/feedback`);
  return data;
};

const doVerifyEmail = async (id: string): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}/verifyEmail`);
  return data;
};

const doUndoVerifyEmail = async (id: string): Promise<void> => {
  const { data } = await api.delete(`${URL}/${id}/verifyEmail`);
  return data;
};

const getAllNotifyExemptions = async (): Promise<Exemption[]> => {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}/admin/exemptions`
  );
  return data.data;
};

type AddNotifyExemption = {
  phone: string;
  email: string;
};

const doAddNotifyExemption = async (
  payload: AddNotifyExemption
): Promise<any> => {
  const { data } = await api.post(
    `${process.env.REACT_APP_API_URL}/admin/exemptions`,
    payload
  );
  return data;
};

const doRemoveNotifyExemption = async (id: string): Promise<any> => {
  const { data } = await api.delete(
    `${process.env.REACT_APP_API_URL}/admin/exemptions/${id}`
  );
  return data;
};

export default {
  getAll,
  getDetails,
  doMakePremium,
  doMakeNonPremium,
  doSuspend,
  undoSuspend,
  doUpdate,
  doCreateNote,
  getNotes,
  getLogs,
  doVerifyPhone,
  doUnVerifyPhone,
  doAskForRating,
  doCancelAskForRating,
  doVerifyEmail,
  doUndoVerifyEmail,
  doAddNotifyExemption,
  getAllNotifyExemptions,
  doRemoveNotifyExemption,
};
