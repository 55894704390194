import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const Award = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color, accent } = props;

  return (
    <svg
      width={width || '17'}
      height={height || '11.861'}
      viewBox="0 0 17 11.861"
    >
      <g id="award_1_" data-name="award (1)" transform="translate(0 -0.403)">
        <path
          id="Path_1508"
          data-name="Path 1508"
          d="M143.14,63.121l1.09,2.209a.14.14,0,0,0,.106.077l2.438.354a.14.14,0,0,1,.078.239l-1.764,1.72a.14.14,0,0,0-.04.124l.416,2.428a.14.14,0,0,1-.2.148l-2.181-1.147a.141.141,0,0,0-.131,0l-2.181,1.147a.14.14,0,0,1-.2-.148l.416-2.428a.14.14,0,0,0-.04-.124L139.177,66a.14.14,0,0,1,.078-.239l2.438-.354a.14.14,0,0,0,.106-.077l1.09-2.209A.14.14,0,0,1,143.14,63.121Zm0,0"
          transform="translate(-134.515 -60.941)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1509"
          data-name="Path 1509"
          d="M298.6,144.872a.139.139,0,0,0-.04.124l.416,2.429a.14.14,0,0,1-.2.148l-.41-.216-.442-2.576a.158.158,0,0,1,.046-.14l1.843-1.8.477.069a.14.14,0,0,1,.078.239Zm0,0"
          transform="translate(-288.022 -138.092)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1510"
          data-name="Path 1510"
          d="M73.222,87.4a.259.259,0,0,1-.072-.01A6.786,6.786,0,0,1,68.8,78.215a.257.257,0,1,1,.472.2A6.271,6.271,0,0,0,73.293,86.9a.257.257,0,0,1-.071.5Zm0,0"
          transform="translate(-65.99 -75.459)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1511"
          data-name="Path 1511"
          d="M33.7,255.048a1.285,1.285,0,0,0,.321-.374c.007-.012.073-.169.08-.17s-.089-.149-.1-.161a1.286,1.286,0,0,0-.357-.339,1.176,1.176,0,1,0,.054,1.043Zm0,0"
          transform="translate(-30.401 -245.345)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1512"
          data-name="Path 1512"
          d="M31.445,271.493a1.6,1.6,0,0,0,2.254.408,1.27,1.27,0,0,0,.206-.2,1.878,1.878,0,0,1-2.244-.485A2.018,2.018,0,0,0,31.445,271.493Zm0,0"
          transform="translate(-30.401 -262.197)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1513"
          data-name="Path 1513"
          d="M2.079,174.7a1.285,1.285,0,0,0,.4-.281c.01-.01.113-.146.12-.144s-.048-.167-.053-.18a1.286,1.286,0,0,0-.261-.418,1.6,1.6,0,0,0-2.29.063A1.6,1.6,0,0,0,2.079,174.7Zm0,0"
          transform="translate(0 -167.465)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1514"
          data-name="Path 1514"
          d="M0,182.429a1.6,1.6,0,0,0,2.079.96,1.275,1.275,0,0,0,.25-.146,1.878,1.878,0,0,1-2.05-1.032A2.014,2.014,0,0,0,0,182.429Zm0,0"
          transform="translate(-0.004 -176.152)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1515"
          data-name="Path 1515"
          d="M96.58,319.484a1.284,1.284,0,0,0,.172-.462c0-.014.01-.184.016-.187s-.135-.11-.146-.117a1.283,1.283,0,0,0-.452-.2,1.176,1.176,0,1,0,.41.961Zm0,0"
          transform="translate(-91.192 -307.905)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1516"
          data-name="Path 1516"
          d="M94.324,341.2a1.6,1.6,0,0,0,2.256-.4,1.275,1.275,0,0,0,.122-.263,1.878,1.878,0,0,1-2.273.319A2.019,2.019,0,0,0,94.324,341.2Zm0,0"
          transform="translate(-91.192 -329.223)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1517"
          data-name="Path 1517"
          d="M13.8,90.25a1.284,1.284,0,0,0,.486-.08c.013,0,.164-.083.17-.079s.028-.171.029-.185a1.284,1.284,0,0,0-.055-.489,1.6,1.6,0,0,0-2.093-.93A1.6,1.6,0,0,0,13.8,90.25Zm0,0"
          transform="translate(-11.931 -85.45)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1518"
          data-name="Path 1518"
          d="M12.346,88.956a1.6,1.6,0,0,0,1.462,1.762,1.27,1.27,0,0,0,.289-.024,1.878,1.878,0,0,1-1.4-1.815A2.018,2.018,0,0,0,12.346,88.956Zm0,0"
          transform="translate(-11.935 -85.919)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1519"
          data-name="Path 1519"
          d="M77.494,13.861a1.286,1.286,0,0,0,.25.424c.009.01.137.123.135.13s.17-.036.183-.039a1.286,1.286,0,0,0,.436-.229,1.6,1.6,0,0,0,.109-2.288A1.6,1.6,0,0,0,77.494,13.861Zm0,0"
          transform="translate(-74.84 -11.457)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1520"
          data-name="Path 1520"
          d="M78.6,11.863a1.6,1.6,0,0,0-1.114,2,1.274,1.274,0,0,0,.127.261A1.878,1.878,0,0,1,78.8,12.158,2,2,0,0,0,78.6,11.863Zm0,0"
          transform="translate(-74.836 -11.46)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1521"
          data-name="Path 1521"
          d="M286.726,87.4a.257.257,0,0,1-.071-.5,6.272,6.272,0,0,0,4.022-8.479.257.257,0,1,1,.472-.2A6.785,6.785,0,0,1,286.8,87.39.259.259,0,0,1,286.726,87.4Zm0,0"
          transform="translate(-276.957 -75.459)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1522"
          data-name="Path 1522"
          d="M401.017,255.048a1.285,1.285,0,0,1-.321-.374c-.007-.012-.073-.169-.08-.17s.089-.149.1-.161a1.285,1.285,0,0,1,.357-.339,1.176,1.176,0,1,1-.054,1.043Zm0,0"
          transform="translate(-387.315 -245.345)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1523"
          data-name="Path 1523"
          d="M408.944,271.493a1.6,1.6,0,0,1-2.254.408,1.271,1.271,0,0,1-.206-.2,1.878,1.878,0,0,0,2.244-.485A2.012,2.012,0,0,1,408.944,271.493Zm0,0"
          transform="translate(-392.988 -262.197)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1524"
          data-name="Path 1524"
          d="M434.118,174.7a1.285,1.285,0,0,1-.4-.281c-.01-.01-.113-.146-.12-.144s.048-.167.053-.18a1.287,1.287,0,0,1,.261-.418,1.176,1.176,0,1,1,.21,1.023Zm0,0"
          transform="translate(-419.197 -167.465)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1525"
          data-name="Path 1525"
          d="M444.165,182.429a1.6,1.6,0,0,1-2.079.96,1.273,1.273,0,0,1-.25-.146,1.878,1.878,0,0,0,2.05-1.032A2.014,2.014,0,0,1,444.165,182.429Zm0,0"
          transform="translate(-427.166 -176.152)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1526"
          data-name="Path 1526"
          d="M344.254,319.484a1.286,1.286,0,0,1-.172-.462c0-.014-.01-.184-.016-.187s.135-.11.146-.117a1.284,1.284,0,0,1,.452-.2,1.176,1.176,0,1,1-.411.961Zm0,0"
          transform="translate(-332.642 -307.905)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1527"
          data-name="Path 1527"
          d="M348.421,341.2a1.6,1.6,0,0,1-2.256-.4,1.276,1.276,0,0,1-.122-.263,1.878,1.878,0,0,0,2.273.319A2.023,2.023,0,0,1,348.421,341.2Zm0,0"
          transform="translate(-334.553 -329.223)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1528"
          data-name="Path 1528"
          d="M435.483,90.25a1.284,1.284,0,0,1-.486-.08c-.013,0-.164-.083-.17-.079s-.028-.171-.03-.185a1.287,1.287,0,0,1,.055-.489,1.6,1.6,0,0,1,2.093-.93A1.6,1.6,0,0,1,435.483,90.25Zm0,0"
          transform="translate(-420.357 -85.45)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1529"
          data-name="Path 1529"
          d="M448.634,88.956a1.6,1.6,0,0,1-1.462,1.762,1.27,1.27,0,0,1-.289-.024,1.878,1.878,0,0,0,1.4-1.815A2.019,2.019,0,0,1,448.634,88.956Zm0,0"
          transform="translate(-432.045 -85.919)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1534"
          data-name="Path 1534"
          d="M385.231,13.861a1.285,1.285,0,0,1-.25.424c-.009.01-.137.123-.135.13s-.17-.036-.183-.039a1.285,1.285,0,0,1-.436-.229,1.6,1.6,0,0,1-.109-2.288A1.6,1.6,0,0,1,385.231,13.861Zm0,0"
          transform="translate(-370.952 -11.457)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
        <path
          id="Path_1535"
          data-name="Path 1535"
          d="M390.791,11.863a1.6,1.6,0,0,1,1.114,2,1.268,1.268,0,0,1-.127.261,1.878,1.878,0,0,0-1.184-1.967A2.006,2.006,0,0,1,390.791,11.863Zm0,0"
          transform="translate(-377.625 -11.46)"
          fill={color ? theme.colors[color] : '#fee3c4'}
        />
      </g>
    </svg>
  );
};

export default Award;
