import numeral from 'numeral';

import {
  MarketingMethod,
  PRICE_BY_NEGOTIATION,
  SET_DATE_OF_SALE,
} from 'constants/property';

const formatPriceShorten = (num: string): string =>
  numeral(num).format('$0,0[.]00a').toLocaleUpperCase();

const formatPrice = (num: string): string =>
  numeral(num).format('$0,0[.]00').toLocaleUpperCase();

const marketingMethodLabel = {
  [MarketingMethod.AUCTION]: 'Auction',
  [MarketingMethod.SET_DATE_OF_SALE]: 'Set Date of Sale',
  [MarketingMethod.TENDER]: 'Tender',
};

const formatAmountMultiple = (
  text?: string | number,
  regular?: boolean,
  marketingMethod?: MarketingMethod
): string => {
  if (text === undefined) return '-';
  if (text === PRICE_BY_NEGOTIATION) return 'Price by Negotiation';
  if (text === SET_DATE_OF_SALE)
    return marketingMethod ? marketingMethodLabel[marketingMethod] : '-';
  return text
    .toString()
    .split('-')
    .map(regular ? formatPrice : formatPriceShorten)
    .join('-');
};
export default formatAmountMultiple;
