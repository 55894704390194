import React from 'react';

import { parseISO, set } from 'date-fns';
import { useField, useFormikContext } from 'formik';

import DatePicker from 'components/primitives/DatePicker';

import { FormikDatePickerProps } from './FormikDatePicker.props';

const FormikDatePickerView = ({
  fieldName,
  error,
  ...props
}: FormikDatePickerProps): JSX.Element => {
  const [{ value }, meta] = useField(fieldName);
  const formik = useFormikContext();

  return (
    <DatePicker
      {...props}
      value={value ? parseISO(value) : undefined}
      onChange={([date]) => {
        const sanitizedDate = set(date, { seconds: 0, milliseconds: 0 });
        formik.setFieldValue(fieldName, sanitizedDate.toISOString());
      }}
      textFieldProps={{
        error: meta.error || error,
        name: fieldName,
        ...props.textFieldProps,
      }}
    />
  );
};

export default FormikDatePickerView;
