import { ModalProps } from '@chakra-ui/react';

type Modal = Omit<ModalProps, 'children' | 'id'> & {
  id: string | undefined;
  onNext: () => void;
  onPrev: () => void;
  disableNext: boolean;
  disablePrev: boolean;
};

export type CharityDetailsProps = Modal & {};

export enum MODE {
  DEFAULT = 'default',
  EDIT = 'edit',
}
