import React, { useEffect, useMemo, useState } from 'react';

import QueryString from 'qs';
import { omit } from 'ramda';
import { useQuery, useQueryClient } from 'react-query';
import { useAsyncDebounce } from 'react-table';

import usersApi from 'api/users';
import { FETCH_USERS } from 'constants/queries';
import { UserRow } from 'types/User';

import { UsersProps } from './Users.props';
import UsersView from './Users.view';

export const TABLE_OPTIONS = {
  page: 1,
  limit: 20,
};

const UsersContainer = (props: UsersProps): JSX.Element => {
  const [tableOptions, setTableOptions] =
    useState<Record<string, any>>(TABLE_OPTIONS);

  const queryClient = useQueryClient();

  const { isFetching, data, refetch } = useQuery(FETCH_USERS, async () =>
    usersApi.getAll(
      `${
        props.location.search ? props.location.search + '&' : '?'
      }${QueryString.stringify(tableOptions)}`
    )
  );

  const queries = useMemo(
    () => QueryString.parse(new URLSearchParams(location.search).toString()),
    [location.search]
  );

  useEffect(() => {
    setTableOptions((opts) => ({ ...opts, page: 1 }));
  }, [queries.filter, queries.search]);

  const onSearchKey = useAsyncDebounce(
    (searchKey: string) =>
      props.history.push({
        pathname: props.location.pathname,
        search:
          '?' +
          new URLSearchParams({
            ...omit(['search'], queries),
            ...(searchKey ? { search: searchKey } : {}),
          }).toString(),
      }),
    600
  );

  const meta = React.useMemo(
    () =>
      data?.meta || {
        count: 0,
        limit: 10,
        page: 1,
      },
    [data]
  );

  const onSetFilter = (value: string, key: string) =>
    props.history.push({
      pathname: props.location.pathname,
      search: '?' + new URLSearchParams({ [key]: value }).toString(),
    });

  const onClearFilter = () =>
    props.history.push({
      pathname: props.location.pathname,
    });

  const setUser = (user?: UserRow) =>
    props.history.push({
      pathname: props.location.pathname,
      search:
        '?' +
        new URLSearchParams({
          ...omit(['id'], queries),
          ...(user ? { id: user.id } : {}),
        }).toString(),
    });

  useEffect(() => {
    queryClient.cancelQueries([FETCH_USERS]);
    setTimeout(refetch, 300);
  }, [props.location.search, tableOptions]);

  return (
    <UsersView
      setUser={setUser}
      data={data?.data || []}
      meta={meta}
      onSearchKey={onSearchKey}
      isLoading={isFetching}
      onClearFilter={onClearFilter}
      onSetFilter={onSetFilter}
      tableOptions={tableOptions}
      setTableOptions={setTableOptions}
    />
  );
};

export default UsersContainer;
