import React from 'react';

import { Flex, HStack, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import AppButton from 'components/primitives/Button';
import FormikLocation from 'components/primitives/FormikLocation';
import FormikRadio from 'components/primitives/FormikRadio';
import FormikSelect from 'components/primitives/FormikSelect';
import FormikTextArea from 'components/primitives/FormikTextArea';
import Typography from 'components/primitives/Typography';
import marketPrice from 'constants/marketPrice.json';
import {
  BedroomOptions,
  BuyPropertyStatus,
  GarageOptions,
  LandAreaOptions,
  PropertyTypesOptions,
  typesExcludingOptional,
  typesThatExcludeBedroom,
  typesThatExcludeGarage,
} from 'constants/property';

import {
  BuyListingEditGeneratedProps,
  FormProps,
} from './BuyListingEdit.props';
import { Schema, transformToFormValues } from './BuyListingEdit.utils';

const BuyListingEditView = (
  props: BuyListingEditGeneratedProps
): JSX.Element => {
  const initialValues = transformToFormValues(props.data);

  const onSubmit = (values: any) => {
    const payload = { ...values };

    if (values.bedrooms && !values.bedrooms.from) {
      payload.bedrooms = {
        from: null,
        to: null,
      };
    }
    if (values.garage && !values.garage.from && values.garage.from !== 0) {
      payload.garage = {
        from: null,
        to: null,
      };
    }

    props.onSubmit(payload);
  };

  return (
    <Formik<FormProps>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={Schema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values }) => {
        return (
          <Form>
            <Flex mt={3} flexDir="column" border="1px solid #ddd">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                px={4}
                py={4}
                borderBottom="1px solid #ddd"
              >
                <Typography variant="type8" color="shade5" weight="600">
                  EDIT PROPERTY DETAILS
                </Typography>
              </Flex>
              <Flex flexDirection="column" p={4}>
                <Typography variant="type9" color="shade5">
                  Status
                </Typography>
                <HStack mt={4} spacing={5}>
                  <FormikRadio
                    fieldName="status"
                    checkedValue={BuyPropertyStatus.LOOKING_TO_BUY}
                    label="Looking to Buy"
                  />
                  <FormikRadio
                    fieldName="status"
                    checkedValue={BuyPropertyStatus.PURCHASED}
                    label="Purchased"
                  />
                  <FormikRadio
                    fieldName="status"
                    checkedValue={BuyPropertyStatus.NO_LONGER_LOOKING}
                    label="No Longer Looking - Save for Later"
                  />
                  <FormikRadio
                    fieldName="status"
                    checkedValue={BuyPropertyStatus.DELETED}
                    label="No Longer Looking - Remove from List"
                  />
                </HStack>
                <Flex mt={6} flexDir="column" width="700px">
                  <HStack spacing={5}>
                    <VStack spacing={2} alignItems="flex-start">
                      <Typography variant="type9" color="shade5">
                        Property Type
                      </Typography>
                      <Flex flexDir="column" width="300px">
                        <FormikSelect
                          fieldName="propertyType"
                          options={PropertyTypesOptions}
                        />
                      </Flex>
                    </VStack>
                    <VStack spacing={1} alignItems="flex-start">
                      <Typography variant="type9" color="shade5">
                        Price
                      </Typography>
                      <Flex flexDir="column" width="300px">
                        <FormikSelect
                          fieldName="marketPrice"
                          options={marketPrice.buyer}
                        />
                      </Flex>
                    </VStack>
                  </HStack>
                  <Flex flexDir="column" alignItems="flex-start">
                    <Typography variant="type9" color="shade5">
                      Land Area (optional)
                    </Typography>
                    <HStack mt={4} spacing={5} width="300px">
                      <Flex flex={1} flexDir="column">
                        <FormikSelect
                          fieldName="landArea.from"
                          options={LandAreaOptions}
                        />
                      </Flex>
                      <Flex flex={1} flexDir="column">
                        <FormikSelect
                          fieldName="landArea.to"
                          options={LandAreaOptions}
                        />
                      </Flex>
                    </HStack>
                  </Flex>

                  <HStack spacing={5}>
                    {!typesThatExcludeBedroom.includes(values.propertyType) && (
                      <VStack spacing={2} alignItems="flex-start">
                        <Typography variant="type9" color="shade5">
                          Bedrooms (optional)
                        </Typography>
                        <HStack mt={4} spacing={5} width="300px">
                          <Flex flex={1} flexDir="column">
                            <FormikSelect
                              fieldName="bedrooms.from"
                              options={BedroomOptions}
                            />
                          </Flex>
                          <Flex flex={1} flexDir="column">
                            <FormikSelect
                              fieldName="bedrooms.to"
                              options={BedroomOptions}
                            />
                          </Flex>
                        </HStack>
                      </VStack>
                    )}
                    {!typesThatExcludeGarage.includes(values.propertyType) && (
                      <VStack spacing={2} alignItems="flex-start">
                        <Typography variant="type9" color="shade5">
                          Car Garage (optional)
                        </Typography>
                        <HStack mt={4} spacing={5} width="300px">
                          <Flex flex={1} flexDir="column">
                            <FormikSelect
                              fieldName="garage.from"
                              options={GarageOptions}
                            />
                          </Flex>
                          <Flex flex={1} flexDir="column">
                            <FormikSelect
                              fieldName="garage.to"
                              options={GarageOptions}
                            />
                          </Flex>
                        </HStack>
                      </VStack>
                    )}
                  </HStack>

                  <Flex alignItems="flex-start" mb={3}>
                    <Typography variant="type9" color="shade5">
                      Location
                    </Typography>
                    <HStack mt={4} spacing={5} width="300px">
                      <Flex flex={1} flexDir="column">
                        <FormikLocation fieldName="location" />
                      </Flex>
                    </HStack>
                  </Flex>

                  {!typesExcludingOptional.includes(values.propertyType) && (
                    <>
                      <Flex flexDir="column">
                        <FormikTextArea
                          label="Residents (optional)"
                          fieldName="noOfResidents"
                          maxLength={500}
                        />
                      </Flex>
                      <Flex flexDir="column">
                        <FormikTextArea
                          label="Pets & Animals Requirements (optional)"
                          fieldName="petsAnimals"
                          maxLength={500}
                        />
                      </Flex>
                      <Flex flexDir="column">
                        <FormikTextArea
                          label="Vehicle Parking (optional)"
                          fieldName="parkingSpace"
                          maxLength={500}
                        />
                      </Flex>
                    </>
                  )}

                  <Flex flexDir="column">
                    <FormikTextArea
                      label="Buying Profile Summary"
                      fieldName="specificRequirement"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex justifyContent="flex-end" mt={5}>
              <HStack spacing={5}>
                <AppButton
                  variant="small"
                  type="submit"
                  disabled={props.isLoading}
                  loading={props.isLoading}
                >
                  Save
                </AppButton>
                <AppButton
                  onClick={props.onEndEdit}
                  kind="secondary"
                  variant="small"
                >
                  Cancel
                </AppButton>
              </HStack>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BuyListingEditView;
