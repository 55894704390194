import React from 'react';

import { Box } from '@chakra-ui/react';

import Typography from '../Typography';
import { TextFieldProps } from './TextField.props';
import {
  Container,
  InputContainer,
  Input,
  Count,
  ErrorContainer,
  Error,
} from './TextField.style';

const TextFieldView = ({
  reactRef,
  LeftComponent,
  RightComponent,
  error = '',
  errorProps,
  label,
  maxLength,
  ...props
}: TextFieldProps): JSX.Element => {
  return (
    <Box position="relative">
      {label && (
        <Box mb={2}>
          <Typography variant="type9" color="shade5">
            {label}
          </Typography>
        </Box>
      )}
      <Container disabled={props.disabled}>
        <InputContainer error={error}>
          {LeftComponent || null}
          <Input
            maxLength={maxLength}
            ref={reactRef}
            {...props}
            as={undefined}
          />
          {RightComponent || null}

          {maxLength && (
            <Count color="shade10">
              {props.value ? String(props.value).length : 0}/{maxLength}
            </Count>
          )}
        </InputContainer>
        <ErrorContainer>
          <Error color="special2" {...errorProps}>
            {error}
          </Error>
        </ErrorContainer>
      </Container>
    </Box>
  );
};

export default TextFieldView;
