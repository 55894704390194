import React, { useEffect } from 'react';

import { ChakraProvider, CSSReset, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import integrateEffector from 'effector/integration';
import Routes from 'routes';
import theme from 'utils/theme';

import './App.css';

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = function () {};
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // query options
    },
    mutations: {
      // mutation options
    },
  },
});

function App(): JSX.Element {
  useEffect(() => {
    integrateEffector();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={extendTheme(theme)}>
        <CSSReset />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
