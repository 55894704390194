import { Session } from 'types/Session';

import { SessionDomain } from './domain';
import authEffects from './effects';

export interface SessionStore {
  initiated: boolean;
  session?: Session;
}

const initialState: SessionStore = {
  initiated: false,
};

export const SessionStore = SessionDomain.createStore(initialState, {
  name: 'sessionStore',
})
  .on(authEffects.login.done, (_, { result }) => ({
    initiated: true,
    session: result,
  }))
  .on(authEffects.logout.done, () => ({
    initiated: true,
  }));
