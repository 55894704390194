import React from 'react';

import { RadioPublicProps } from './Radio.props';
import {
  Container,
  HiddenRadio,
  Radio,
  InnerCircle,
  Label,
} from './Radio.style';

const RadioView = ({
  checked = false,
  value,
  onChange,
  disabled,
  label,
  size = 20,
  typographyProps,
  ...props
}: RadioPublicProps): JSX.Element => {
  const onClick = () => {
    if (!disabled) onChange(value);
  };

  return (
    <Container disabled={disabled} onClick={onClick}>
      <HiddenRadio
        onClick={(event) => event.stopPropagation()}
        checked={checked}
        disabled={disabled}
        type="radio"
        {...props}
      />
      <Radio checked={checked} disabled={disabled} size={size}>
        {checked && <InnerCircle size={size} />}
      </Radio>
      <Label
        {...typographyProps}
        variant="type8"
        color={
          disabled
            ? 'shade10'
            : typographyProps
            ? typographyProps.color
            : 'shade1'
        }
      >
        {label}
      </Label>
    </Container>
  );
};

export default RadioView;
