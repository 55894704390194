import React from 'react';

import Typography from 'components/primitives/Typography';

import { SliderPublicProps } from './Slider.props';
import { StyledSlider, StyledThumb, StyledTrack } from './Slider.style';

const Thumb = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  props: object,
  state: { index: number; value: number | number[]; valueNow: number }
) => (
  <StyledThumb {...props}>
    <Typography variant="type9" color="secondary">
      {state.valueNow}
    </Typography>
  </StyledThumb>
);

const Track = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  props: object,
  state: { index: number; value: number | number[] }
) => <StyledTrack {...props} index={state.index} value={state.value} />;

const SliderView = (props: SliderPublicProps): JSX.Element => {
  // @ts-ignore
  return <StyledSlider renderThumb={Thumb} renderTrack={Track} {...props} />;
};

export default SliderView;
