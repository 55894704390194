import React, { useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import QueryString from 'qs';
import { omit } from 'ramda';
import { useQuery, useQueryClient } from 'react-query';
import { useAsyncDebounce } from 'react-table';

import propertiesApi from 'api/properties';
import {
  BuyPropertyStatusOptions,
  PropertyTypesOptions,
  SellPropertyStatusOptions,
} from 'constants/property';
import { FETCH_LISTINGS } from 'constants/queries';
import { Listing } from 'types/Property';
import getFullPropertyTitle from 'utils/getFullPropertyTitle';
import formatAmountMultiple from 'utils/string/formatAmountMultiple';
import ucFirst from 'utils/string/ucFirst';

import { ListingsProps } from './Listings.props';
import ListingsView from './Listings.view';

export const TABLE_OPTIONS = {
  page: 1,
  limit: 20,
};

const ListingsContainer = (props: ListingsProps): JSX.Element => {
  const [tableOptions, setTableOptions] =
    useState<Record<string, any>>(TABLE_OPTIONS);

  const queryClient = useQueryClient();

  const { isFetching, data, refetch } = useQuery(FETCH_LISTINGS, async () =>
    propertiesApi.getAll(
      `${
        props.location.search ? props.location.search + '&' : '?'
      }${QueryString.stringify(tableOptions)}`
    )
  );

  const queries = useMemo(
    () => QueryString.parse(new URLSearchParams(location.search).toString()),
    [location.search]
  );

  useEffect(() => {
    setTableOptions((opts) => ({ ...opts, page: 1 }));
  }, [
    queries.type,
    queries.search,
    queries.propertyType,
    queries.status,
    queries.locationRegion,
    queries.priceFrom,
    queries.priceTo,
    queries.createdAtFrom,
    queries.createdAtTo,
  ]);

  const onSearchKey = useAsyncDebounce(
    (searchKey: string) =>
      props.history.push({
        pathname: props.location.pathname,
        search:
          '?' +
          new URLSearchParams({
            ...omit(['search'], queries),
            ...(searchKey ? { search: searchKey } : {}),
          }).toString(),
      }),
    600
  );

  const setListing = (listing?: Listing) =>
    props.history.push({
      pathname: props.location.pathname,
      search:
        '?' +
        new URLSearchParams({
          ...omit(['id'], queries),
          ...(listing ? { id: listing.id } : {}),
        }).toString(),
    });

  const listings = React.useMemo(
    () =>
      (data?.data || []).map((lt) => ({
        name: lt.user
          ? `${lt.isSellerLessAgentManaged ? 'Agent - ' : ''}${
              lt.user.firstName
            } ${lt.user.lastName}`
          : 'N/A',
        propertyType: ucFirst(lt.type),
        location: getFullPropertyTitle(lt.title, lt.location),
        listingType:
          PropertyTypesOptions.find((pt) => pt.value === lt.propertyType)
            ?.label || '',
        price: formatAmountMultiple(lt.marketPrice.to || ''),
        matches: lt.matches,
        status:
          lt.type === 'sell'
            ? SellPropertyStatusOptions.find((s) => s.value === lt.status)
                ?.label || ''
            : BuyPropertyStatusOptions.find((b) => b.value === lt.status)
                ?.label || '',
        dateListing: format(parseISO(lt.createdAt), 'dd/MM/yyyy H:mm a'),
        original: lt,
        id: lt.id,
      })),
    [data]
  );

  const meta = React.useMemo(
    () =>
      data?.meta || {
        count: 0,
        limit: 10,
        page: 1,
      },
    [data]
  );

  const onSetFilter = (value: string, key: string) =>
    props.history.push({
      pathname: props.location.pathname,
      search: '?' + new URLSearchParams({ [key]: value }).toString(),
    });

  const onClearFilter = () =>
    props.history.push({
      pathname: props.location.pathname,
    });

  useEffect(() => {
    queryClient.cancelQueries([FETCH_LISTINGS]);
    setTimeout(refetch, 300);
  }, [props.location.search, tableOptions]);

  return (
    <ListingsView
      setListing={setListing}
      data={listings}
      meta={meta}
      onSearchKey={onSearchKey}
      isLoading={isFetching}
      onClearFilter={onClearFilter}
      onSetFilter={onSetFilter}
      tableOptions={tableOptions}
      setTableOptions={setTableOptions}
    />
  );
};

export default ListingsContainer;
