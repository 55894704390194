import React from 'react';

import { useField } from 'formik';

import { FormikPhoneTextFieldProps } from 'components/primitives/FormikPhoneTextField/FormikPhoneTextField.props';
import PhoneTextField from 'components/primitives/PhoneTextField';
import { CountryData } from 'types/Country';

const FormikPhoneTextField = ({
  error,
  fieldName,
  ...props
}: FormikPhoneTextFieldProps): JSX.Element => {
  const [field, meta, { setValue }] = useField(fieldName);
  return (
    <PhoneTextField
      {...field}
      {...props}
      value={field.value.toString()}
      onChange={(value, country: CountryData) => {
        props.onChangeCountry(country);
        setValue(value);
      }}
      error={meta.error || error}
    />
  );
};

export default FormikPhoneTextField;
