import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const Calendar = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color, accent } = props;

  return (
    <svg
      id="dateIcon"
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 14 14"
    >
      <g id="Group_1473" data-name="Group 1473">
        <path
          id="Path_1607"
          data-name="Path 1607"
          d="M12.359,1.094H11.7V0H10.609V1.094H3.391V0H2.3V1.094H1.641A1.642,1.642,0,0,0,0,2.734v9.625A1.642,1.642,0,0,0,1.641,14H12.359A1.642,1.642,0,0,0,14,12.359V2.734A1.642,1.642,0,0,0,12.359,1.094Zm.547,11.266a.547.547,0,0,1-.547.547H1.641a.547.547,0,0,1-.547-.547V5.141H12.906Zm0-8.312H1.094V2.734a.547.547,0,0,1,.547-.547H2.3V3.281H3.391V2.188h7.219V3.281H11.7V2.188h.656a.547.547,0,0,1,.547.547Z"
          fill={theme.colors[color || 'shade1']}
        />
      </g>
    </svg>
  );
};

export default Calendar;
