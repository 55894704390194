export const FETCH_ALL = 'fetch all';
export const FETCH_LISTINGS = 'fetch listings';
export const FETCH_LISTING_SUMMARY = 'fetch listing summary';
export const FETCH_LISTING_MATCHES = 'fetch listing matches';
export const FETCH_LISTING_PREREG_ACTIVITIES =
  'fetch listing prereg activities';
export const FETCH_LISTING_OPEN_HOME_DETAILS =
  'fetch listing open home details';
export const FETCH_LISTING_BUYER_ADVISORY = 'fetch listing buyer advisory';
export const FETCH_LISTING_AD_ACTIVITIES = 'fetch listing ad activities';
export const FETCH_USERS = 'fetch users';
export const FETCH_USER_DETAILS = 'fetch user details';
export const FETCH_USER_NOTES = 'fetch user notes';
export const FETCH_AGENT_NOTES = 'fetch agent notes';
export const FETCH_AGENT_APPLICATIONS = 'fetch agent applications';
export const FETCH_AGENT_DETAILS = 'fetch agent details';
export const FETCH_USER_LOGS = 'fetch user logs';
export const FETCH_FEEDBACKS = 'fetch feedbacks';
export const FETCH_VOUCHERS = 'fetch vouchers';
export const FETCH_CHARITIES = 'fetch charities';
export const FETCH_ALL_USER_EXEMPTIONS = 'fetch all user exemptions';
export const FETCH_DRAFTS = 'fetch drafts';
export const FETCH_DRAFT_SUMMARY = 'fetch draft summary';
