import React, { useMemo, useState } from 'react';

import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import { useQuery } from 'react-query';

import promosApi from 'api/promos';
import { FETCH_VOUCHERS } from 'constants/queries';

import AppModal from '../AppModal';
import VoucherDetailsEdit from '../VoucherDetailsEdit';
import VoucherFullDetails from '../VoucherFullDetails';
import { MODE, VoucherDetailsProps } from './VoucherDetails.props';

const VoucherDetailsView = (props: VoucherDetailsProps): JSX.Element => {
  const { data: fullDetails, refetch } = useQuery(
    `${FETCH_VOUCHERS}${props.id}`,
    () => (props.id ? promosApi.getDetails(props.id) : undefined)
  );

  const [mode, setMode] = useState(MODE.DEFAULT);

  const Content = useMemo(() => {
    if (!fullDetails) return <Flex />;

    if (mode === MODE.EDIT) {
      return (
        <VoucherDetailsEdit
          refetch={refetch}
          onBack={() => setMode(MODE.DEFAULT)}
          data={fullDetails.promo}
        />
      );
    }

    return (
      <VoucherFullDetails
        refetch={refetch}
        data={fullDetails}
        onSetMode={(m: MODE) => setMode(m)}
      />
    );
  }, [mode, fullDetails]);

  return (
    <AppModal
      {...props}
      size="5xl"
      onClose={() => {
        setMode(MODE.DEFAULT);
        props.onClose();
      }}
      onOverlayClick={props.onClose}
      title={fullDetails?.promo.code || '-'}
    >
      {!props.disableNext && (
        <Flex
          position="absolute"
          right="-150px"
          top="40vh"
          width="60px"
          height="60px"
          borderRadius="30px"
          zIndex="100"
          backgroundColor="#fff"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={props.onNext}
        >
          <ArrowRightIcon />
        </Flex>
      )}
      {!props.disablePrev && (
        <Flex
          position="absolute"
          left="-150px"
          top="40vh"
          width="60px"
          height="60px"
          borderRadius="30px"
          zIndex="100"
          backgroundColor="#fff"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={props.onPrev}
        >
          <ArrowLeftIcon />
        </Flex>
      )}
      {Content}
    </AppModal>
  );
};

export default VoucherDetailsView;
