import React, { useRef } from 'react';

import { Box, Flex, HStack, useToast } from '@chakra-ui/react';
import { Form, Formik, FormikProps } from 'formik';
import { useMutation } from 'react-query';

import charities, { CharityUpdatePayload } from 'api/charities';
import AppButton from 'components/primitives/Button';
import FormikRadio from 'components/primitives/FormikRadio';
import FormikTextField from 'components/primitives/FormikTextField';
import Typography from 'components/primitives/Typography';
import { ApiResponseError } from 'types/Api';
import { Charity } from 'types/Charities';

import { EditForm, CharityDetailsEditProps } from './CharityDetailsEdit.props';
import { Schema } from './CharityDetailsEdit.utils';

const transformToPayload = (
  data: Charity,
  form: EditForm
): CharityUpdatePayload => ({
  name: data.name !== form.name ? form.name : undefined,
  priority:
    data.priority !== Number(form.priority) ? Number(form.priority) : undefined,
  status: data.status !== form.status ? form.status : undefined,
});

const CharityDetailsEditView = (
  props: CharityDetailsEditProps
): JSX.Element => {
  const toast = useToast();
  const formRef = useRef<FormikProps<any>>(null);

  const { isLoading, mutate } = useMutation(
    (fields: EditForm) =>
      charities.doUpdate(props.data.id, transformToPayload(props.data, fields)),
    {
      onSuccess: () => {
        toast({
          description: 'Voucher updated successfully.',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
        props.onBack();
        props.refetch();
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  return (
    <Formik<EditForm>
      innerRef={formRef}
      initialValues={{
        name: props.data.name,
        priority: props.data.priority || 0,
        status: props.data.status || 'active',
      }}
      validateOnChange={false}
      validationSchema={Schema}
      onSubmit={(fields) => mutate(fields)}
    >
      <Form>
        <Flex mt={3} flexDir="column" border="1px solid #ddd">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px={4}
            py={4}
            borderBottom="1px solid #ddd"
          >
            <Typography variant="type8" color="shade5" weight="600">
              EDIT CHARITY
            </Typography>
          </Flex>
          <Flex flexDirection="column" p={5}>
            <Flex mt={8} maxW="350px" flexDir="column">
              <FormikTextField fieldName="name" label="CHARITY NAME" />
              <FormikTextField
                type="number"
                fieldName="priority"
                label="PRIORITY"
              />
              <Box mb={2}>
                <Typography variant="type9" color="shade5">
                  STATUS
                </Typography>
              </Box>
              <Flex gridGap="10">
                <FormikRadio
                  checkedValue="active"
                  fieldName="status"
                  label="Active"
                />
                <FormikRadio
                  checkedValue="inactive"
                  fieldName="status"
                  label="Deactivated"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end" mt={5}>
          <HStack spacing={5}>
            <AppButton
              type="submit"
              loading={isLoading}
              disabled={isLoading}
              variant="small"
            >
              Save
            </AppButton>
            <AppButton
              onClick={props.onBack}
              kind="secondary"
              variant="small"
              disabled={isLoading}
            >
              Cancel
            </AppButton>
          </HStack>
        </Flex>
      </Form>
    </Formik>
  );
};

export default CharityDetailsEditView;
