import React, { useMemo } from 'react';

import { Flex, Link } from '@chakra-ui/react';
import { format } from 'date-fns';

import StaticTable from 'components/layouts/StaticTable';
import Button from 'components/primitives/Button';
import Typography from 'components/primitives/Typography';
import { routesSettings } from 'constants/routes';
import getFullPropertyTitle from 'utils/getFullPropertyTitle';
import ucFirst from 'utils/string/ucFirst';

import { MODE } from '../VoucherDetails/VoucherDetails.props';
import { VoucherFullDetailsProps } from './VoucherFullDetails.props';

export const columns = [
  'User Name',
  'User Email',
  'Property Address',
  'Redeemed On',
];

const VoucherFullDetailsView = (
  props: VoucherFullDetailsProps
): JSX.Element => {
  const usageSummary = useMemo(
    () =>
      (props.data?.usages.slice(0, 5) || []).map((tr) => [
        <Link
          href={`${routesSettings.MAIN_USERS.path}?id=${tr.user.id}`}
          key={`link:${tr.user.id}`}
        >
          <Typography variant="type10" color="primary" weight="600">
            {`${tr.user.firstName} ${tr.user.lastName}`}
          </Typography>
        </Link>,
        tr.user.email,
        <Link
          href={`${routesSettings.MAIN_LISTINGS.path}?id=${tr.property.id}`}
          key={`link:${tr.property.id}`}
        >
          <Typography variant="type10" color="primary" weight="600">
            {getFullPropertyTitle(tr.property.title, tr.property.location)}
          </Typography>
        </Link>,
        tr.createdAt
          ? format(new Date(tr.createdAt), 'dd/MM/y HH:mm:ss a')
          : '-',
      ]),
    [props.data?.usages]
  );

  return (
    <>
      <Flex
        flexDir="column"
        border="1px solid #ddd"
        p={4}
        flex={4}
        height="100%"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #ddd"
          m={-4}
          px={4}
          py={2}
          mb={4}
        >
          <Typography variant="type8" color="shade5" weight="600">
            VOUCHER DETAILS
          </Typography>
          <Button
            variant="small"
            outline
            onClick={() => props.onSetMode(MODE.EDIT)}
          >
            Edit
          </Button>
        </Flex>
        <Flex>
          <Flex flex={1} flexDirection="column">
            <Flex flex={1} flexWrap="wrap">
              <Flex flexDirection="column" w="20%" mt={4}>
                <Typography variant="type10" color="shade1" weight="600">
                  VOUCHER CODE
                </Typography>
                <Typography
                  variant="type8"
                  color="shade5"
                  style={{ marginTop: 5 }}
                >
                  {props.data.promo.code}
                </Typography>
              </Flex>
              <Flex flexDirection="column" w="20%" mt={4}>
                <Typography variant="type10" color="shade1" weight="600">
                  MAX USE PER CODE
                </Typography>
                <Typography
                  variant="type8"
                  color="shade5"
                  style={{ marginTop: 5 }}
                >
                  {props.data.promo.maxUseCount}
                </Typography>
              </Flex>
              <Flex flexDirection="column" w="20%" mt={4}>
                <Typography variant="type10" color="shade1" weight="600">
                  MAX USE PER USER
                </Typography>
                <Typography
                  variant="type8"
                  color="shade5"
                  style={{ marginTop: 5 }}
                >
                  {props.data.promo.maxPerUser}
                </Typography>
              </Flex>
              <Flex flexDirection="column" w="20%" mt={4}>
                <Typography variant="type10" color="shade1" weight="600">
                  STATUS
                </Typography>
                <Typography
                  variant="type8"
                  color="shade5"
                  style={{ marginTop: 5 }}
                >
                  {ucFirst(props.data.promo.status)}
                </Typography>
              </Flex>
              <Flex flexDirection="column" w="20%" mt={4}>
                <Typography variant="type10" color="shade1" weight="600">
                  EXPIRES ON
                </Typography>
                <Typography
                  variant="type8"
                  color="shade5"
                  style={{ marginTop: 5 }}
                >
                  {props.data.promo.expiredAt
                    ? format(
                        new Date(props.data.promo.expiredAt),
                        'MM/dd/y kk:mm:ss a'
                      )
                    : '-'}
                </Typography>
              </Flex>
              <Flex flexDirection="column" w="20%" mt={4}>
                <Typography variant="type10" color="shade1" weight="600">
                  LAST UPDATED
                </Typography>
                <Typography
                  variant="type8"
                  color="shade5"
                  style={{ marginTop: 5 }}
                >
                  {format(
                    new Date(props.data.promo.updatedAt),
                    'MM/dd/y kk:mm:ss a'
                  )}
                </Typography>
              </Flex>
              <Flex flexDirection="column" w="20%" mt={4}>
                <Typography variant="type10" color="shade1" weight="600">
                  NO. OF TIMES REDEEMED
                </Typography>
                <Typography
                  variant="type8"
                  color="shade5"
                  style={{ marginTop: 5 }}
                >
                  {props.data.promo.timesUsed}
                </Typography>
              </Flex>
              <Flex flexDirection="column" w="20%" mt={4}>
                <Typography variant="type10" color="shade1" weight="600">
                  LAST USED
                </Typography>
                <Typography
                  variant="type8"
                  color="shade5"
                  style={{ marginTop: 5 }}
                >
                  {props.data.promo.lastUsed
                    ? format(
                        new Date(props.data.promo.lastUsed),
                        'MM/dd/y kk:mm:ss a'
                      )
                    : '-'}
                </Typography>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        flexDir="column"
        border="1px solid #ddd"
        p={4}
        mt={4}
        flex={4}
        height="100%"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #ddd"
          m={-4}
          px={4}
          py={2}
          mb={4}
        >
          <Typography variant="type8" color="shade5" weight="600">
            VOUCHER USERS
          </Typography>
        </Flex>
        <StaticTable columns={columns} data={usageSummary} />
      </Flex>
    </>
  );
};

export default VoucherFullDetailsView;
