/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  CircularProgress,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { isEmpty } from 'ramda';
import { useHistory } from 'react-router';
import { useTable, useSortBy } from 'react-table';

import PaginationView from 'components/primitives/Pagination';
import { Listing } from 'components/primitives/SVG';
import Typography from 'components/primitives/Typography';
import { routesSettings } from 'constants/routes';

import { FeedbacksGeneratedProps } from './Feedbacks.props';

const widthPerAccessor = {
  undefined: undefined,
};

const FeedbacksView = ({
  data,
  meta,
  isLoading,
  tableOptions,
  setTableOptions,
}: FeedbacksGeneratedProps): JSX.Element => {
  const history = useHistory();

  const onViewUserProfile = (id: string) => {
    history.push(`${routesSettings.MAIN_USERS.path}?id=${id}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'user',
        Cell: ({ row }: any) =>
          `${row.original.user?.firstName} ${row.original.user?.lastName}`,
      },
      {
        Header: 'Message',
        accessor: 'message',
      },
      {
        Header: 'Platform',
        accessor: 'platform',
      },
      {
        Header: 'Version',
        accessor: 'version',
      },
      {
        Header: 'Created At',
        accessor: 'date',
        Cell: ({ row }: any) =>
          format(new Date(row.original.createdAt), 'MM/dd/y kk:mm:ss a'),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      data,
      // @ts-ignore
      columns,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortBy: isLoading,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );

  const onPaginate = (page: number, pageSize: number) => {
    setTableOptions((prevState) => {
      return {
        ...prevState,
        page: prevState.limit !== pageSize ? 1 : page,
        limit: pageSize,
      };
    });
  };

  useEffect(() => {
    setTableOptions((prevState) => {
      if (!isEmpty(sortBy)) {
        const { id, desc } = sortBy[0];
        return {
          ...prevState,
          sort: id,
          direction: desc ? 'desc' : 'asc',
        };
      } else if (isEmpty(sortBy) && !!prevState.sort) {
        const psCopy = { ...prevState };
        delete psCopy.sort;
        delete psCopy.direction;

        return psCopy;
      }

      return prevState;
    });
  }, [sortBy]);

  return (
    <Flex flexDirection="column">
      <Flex flex={1}>
        <Typography variant="type5" weight="700" color="primary">
          FEEDBACKS
        </Typography>
      </Flex>
      <Flex
        mt={5}
        flex={1}
        borderRadius={10}
        p={5}
        minHeight="500px"
        bg="#fff"
        filter="drop-shadow(0px 2px 10px rgba(149, 149, 149, 0.18))"
        flexDirection="column"
      >
        <Flex
          flexDir="row"
          width="100%"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Flex height="30px" alignItems="center">
            <Listing color="shade10" width={12} height={12} />
            <Flex mx={3}>
              <Typography variant="type8" weight="600" color="shade10">
                ALL FEEDBACKS
              </Typography>
            </Flex>
            {isLoading ? (
              <CircularProgress isIndeterminate size="20px" color="primary" />
            ) : (
              <Typography variant="type10" color="shade5">
                {meta.count} feedback{meta.count > 1 && 's'}
              </Typography>
            )}
          </Flex>
        </Flex>
        <Table size="sm" mt={7} {...getTableProps()}>
          <Thead backgroundColor="shade12">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {
                      // @ts-ignore
                      ...column.getHeaderProps(column.getSortByToggleProps())
                    }
                  >
                    <Flex flexDirection="row">
                      <Typography variant="type9" weight="600" color="shade5">
                        {column.Header}
                      </Typography>
                      <Flex ml={2}>
                        {
                          // @ts-ignore
                          column.isSorted ? (
                            <Box>
                              {
                                // @ts-ignore
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              }
                            </Box>
                          ) : (
                            <Box width="12px" height="5px" />
                          )
                        }
                      </Flex>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  backgroundColor={i % 2 !== 0 ? 'shade18' : 'transparent'}
                  cursor="pointer"
                  onClick={() => onViewUserProfile(row.original.user?.id)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      // @ts-ignore
                      width={widthPerAccessor[cell.column.id]}
                    >
                      <Typography variant="type8" color="shade5">
                        {cell.render('Cell')}
                      </Typography>
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex mt={5} flexDir="row" width="100%" justifyContent="flex-end">
          <PaginationView
            disabled={isLoading}
            total={meta.count}
            current={meta.page}
            pageSize={tableOptions.limit}
            onChange={onPaginate}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FeedbacksView;
