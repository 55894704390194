import React, { useMemo, useState } from 'react';

import { Button } from '@chakra-ui/button';
import { CheckCircleIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Flex, HStack } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Box } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import { useToast } from '@chakra-ui/toast';
import { format as formatDate, format } from 'date-fns';
import { partial } from 'ramda';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router';

import usersApi from 'api/users';
import StaticTable from 'components/layouts/StaticTable';
import Avatar from 'components/primitives/Avatar';
import AppButton from 'components/primitives/Button';
import Label from 'components/primitives/Label';
import PaginationView from 'components/primitives/Pagination';
import { Award } from 'components/primitives/SVG';
import Typography from 'components/primitives/Typography';
import marketPrice from 'constants/marketPrice.json';
import { FETCH_USER_LOGS } from 'constants/queries';
import { routesSettings } from 'constants/routes';
import { ApiResponseError } from 'types/Api';
import getFullPropertyTitle from 'utils/getFullPropertyTitle';
import ucFirst from 'utils/string/ucFirst';
import { useTheme } from 'utils/theme';

import { MODE } from '../UserDetails/UserDetails.props';
import { UserFullDetailsProps } from './UserFullDetails.props';
import {
  transactionHistoryColumns,
  sellingColumns,
  sellStatusLabel,
  buyStatusLabel,
  buyingColumns,
  buyFinanceLabel,
  directColumns,
  propertyTypeLabels,
  logColumns,
  facilitateColumns,
} from './UserFullDetails.utils';

const UserLogsEvent = (props: {
  event: { type: string; data: any };
}): JSX.Element => {
  if (props.event.type === 'login_logs') {
    return (
      <span>{`Logged in with ${
        props.event.data.new.loginMethod === 'emailPassword'
          ? 'Email/Password'
          : ucFirst(props.event.data.new.loginMethod)
      }`}</span>
    );
  }
  if (props.event.type === 'REGISTERED') {
    return (
      <span>{`Registered using ${props.event.data?.new?.loginMethod}.`}</span>
    );
  }

  return <span>{props.event.type}</span>;
};

export const TABLE_OPTIONS = {
  page: 1,
  limit: 10,
};

const UserFullDetailsView = (props: UserFullDetailsProps): JSX.Element => {
  const theme = useTheme();
  const toast = useToast();
  const history = useHistory();
  const { data } = props;
  const [logsTableOptions, setLogsTableOptions] =
    useState<Record<string, any>>(TABLE_OPTIONS);

  const { mutate: makePremium } = useMutation(
    () => usersApi.doMakePremium(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: 'User is now Premium',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: makeNonPremium } = useMutation(
    () => usersApi.doMakeNonPremium(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: 'User is now Non-Premium',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: suspendUser } = useMutation(
    () => usersApi.doSuspend(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: 'User is now suspended',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: undoSuspend } = useMutation(
    () => usersApi.undoSuspend(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: 'User suspension has been undone',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: verifyPhone } = useMutation(
    () => usersApi.doVerifyPhone(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: `User's phone has been verified.`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
    }
  );

  const { mutate: unverifyPhone } = useMutation(
    () => usersApi.doUnVerifyPhone(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: `User's phone has been unverified.`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: verifyEmail } = useMutation(
    () => usersApi.doVerifyEmail(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: `User's email has been verified.`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Request failed',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: undoVerifyEmail } = useMutation(
    () => usersApi.doUndoVerifyEmail(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: `User's email set to unverified.`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Request failed',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const { mutate: askForRating } = useMutation(
    () => usersApi.doAskForRating(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: `User will be prompted for feedback.`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
    }
  );

  const { mutate: cancelAskForRating } = useMutation(
    () => usersApi.doCancelAskForRating(props.data.id),
    {
      onSuccess: () => {
        props.refetch();
        toast({
          description: `Canceled user feedback prompt.`,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
    }
  );

  const onViewListing = (group: string, index: number) => {
    data &&
      history.push({
        pathname: routesSettings.MAIN_LISTINGS.path,
        // @ts-ignore
        search: `?id=${data[group].data[index].id}`,
      });
  };

  const transactionHistorySummary = useMemo(
    () =>
      (data?.transations.data.slice(0, 5) || []).map((tr) => [
        tr.label,
        `$${tr.amount}`,
        format(new Date(tr.createdAt), 'dd/MM/y HH:mm:ss a'),
      ]),
    [data?.transations]
  );

  const sellingSummary = useMemo(
    () =>
      (data?.selling.data || []).map((s) => [
        propertyTypeLabels[s.propertyType],
        getFullPropertyTitle(s.street, s.location),
        marketPrice.seller.find((m) => m.value === s.marketingPrice)?.label ||
          '-',
        `${s.matchesCount}`,
        sellStatusLabel[s.status],
      ]),
    [data?.selling]
  );

  const { isFetching: isUserLogsFetching, data: userLogs } = useQuery(
    `${FETCH_USER_LOGS}${props.data.id}?page=${logsTableOptions.page}&limit=${logsTableOptions.limit}`,
    () =>
      props.data.id
        ? usersApi.getLogs(
            props.data.id,
            logsTableOptions.page,
            logsTableOptions.limit
          )
        : undefined
  );

  const logs = useMemo(
    () =>
      (userLogs?.data || []).map((log) => [
        <UserLogsEvent
          event={{
            type: log.type,
            data: {
              property: log.property,
              match: log.match,
              user: log.user,
              old: log.old,
              new: log.new,
            },
          }}
          key={log.id}
        />,
        (log.device?.type || '-').toUpperCase(),
        log.device?.name || '-',
        log.device?.os || '-',
        log.device?.app_version || '-',
        `${log.executedBy.type} - ${log.executedBy.displayName}`,
        log.executedOn
          ? formatDate(new Date(log.executedOn), 'MM/dd/yyyy hh:mm:ss a')
          : '',
      ]),
    [userLogs]
  );

  const buyingSummary = useMemo(
    () =>
      (data?.buying.data || []).map((s) => [
        propertyTypeLabels[s.propertyType],
        getFullPropertyTitle(s.title, s.location),
        marketPrice.buyer.find((m) => m.value === s.marketingPrice)?.label ||
          '-',
        `${s.matchesCount}`,
        buyFinanceLabel[s.financeStatus],
        buyStatusLabel[s.status],
      ]),
    [data?.selling]
  );

  const directSummary = useMemo(
    () =>
      (data?.direct.data || []).map((s) => [
        propertyTypeLabels[s.propertyType],
        getFullPropertyTitle(s.title, s.location),
        marketPrice.seller.find((m) => m.value === s.marketingPrice)?.label ||
          '-',
        sellStatusLabel[s.status],
        s.addedAt ? format(new Date(s.addedAt), 'dd/MM/y HH:mm:ss a') : '-',
      ]),
    [data?.direct]
  );

  const facilitateSummary = useMemo(
    () =>
      (data?.facilitating.data || []).map((s) => [
        propertyTypeLabels[s.propertyType],
        getFullPropertyTitle(s.street, s.location),
        `${s.seller.firstName} ${s.seller.lastName}`,
        sellStatusLabel[s.status],
        s.acceptedOn
          ? format(new Date(s.acceptedOn), 'dd/MM/y HH:mm:ss a')
          : '-',
      ]),
    [data?.facilitating]
  );

  if (!data) return <Flex />;

  return (
    <Flex flexDirection="column">
      <HStack spacing={3} alignItems="flex-start" height={400}>
        <Flex
          flexDir="column"
          border="1px solid #ddd"
          p={4}
          flex={4}
          height="100%"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #ddd"
            m={-4}
            px={4}
            py={2}
            mb={4}
          >
            <Typography variant="type8" color="shade5" weight="600">
              USER DETAILS
            </Typography>
            <Flex alignItems="center">
              {data.isSuspended ? (
                <Flex mr={2} height="30px">
                  <Label
                    typographyProps={{
                      variant: 'type11',
                      color: 'secondary',
                      style: {
                        height: 10,
                      },
                    }}
                    color="special2"
                  >
                    SUSPENDED
                  </Label>
                </Flex>
              ) : (
                <Flex mr={2} height="30px">
                  <Label
                    typographyProps={{
                      variant: 'type11',
                      color: 'secondary',
                      style: {
                        height: 10,
                      },
                    }}
                    color="special5"
                  >
                    ACTIVE
                  </Label>
                </Flex>
              )}
              {data.isPremium && (
                <Flex width={140} height="30px">
                  <Label
                    LeftComponent={
                      <Flex mt={0.2} mr={1}>
                        <Award height={9} />
                      </Flex>
                    }
                    premium
                    typographyProps={{
                      variant: 'type11',
                      color: 'secondary',
                      style: {
                        height: 9,
                      },
                    }}
                  >
                    PREMIUM
                  </Label>
                </Flex>
              )}
              <Flex ml={2}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon color={theme.colors.primary} />}
                    border={`1px solid ${theme.colors.primary}`}
                    backgroundColor="#fff"
                  >
                    <Typography color="primary" weight="600">
                      Actions
                    </Typography>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => props.onSetMode(MODE.EDIT)}>
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        data.isPremium ? makeNonPremium() : makePremium()
                      }
                    >
                      Mark as {data.isPremium ? 'non-premium' : 'premium'}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        data.isSuspended ? undoSuspend() : suspendUser()
                      }
                    >
                      {data.isSuspended ? 'Undo Suspend' : 'Suspend'}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        data.phoneVerified ? unverifyPhone() : verifyPhone()
                      }
                    >
                      {data.phoneVerified ? 'Unverify Phone' : 'Verify Phone'}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        data.emailVerified ? undoVerifyEmail() : verifyEmail()
                      }
                    >
                      {data.emailVerified ? 'Unverify Email' : 'Verify Email'}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        data.showRating === 'force-show'
                          ? cancelAskForRating()
                          : askForRating()
                      }
                    >
                      {data.showRating === 'force-show'
                        ? 'Cancel Ask App Rating'
                        : 'Ask App Rating'}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
          </Flex>
          <Flex>
            <Box ml={3} mr={10}>
              <Avatar size={80} src={data.profilePic?.medium || ''} />
            </Box>
            <Flex flex={1} flexDirection="column">
              <Flex flex={1}>
                <Flex flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    FIRST NAME
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.firstName}
                  </Typography>
                </Flex>
                <Flex flexDirection="column" flex={1}>
                  <Typography variant="type10" color="shade1" weight="600">
                    LAST NAME
                  </Typography>
                  <Typography variant="type8" color="shade5">
                    {data.lastName}
                  </Typography>
                </Flex>
              </Flex>
              <Flex mt={8} flexDirection="column" flex={1}>
                <Typography variant="type10" color="shade1" weight="600">
                  EMAIL ADDRESS
                </Typography>
                <Typography variant="type8" color="shade5">
                  {data.email}
                </Typography>
              </Flex>
              <Flex mt={8} flexDirection="column" flex={1}>
                <Typography variant="type10" color="shade1" weight="600">
                  PHONE NUMBER
                </Typography>
                <Typography variant="type8" color="shade5">
                  +{data.phoneCode}
                  {data.phone}
                  {data.phoneVerified && (
                    <chakra.span ml={2}>
                      <CheckCircleIcon color={theme.colors.special1} />
                    </chakra.span>
                  )}
                </Typography>
              </Flex>
              <Flex mt={8} flexDirection="column" flex={1}>
                <Typography variant="type10" color="shade1" weight="600">
                  DATE JOINED
                </Typography>
                <Typography variant="type8" color="shade5">
                  {data.dateJoined
                    ? format(new Date(data.dateJoined), 'dd/MM/y HH:mm:ss a')
                    : '-'}
                </Typography>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex mt={3} flexDir="column" border="1px solid #ddd" height="100%">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px={4}
            py={4}
          >
            <Typography variant="type8" color="shade5" weight="600">
              TRANSACTION HISTORY ({transactionHistorySummary.length})
            </Typography>
          </Flex>
          <StaticTable
            columns={transactionHistoryColumns}
            data={transactionHistorySummary}
          />
          <Flex flexDir="column" m={4}>
            <AppButton
              onClick={() => props.onSetMode(MODE.TRANSACTIONS)}
              variant="small"
              outline
            >
              See All
            </AppButton>
          </Flex>
        </Flex>
      </HStack>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
          <Typography variant="type8" color="shade5" weight="600">
            SELLING ({sellingSummary.length})
          </Typography>
          <Flex></Flex>
        </Flex>
        <StaticTable
          onRowClick={partial(onViewListing, ['selling'])}
          columns={sellingColumns}
          data={sellingSummary}
        />
      </Flex>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
          <Typography variant="type8" color="shade5" weight="600">
            BUYING ({buyingSummary.length})
          </Typography>
          <Flex></Flex>
        </Flex>
        <StaticTable
          onRowClick={partial(onViewListing, ['buying'])}
          columns={buyingColumns}
          data={buyingSummary}
        />
      </Flex>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
          <Typography variant="type8" color="shade5" weight="600">
            DIRECT CONTACTS ({directSummary.length})
          </Typography>
          <Flex></Flex>
        </Flex>
        <StaticTable
          onRowClick={partial(onViewListing, ['direct'])}
          columns={directColumns}
          data={directSummary}
        />
      </Flex>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
          <Typography variant="type8" color="shade5" weight="600">
            FACILITATE ({facilitateSummary.length})
          </Typography>
          <Flex></Flex>
        </Flex>
        <StaticTable
          onRowClick={partial(onViewListing, ['facilitating'])}
          columns={facilitateColumns}
          data={facilitateSummary}
        />
      </Flex>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
          <Typography variant="type8" color="shade5" weight="600">
            LOGS ({userLogs?.meta.count})
          </Typography>
          <Flex></Flex>
        </Flex>
        <StaticTable columns={logColumns} data={logs} />
        <Flex mt={5} flexDir="row" width="100%" justifyContent="flex-end">
          <PaginationView
            disabled={isUserLogsFetching}
            total={userLogs?.meta.count}
            current={userLogs?.meta.page}
            pageSize={logsTableOptions.limit}
            onChange={(page: number, pageSize: number) => {
              setLogsTableOptions((prevState) => ({
                ...prevState,
                page: prevState.limit !== pageSize ? 1 : page,
                limit: pageSize,
              }));
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UserFullDetailsView;
