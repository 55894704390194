import React from 'react';

import { Box } from '@chakra-ui/react';

import Typography from '../Typography';
import { NumberFormatFieldProps } from './NumberFormatField.props';
import {
  StyledNumberFormat,
  Error,
  ErrorContainer,
} from './NumberFormatField.style';

const NumberFormatField = ({
  label,
  hideSpacing,
  error = '',
  errorProps,
  ...numberFormatProps
}: NumberFormatFieldProps): JSX.Element => {
  return (
    <Box>
      {label && (
        <Box mb={2}>
          <Typography variant="type9" color="shade5">
            {label}
          </Typography>
        </Box>
      )}
      <StyledNumberFormat error={error} {...numberFormatProps} />
      {(!hideSpacing || error) && (
        <ErrorContainer>
          <Error color="special2" {...errorProps}>
            {error}
          </Error>
        </ErrorContainer>
      )}
    </Box>
  );
};

export default NumberFormatField;
