export enum SellPropertyStatus {
  FOR_SALE = 'active',
  SOLD = 'sold',
  WITHDRAWN = 'withdrawn',
  PRE_REGISTRATION = 'pre-registration',
  DELETED = 'deleted',
}

export const SellPropertyStatusOptions = [
  { label: 'For Sale', value: SellPropertyStatus.FOR_SALE },
  { label: 'Sold', value: SellPropertyStatus.SOLD },
  { label: 'Withdrawn - Kept', value: SellPropertyStatus.WITHDRAWN },
  { label: 'Withdrawn - Removed', value: SellPropertyStatus.DELETED },
  { label: 'Pre-registration', value: SellPropertyStatus.PRE_REGISTRATION },
];

export const SellPropertyStatusPreRegistrationOption = [
  { label: 'Pre-registration', value: SellPropertyStatus.PRE_REGISTRATION },
];

export enum BuyPropertyStatus {
  LOOKING_TO_BUY = 'looking to buy',
  PURCHASED = 'purchased',
  NO_LONGER_LOOKING = 'no longer looking',
  DELETED = 'deleted',
}

export const BuyPropertyStatusOptions = [
  { label: 'Looking to buy', value: BuyPropertyStatus.LOOKING_TO_BUY },
  { label: 'Purchased', value: BuyPropertyStatus.PURCHASED },
  { label: 'Deleted', value: BuyPropertyStatus.DELETED },
  { label: 'No longer looking', value: BuyPropertyStatus.NO_LONGER_LOOKING },
];

export enum FinancialStatus {
  PRE_APPROVED = 'pre approved',
  FUNDS_AVAILABLE = 'funds available',
  SUBJECT_TO_PROPERTY_SALE = 'subject to property sale',
}

export const FinancialStatusOptions = [
  { label: 'Pre-Approved', value: FinancialStatus.PRE_APPROVED },
  { label: 'Funds Available', value: FinancialStatus.FUNDS_AVAILABLE },
  {
    label: 'Subject To Property Sale',
    value: FinancialStatus.SUBJECT_TO_PROPERTY_SALE,
  },
];

export enum PropertyTypes {
  HOUSE = 'house',
  APARTMENT = 'apartment',
  UNIT = 'unit',
  SECTION = 'section',
  LIFESTYLE_BARELAND = 'lifestyle bareland',
  LIFESTYLE_DWELLING = 'lifestyle dwelling',
  COMMERCIAL_PROPERTY = 'commercial property',
  HORTICULTURAL_PROPERTY = 'horticultural property',
  AGRICULTURAL_PROPERTY = 'agricultural property',
}

export const PropertyTypesOptions = [
  { label: 'House', value: PropertyTypes.HOUSE },
  { label: 'Apartment', value: PropertyTypes.APARTMENT },
  { label: 'Unit', value: PropertyTypes.UNIT },
  { label: 'Section', value: PropertyTypes.SECTION },
  { label: 'Lifestyle Bareland', value: PropertyTypes.LIFESTYLE_BARELAND },
  { label: 'Lifestyle Dwelling', value: PropertyTypes.LIFESTYLE_DWELLING },
  { label: 'Commercial Property', value: PropertyTypes.COMMERCIAL_PROPERTY },
  {
    label: 'Horticultural Property',
    value: PropertyTypes.HORTICULTURAL_PROPERTY,
  },
  {
    label: 'Agricultural Property',
    value: PropertyTypes.AGRICULTURAL_PROPERTY,
  },
];

export const typesThatExcludeBedroom = [
  PropertyTypes.SECTION,
  PropertyTypes.LIFESTYLE_BARELAND,
  PropertyTypes.COMMERCIAL_PROPERTY,
  PropertyTypes.HORTICULTURAL_PROPERTY,
  PropertyTypes.AGRICULTURAL_PROPERTY,
];
export const typesThatExcludeGarage = [
  PropertyTypes.SECTION,
  PropertyTypes.LIFESTYLE_BARELAND,
  PropertyTypes.COMMERCIAL_PROPERTY,
  PropertyTypes.HORTICULTURAL_PROPERTY,
  PropertyTypes.AGRICULTURAL_PROPERTY,
];
export const typesExcludingOptional = [
  PropertyTypes.SECTION,
  PropertyTypes.LIFESTYLE_BARELAND,
  PropertyTypes.COMMERCIAL_PROPERTY,
  PropertyTypes.HORTICULTURAL_PROPERTY,
  PropertyTypes.AGRICULTURAL_PROPERTY,
];
export const typesExcludingFencing = [
  PropertyTypes.SECTION,
  PropertyTypes.COMMERCIAL_PROPERTY,
];

export const OtherFeaturesOptions = [
  {
    label: 'Office',
    value: 'office',
  },
  {
    label: 'Rumpus Room',
    value: 'rumpus room',
  },
  {
    label: 'Workshop/Shed',
    value: 'workshop/shed',
  },
  {
    label: 'Basement Storage',
    value: 'basement storage',
  },
  {
    label: 'Garden Shed',
    value: 'garden shed',
  },
  {
    label: 'Pool',
    value: 'pool',
  },
  {
    label: 'Spa',
    value: 'spa',
  },
];

export const BedroomOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6+',
    value: 6,
  },
];

export const GarageOptions = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: '1 Bay',
    value: 1,
  },
  {
    label: '2 Bay',
    value: 2,
  },
  {
    label: '3 Bay',
    value: 3,
  },
  {
    label: '4+ Bay',
    value: 4,
  },
];

export const ParkingOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6+',
    value: 6,
  },
];

export const FenceOptions = [
  {
    label: 'Fully fenced',
    value: 'fully fenced',
  },
  {
    label: 'Partially fenced',
    value: 'partially fenced',
  },
  {
    label: 'Not fenced',
    value: 'no fenced',
  },
];

export const HeatingOptions = [
  {
    label: 'Gas',
    value: 'gas',
  },
  {
    label: 'Electric',
    value: 'electric',
  },
  {
    label: 'Heatpump',
    value: 'heatpump',
  },
  {
    label: 'Central Heating',
    value: 'central heating',
  },
  {
    label: 'Log Burner',
    value: 'log burner',
  },
  {
    label: 'Pellet Fire',
    value: 'pellet fire',
  },
  {
    label: 'Pot Belly Stove',
    value: 'pot belly stove',
  },
  {
    label: 'Open Fire',
    value: 'open fire',
  },
  {
    label: 'Solar Heating',
    value: 'solar heating',
  },
  {
    label: 'Night Store',
    value: 'night store',
  },
  {
    label: 'HRV',
    value: 'hrv',
  },
  {
    label: 'Heat Transfer System',
    value: 'heat transfer system',
  },
];

export const LandAreaOptions = [
  {
    label: '100m²',
    value: 100,
  },
  {
    label: '200m²',
    value: 200,
  },
  {
    label: '300m²',
    value: 300,
  },
  {
    label: '500m²',
    value: 500,
  },
  {
    label: '750m²',
    value: 750,
  },
  {
    label: '1000m²',
    value: 1000,
  },
  {
    label: '2000m²',
    value: 2000,
  },
  {
    label: '5000m²',
    value: 5000,
  },
  {
    label: '1HA',
    value: 10000,
  },
  {
    label: '2HA',
    value: 20000,
  },
  {
    label: '5HA',
    value: 50000,
  },
  {
    label: '10HA',
    value: 100000,
  },
  {
    label: '15HA',
    value: 150000,
  },
  {
    label: '20HA',
    value: 200000,
  },
  {
    label: '25HA',
    value: 250000,
  },
  {
    label: '50HA',
    value: 500000,
  },
  {
    label: '75HA',
    value: 750000,
  },
  {
    label: '100HA',
    value: 1000000,
  },
  {
    label: '250HA',
    value: 2500000,
  },
  {
    label: '500HA',
    value: 5000000,
  },
  {
    label: '750HA',
    value: 7500000,
  },
  {
    label: '10000HA',
    value: 10000000,
  },
];

export const LandUnitOptions = [
  {
    label: 'm²',
    value: 'm2',
  },
  {
    label: 'hectares',
    value: 'hectares',
  },
];

export const LivingAreaOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
];

export const BathroomOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5+',
    value: 5,
  },
];

export const ToiletOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5+',
    value: 5,
  },
];

export const GlazingOptions = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Single Tinted',
    value: 'single tinted',
  },
  {
    label: 'Double',
    value: 'double',
  },
  {
    label: 'Double Tinted',
    value: 'double tinted',
  },
  {
    label: 'Single & Double',
    value: 'single & double',
  },
  {
    label: 'Hush Glass',
    value: 'hush glass',
  },
];

export enum OPEN_HOME_STATUS {
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  INVITE = 'INVITE',
  UNDECIDED = 'UNDECIDED',
}

export enum PRIVATE_VIEWING_STATUS {
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  INVITE = 'INVITE',
  REQUESTED = 'REQUESTED',
  NEW_MATCHES = 'NEW_MATCHES',
}

export enum PRE_REG_STATUS {
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  INVITED = 'INVITED',
}

export const RespondPrivateViewingInvitationOptions = [
  {
    label: 'New Private Viewing Invitation',
    value: PRIVATE_VIEWING_STATUS.INVITE,
  },
  { label: `I'm interested`, value: PRIVATE_VIEWING_STATUS.ACCEPTED },
  { label: 'No, thanks', value: PRIVATE_VIEWING_STATUS.DECLINED },
];

export const RespondPreRegistrationInvitationOptions = [
  {
    label: 'New Pre-registration Invitation',
    value: PRE_REG_STATUS.INVITED,
  },
  {
    label: 'Accept',
    value: PRE_REG_STATUS.ACCEPTED,
  },
  { label: 'No, Thanks', value: PRE_REG_STATUS.DECLINED },
];

export const RespondPrivateViewingRequestOptions = [
  {
    label: 'Respond to Private Viewing Request',
    value: PRIVATE_VIEWING_STATUS.REQUESTED,
  },
  { label: `I'm interested`, value: PRIVATE_VIEWING_STATUS.ACCEPTED },
  { label: 'No, thanks', value: PRIVATE_VIEWING_STATUS.DECLINED },
];

export const PreferredViewingSchedule = [
  { label: 'Any time suits', value: 'Any time suits' },
  { label: 'Weekends work best', value: 'Weekends work best' },
  { label: 'Any day after 5pm', value: 'Any day after 5pm' },
  { label: 'During school hours', value: 'During school hours' },
  { label: 'Custom', value: 'Custom' },
];

export enum RESPOND_FULL_DETAILS {
  PLACEHOLDER = 'RESPOND',
  ACTIVATE = 'ACTIVATE',
  IGNORE = 'IGNORE',
}

export const RespondFullDetailsRequestOptions = [
  {
    label: 'Respond to Full Details & Contact Info Request',
    value: RESPOND_FULL_DETAILS.PLACEHOLDER,
  },
  {
    label: `Activate to disclose full details to Buyers`,
    value: RESPOND_FULL_DETAILS.ACTIVATE,
  },
  { label: 'No, thanks', value: RESPOND_FULL_DETAILS.IGNORE },
];

export enum MarketingMethod {
  AUCTION = 'AUCTION',
  TENDER = 'TENDER',
  SET_DATE_OF_SALE = 'SET_DATE_OF_SALE',
}

export const MarketingMethodOptions = [
  {
    label: 'Auction',
    value: 'AUCTION',
  },
  {
    label: 'Tender',
    value: 'TENDER',
  },
  {
    label: 'Set Date of Sale',
    value: 'SET_DATE_OF_SALE',
  },
];

export const SET_DATE_OF_SALE = 'SET_DATE_OF_SALE';

export const CONFIDENTIAL_REGION = 'confidential';

export const PRICE_BY_NEGOTIATION = 'PRICE_BY_NEGOTIATION';

export const MarketingMethodLabel = {
  [MarketingMethod.AUCTION]: 'Auction',
  [MarketingMethod.SET_DATE_OF_SALE]: 'Set Date of Sale',
  [MarketingMethod.TENDER]: 'Tender',
};
