import styled from 'utils/styled';

import { AvatarProps } from './Avatar.props';

export const Image = styled.img<AvatarProps>`
  width: ${({ size }) => (size ? `${size}px` : '38px')};
  height: ${({ size }) => (size ? `${size}px` : '38px')};
  border-radius: 50%;
  object-fit: cover;
  position: relative;
`;
