import NumberFormat from 'react-number-format';

import styled from 'utils/styled';

import { pxToRem } from '../../../utils/theme';
import Typography from '../Typography';

export const StyledNumberFormat = styled(NumberFormat)<{
  error: string;
}>`
  background-color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.special2 : theme.colors.shade9};
  border-radius: 4px;
  padding: 13px;
  font-size: ${pxToRem(14)};
  line-height: 19px;
  outline: none;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.colors.shade10};
  }
`;

export const ErrorContainer = styled.div`
  min-height: ${pxToRem(14)};
  margin-top: 4px;
  padding-left: 1px;
`;

export const Error = styled(Typography)`
  font-size: ${pxToRem(10)};
  line-height: 14px;
`;
