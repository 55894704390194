import React, { useMemo, useState } from 'react';

import { ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Collapse, Flex, HStack } from '@chakra-ui/react';
import { format, isAfter } from 'date-fns';
import QueryString from 'qs';
import { groupBy } from 'ramda';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import properties from 'api/properties';
import AppButton from 'components/primitives/Button';
import Typography from 'components/primitives/Typography';
import { PRIVATE_VIEWING_STATUS } from 'constants/property';
import { FETCH_LISTING_MATCHES } from 'constants/queries';
import { Match } from 'types/Property';
import getFullPropertyTitle from 'utils/getFullPropertyTitle';
import { useTheme } from 'utils/theme';

import { BuyListingMatchesProps } from './BuyListingMatches.props';

const regularColumnGroup1 = [
  'Address',
  'Seller',
  'Match %',
  'Matched on',
  'Private Viewing',
];

const regularColumnGroup2 = [
  ' ',
  'Invited/Requested on',
  'Responded on',
  'Response Type',
  'Response',
];

const responseTypeStatusLabel: Record<string, string> = {
  [PRIVATE_VIEWING_STATUS.ACCEPTED]: 'Accepted',
  [PRIVATE_VIEWING_STATUS.DECLINED]: 'Declined',
};

const BuyListingMatchesView = (props: BuyListingMatchesProps): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [openedMatch, setOpenedMatch] = useState<string>();
  const { data = [] } = useQuery(`${FETCH_LISTING_MATCHES}${props.id}`, () =>
    properties.getListingMatches(props.id)
  );

  const { regular = [] } = useMemo(
    () =>
      groupBy(
        (m) => (m.buyingProfileId === 'direct' ? 'direct' : 'regular'),
        data
      ),
    [data]
  );

  const queries = useMemo(
    () => QueryString.parse(new URLSearchParams(location.search).toString()),
    [location.search]
  );

  const setListing = (id: string) =>
    history.push({
      pathname: location.pathname,
      search:
        '?' +
        new URLSearchParams({
          ...queries,
          id: id,
        }).toString(),
    });

  const privateViewing = (m: Match) => {
    if (m.privateViewingStatus === PRIVATE_VIEWING_STATUS.NEW_MATCHES)
      return '-';
    if (!m.buyerRespondedAt && m.sellerRespondedAt) return 'Requested';
    if (!m.sellerRespondedAt && m.buyerRespondedAt) return 'Invited';
    if (m.buyerRespondedAt && m.sellerRespondedAt) {
      return isAfter(
        new Date(m.buyerRespondedAt),
        new Date(m.sellerRespondedAt)
      )
        ? 'Invited'
        : 'Requested';
    }
    if (!m.buyerRequestedAt && m.buyerInvitedAt) return 'Invited';
    if (!m.buyerInvitedAt && m.buyerRequestedAt) return 'Requested';
    if (m.buyerInvitedAt && m.buyerRequestedAt) {
      return isAfter(new Date(m.buyerRequestedAt), new Date(m.buyerInvitedAt))
        ? 'Requested'
        : 'Invited';
    }
    return '-';
  };

  const RegularMatchRow = (m: Match, i: number) => (
    <Flex flexDir="column" key={m.id}>
      <Flex
        cursor="pointer"
        position="relative"
        backgroundColor={i % 2 !== 0 ? theme.colors.shade18 : 'transparent'}
        onClick={() => setOpenedMatch((id) => (id === m.id ? undefined : m.id))}
      >
        <Flex flex={1} p={2} cursor="pointer" position="relative">
          <Typography
            variant="type8"
            color="primary"
            onClick={() => {
              setListing(m.sellingProfile._id);
            }}
          >
            {getFullPropertyTitle(
              m.sellingProfile.title,
              m.sellingProfile.location
            )}
          </Typography>
        </Flex>
        <Flex flex={1} p={2}>
          <Typography variant="type8" color="shade5">
            {m.seller ? `${m.seller.firstName} ${m.seller.lastName}` : ''}
          </Typography>
        </Flex>
        <Flex flex={1} p={2}>
          <Typography variant="type8" color="shade5">
            {m.matchPercentage}%
          </Typography>
        </Flex>
        <Flex flex={1} p={2}>
          <Typography variant="type8" color="shade5">
            {format(new Date(m.createdAt), 'MM/dd/y kk:mm:ss a')}
          </Typography>
        </Flex>
        <Flex flex={1} p={2}>
          <Typography variant="type8" color="shade5">
            {privateViewing(m)}
          </Typography>
        </Flex>
        <Flex position="absolute" right={3} top={5}>
          {openedMatch === m.id ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </Flex>
      </Flex>
      <Collapse in={openedMatch === m.id} animateOpacity>
        <Flex backgroundColor={theme.colors.shade12}>
          {regularColumnGroup2.map((col) => (
            <Flex key={col} flex={1} p={2}>
              <Typography variant="type9" weight="600" color="shade5">
                {col}
              </Typography>
            </Flex>
          ))}
        </Flex>
        <Flex flexDir="column">
          <Flex
            key={m.id}
            backgroundColor={i % 2 !== 0 ? theme.colors.shade18 : 'transparent'}
          >
            <Flex flex={1} p={2} />
            <Flex flex={1} p={2} pl={0} ml={2} borderBottom="1px solid #6677A3">
              <Typography variant="type8" color="shade5">
                {m.privateViewingStatus !==
                  PRIVATE_VIEWING_STATUS.NEW_MATCHES &&
                (m.buyerRequestedAt || m.buyerInvitedAt)
                  ? format(
                      new Date(
                        // @ts-ignore
                        m.buyerRequestedAt || m.buyerInvitedAt
                      ),
                      'MM/dd/y kk:mm:ss a'
                    )
                  : '-'}
              </Typography>
            </Flex>
            <Flex flex={1} p={2} borderBottom="1px solid #6677A3">
              <Typography variant="type8" color="shade5">
                {m.privateViewingStatus !==
                  PRIVATE_VIEWING_STATUS.NEW_MATCHES &&
                (m.sellerRespondedAt || m.buyerRespondedAt)
                  ? format(
                      new Date(
                        // @ts-ignore
                        m.sellerRespondedAt || m.buyerRespondedAt
                      ),
                      'MM/dd/y kk:mm:ss a'
                    )
                  : '-'}
              </Typography>
            </Flex>
            <Flex flex={1} p={2} borderBottom="1px solid #6677A3">
              <Typography variant="type8" color="shade5">
                {
                  responseTypeStatusLabel[
                    m.privateViewingStatus || m.preRegistrationStatus
                  ]
                }
              </Typography>
            </Flex>
            <Flex flex={1} p={2} borderBottom="1px solid #6677A3">
              <Typography variant="type8" color="shade5">
                {m.privateViewingMessage}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      </Collapse>
    </Flex>
  );

  return (
    <>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            PROPERTY MATCHES ({regular.length})
          </Typography>
        </Flex>
        <Flex flexDirection="column">
          <Flex backgroundColor={theme.colors.shade12}>
            {regularColumnGroup1.map((col) => (
              <Flex key={col} flex={1} p={2}>
                <Typography variant="type9" weight="600" color="shade5">
                  {col}
                </Typography>
              </Flex>
            ))}
          </Flex>
          <Flex flexDirection="column">{regular.map(RegularMatchRow)}</Flex>
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end" mt={5}>
        <HStack spacing={5}>
          <AppButton onClick={props.onBack} kind="secondary" variant="small">
            Go Back
          </AppButton>
        </HStack>
      </Flex>
    </>
  );
};

export default BuyListingMatchesView;
