import { AxiosPromise } from 'axios';

import api from 'api/instance';
import { Session } from 'types/Session';

const URL = `${process.env.REACT_APP_API_URL}/admin/auth`;

export type LoginPayload = {
  username: string;
  password: string;
};

export type LoginResponse = {
  data: Session;
};

const login = async (payload: LoginPayload): Promise<Session> => {
  const { data } = await api.post<LoginResponse>(`${URL}/login`, payload);
  return data.data;
};

const logout = (): AxiosPromise => api.post(`${URL}/logout`);

export default {
  login,
  logout,
};
