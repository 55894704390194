import React from 'react';

import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import authEffects from 'effector/session/effects';
import { ApiResponseError } from 'types/Api';

import LoginView from './Login.view';

const LoginContainer = (): JSX.Element => {
  const toast = useToast();
  const { mutate: login, isLoading } = useMutation(authEffects.login, {
    onError: (error: ApiResponseError) => {
      console.log('🚀 ~ file: Login.container.tsx ~ line 14 ~ error', error);

      toast({
        description: error.message,
        status: 'error',
      });
    },
    onSuccess: (data) => {
      console.log('🚀 ~ file: Login.container.tsx ~ line 21 ~ data', data);
    },
  });
  console.log(
    '🚀 ~ file: Login.container.tsx ~ line 13 ~ isLoading',
    isLoading
  );
  return <LoginView login={login} isLoading={isLoading} />;
};

export default LoginContainer;
