import React from 'react';

import Typography from 'components/primitives/Typography';

import { LabelPublicProps } from './Label.props';
import { Container } from './Label.style';

const LabelView = ({
  children,
  LeftComponent,
  typographyProps,
  ...props
}: LabelPublicProps): JSX.Element => {
  return (
    <Container {...props}>
      {LeftComponent || null}
      <Typography weight="600" variant="type9" {...typographyProps}>
        {children}
      </Typography>
    </Container>
  );
};

export default LabelView;
