import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const User = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color } = props;

  return (
    <svg width={width || '20'} height={height || '20'} viewBox={`0 0 20 20`}>
      <g id="Users-Icon-SideMenu" transform="translate(-29 -128)">
        <rect
          id="IconBounds"
          width="20"
          height="20"
          transform="translate(29 128)"
          fill="none"
        />
        <g
          id="Users-Icon-SideMenu-2"
          data-name="Users-Icon-SideMenu"
          transform="translate(30.818 128)"
        >
          <path
            id="Path_2122"
            data-name="Path 2122"
            d="M91.577,9.091a4.4,4.4,0,0,0,3.214-1.332,4.4,4.4,0,0,0,1.332-3.214A4.4,4.4,0,0,0,94.79,1.331a4.544,4.544,0,0,0-6.428,0,4.4,4.4,0,0,0-1.332,3.214,4.4,4.4,0,0,0,1.332,3.214A4.4,4.4,0,0,0,91.577,9.091Zm0,0"
            transform="translate(-83.632)"
            fill={theme.colors[color || 'primary']}
          />
          <path
            id="Path_2123"
            data-name="Path 2123"
            d="M16.324,253.035a12.121,12.121,0,0,0-.159-1.264,10.12,10.12,0,0,0-.3-1.271,6.339,6.339,0,0,0-.512-1.185,4.463,4.463,0,0,0-.772-1.026,3.4,3.4,0,0,0-1.11-.711,3.769,3.769,0,0,0-1.416-.261,1.422,1.422,0,0,0-.768.332c-.23.153-.5.33-.8.526a4.539,4.539,0,0,1-1.035.465,3.945,3.945,0,0,1-2.532,0,4.529,4.529,0,0,1-1.034-.465c-.3-.194-.567-.371-.8-.526a1.42,1.42,0,0,0-.767-.332,3.764,3.764,0,0,0-1.416.262,3.394,3.394,0,0,0-1.11.711,4.463,4.463,0,0,0-.772,1.026A6.351,6.351,0,0,0,.5,250.5a10.144,10.144,0,0,0-.3,1.27,12.079,12.079,0,0,0-.159,1.264c-.026.383-.039.78-.039,1.181a3.356,3.356,0,0,0,.967,2.512,3.443,3.443,0,0,0,2.493.927H12.9a3.443,3.443,0,0,0,2.492-.927,3.354,3.354,0,0,0,.967-2.513C16.363,253.813,16.35,253.415,16.324,253.035Zm0,0"
            transform="translate(0 -237.656)"
            fill={theme.colors[color || 'primary']}
          />
        </g>
      </g>
    </svg>
  );
};

export default User;
