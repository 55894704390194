import { SellListingSummary } from 'types/Property';

export type DraftSellProps = {
  data: SellListingSummary;
  refetch: () => void;
};

export type DraftSellGeneratedProps = {
  data: SellListingSummary;
  onDelete: () => void;
  refetch: () => void;
};

export enum MODE {
  DEFAULT = 'default',
  EDIT = 'edit',
}
