import React from 'react';

import { Box, Flex, VStack } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { isEmpty } from 'ramda';
import { useMutation, useQueryClient } from 'react-query';

import propertiesApi, { ClaimDraftEmailRequest } from 'api/properties';
import AppButton from 'components/primitives/Button/Button.view';
import FormikSelect from 'components/primitives/FormikSelect/FormikSelect.view';
import FormikTextField from 'components/primitives/FormikTextField/FormikTextField.view';
import Typography from 'components/primitives/Typography';
import { FETCH_DRAFT_SUMMARY } from 'constants/queries';
import { ApiResponseError } from 'types/Api';
import ucFirst from 'utils/string/ucFirst';

import { DraftSendEmailProps, FormProps } from './DraftSendEmail.props';
import { Schema } from './DraftSendEmail.utils';

const DraftSendEmailView = (props: DraftSendEmailProps): JSX.Element => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate: sendEmail, isLoading } = useMutation(
    (payload: ClaimDraftEmailRequest) =>
      propertiesApi.doSendClaimDraftEmail(props.id, payload),
    {
      onSuccess: () => {
        queryClient.refetchQueries(`${FETCH_DRAFT_SUMMARY}${props.id}`);
        toast({
          description: 'Email sent successfully.',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  return (
    <Flex flexDir="column" w="100%">
      <Box overflow="auto" maxH={64}>
        {props.data?.map((d, i) => (
          <VStack
            key={i}
            w="100%"
            spacing={5}
            pb={5}
            backgroundColor={i % 2 !== 0 ? 'shade18' : 'transparent'}
          >
            <Flex w="100%">
              <VStack flex={1} alignItems="flex-start">
                <Typography variant="type10" color="shade17">
                  USER TYPE
                </Typography>
                <Typography variant="type8" color="shade5">
                  {ucFirst(d.userType)}
                </Typography>
              </VStack>

              <VStack flex={2} alignItems="flex-start">
                <Typography variant="type10" color="shade17">
                  EMAIL
                </Typography>
                <Typography variant="type8" color="shade5">
                  {d.email}
                </Typography>
              </VStack>
            </Flex>

            <VStack w="100%" alignItems="flex-start">
              <Typography variant="type10" color="shade17">
                VOUCHER CODE
              </Typography>
              <Typography variant="type8" color="shade5">
                {d.voucherCode || '-'}
              </Typography>
            </VStack>

            <VStack w="100%" alignItems="flex-start">
              <Typography variant="type10" color="shade17">
                SENT ON
              </Typography>
              <Typography variant="type8" color="shade5">
                {format(new Date(d.sentAt), 'dd/MM/y HH:mm:ss a')}
              </Typography>
            </VStack>
          </VStack>
        ))}
      </Box>

      {isEmpty(props.data) && props.hideForm && (
        <Typography variant="type8" color="shade5">
          No emails sent
        </Typography>
      )}

      {!isEmpty(props.data) && !props.hideForm && (
        <Flex borderBottom="1px solid #ddd" width="100%" pt={5} mb={5} />
      )}

      {!props.hideForm && (
        <Formik<FormProps>
          initialValues={{
            userType: 'seller',
            email: '',
          }}
          validationSchema={Schema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(fields) => {
            const payload = { ...fields };
            if (!payload.voucherCode) delete payload['voucherCode'];

            sendEmail(payload);
          }}
        >
          <Form style={{ width: '100%' }}>
            <FormikSelect
              fieldName="userType"
              options={[
                { label: 'Seller', value: 'seller' },
                { label: 'Agent', value: 'agent' },
              ]}
              label="User Type"
            />
            <FormikTextField fieldName="email" label="Email" />
            <FormikTextField
              fieldName="voucherCode"
              label="Enter Voucher Code (optional)"
            />

            <AppButton
              fullwidth
              type="submit"
              loading={isLoading}
              variant="small"
            >
              Send Email
            </AppButton>
          </Form>
        </Formik>
      )}
    </Flex>
  );
};

export default DraftSendEmailView;
