import React from 'react';

import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import propertiestApi, { BuyDetailsRequest } from 'api/properties';
import { ApiResponseError } from 'types/Api';

import { BuyListingEditProps } from './BuyListingEdit.props';
import BuyListingEditView from './BuyListingEdit.view';

const BuyListingEditContainer = (props: BuyListingEditProps): JSX.Element => {
  const toast = useToast();
  const { mutate: update, isLoading } = useMutation(
    async (values: BuyDetailsRequest) =>
      propertiestApi.doUpdateBuyProperty(props.data.id, values),
    {
      onSuccess: () => {
        toast({
          description: 'Updated successfully',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
        props.onEndEdit();
      },
      onError: (error: ApiResponseError) => {
        toast({
          status: 'error',
          description: error.message,
          position: 'top-right',
        });
      },
    }
  );
  return (
    <BuyListingEditView {...props} isLoading={isLoading} onSubmit={update} />
  );
};

export default BuyListingEditContainer;
