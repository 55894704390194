import React, { useCallback } from 'react';

import { CheckboxPublicProps } from './Checkbox.props';
import {
  Container,
  HiddenCheckbox,
  Checkbox,
  InnerCheck,
  Label,
} from './Checkbox.style';

const CheckboxView = ({
  checked = false,
  value,
  onChange,
  disabled,
  label,
  size = 20,
  scale = 1,
  typographyProps,
  ...props
}: CheckboxPublicProps): JSX.Element => {
  const onClick = useCallback(() => {
    if (!disabled) onChange(value);
  }, [disabled, value, onChange]);

  return (
    <Container disabled={disabled} onClick={onClick}>
      <HiddenCheckbox
        onClick={(event) => event.stopPropagation()}
        checked={checked}
        disabled={disabled}
        type="checkbox"
        {...props}
      />
      <Checkbox checked={checked} disabled={disabled} size={size}>
        {checked && <InnerCheck scale={scale} />}
      </Checkbox>
      <Label
        {...typographyProps}
        variant="type8"
        color={
          disabled
            ? 'shade10'
            : typographyProps
            ? typographyProps.color
            : 'shade1'
        }
      >
        {label}
      </Label>
    </Container>
  );
};

export default CheckboxView;
