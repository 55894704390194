import React, { useRef } from 'react';

import { Box, Flex, HStack, useToast } from '@chakra-ui/react';
import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  formatISO,
  set,
} from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import { useMutation } from 'react-query';

import promos from 'api/promos';
import { DateButton } from 'components/modules/VoucherAddNew/VoucherAddNew.style';
import AppButton from 'components/primitives/Button';
import FormikDatePicker from 'components/primitives/FormikDatePicker/FormikDatePicker.view';
import FormikRadio from 'components/primitives/FormikRadio';
import FormikTextField from 'components/primitives/FormikTextField';
import FormikTimePicker from 'components/primitives/FormikTimePicker/FormikTimePicker.view';
import Typography from 'components/primitives/Typography';
import { ApiResponseError } from 'types/Api';

import AppModal from '../AppModal';
import { AddForm, VoucherAddNewProps } from './VoucherAddNew.props';
import { Schema } from './VoucherAddNew.utils';

const VoucherAddNewView = (props: VoucherAddNewProps): JSX.Element => {
  const toast = useToast();
  const formRef = useRef<FormikProps<any>>(null);

  const { isLoading, mutate } = useMutation(
    (fields: AddForm) => promos.doCreate(fields as any),
    {
      onSuccess: () => {
        toast({
          description: 'Voucher created successfully.',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
        props.onClose();
        props.refetch();
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const onClickDate = (option: number) => {
    const form = formRef.current;
    if (!form) return;

    if (option === 1)
      form.setFieldValue(
        'expiredAt',
        formatISO(addHours(addDays(new Date(), 1), 1))
      );
    if (option === 2)
      form.setFieldValue('expiredAt', formatISO(addDays(new Date(), 2)));
    if (option === 3)
      form.setFieldValue('expiredAt', formatISO(addDays(new Date(), 3)));
    if (option === 4)
      form.setFieldValue('expiredAt', formatISO(addWeeks(new Date(), 1)));
    if (option === 5)
      form.setFieldValue('expiredAt', formatISO(addMonths(new Date(), 1)));
  };

  return (
    <AppModal {...props} size="xl" title="ADD NEW VOUCHER">
      <Formik<AddForm>
        innerRef={formRef}
        initialValues={{
          code: '',
          maxPerUser: 1,
          maxUseCount: 1,
          status: 'active',
        }}
        validateOnChange={false}
        validationSchema={Schema}
        onSubmit={(fields) => mutate(fields)}
      >
        <Form>
          <Flex flexDirection="column" p={2}>
            <Flex mt={2} maxW="350px" flexDir="column">
              <FormikTextField fieldName="code" label="VOUCHER CODE" />
              <FormikTextField
                type="number"
                fieldName="maxUseCount"
                label="MAX USE PER CODE"
              />
              <FormikTextField
                type="number"
                fieldName="maxPerUser"
                label="MAX USE PER USER"
              />
              <Flex gridGap="10">
                <FormikDatePicker
                  flatpickrProps={{
                    options: {
                      minDate: set(addDays(new Date(), 1), {
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                        milliseconds: 0,
                      }),
                    },
                  }}
                  fieldName="expiredAt"
                  textFieldProps={{
                    label: 'EXPIRY DATE in NZ',
                  }}
                />
                <FormikTimePicker
                  fieldName="expiredAt"
                  textFieldProps={{
                    label: 'EXPIRY TIME in NZ',
                  }}
                />
              </Flex>
              <Flex gridGap="5" mb={4}>
                <DateButton onClick={() => onClickDate(1)}>1day</DateButton>
                <DateButton onClick={() => onClickDate(2)}>2days</DateButton>
                <DateButton onClick={() => onClickDate(3)}>3days</DateButton>
                <DateButton onClick={() => onClickDate(4)}>1week</DateButton>
                <DateButton onClick={() => onClickDate(5)}>1month</DateButton>
              </Flex>

              <Box mb={2}>
                <Typography variant="type9" color="shade5">
                  STATUS
                </Typography>
              </Box>
              <Flex gridGap="10">
                <FormikRadio
                  checkedValue="active"
                  fieldName="status"
                  label="Active"
                />
                <FormikRadio
                  checkedValue="inactive"
                  fieldName="status"
                  label="Deactivated"
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end" mt={5}>
            <HStack spacing={5}>
              <AppButton
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                variant="small"
              >
                Save
              </AppButton>
              <AppButton
                onClick={props.onClose}
                kind="secondary"
                variant="small"
                disabled={isLoading}
              >
                Cancel
              </AppButton>
            </HStack>
          </Flex>
        </Form>
      </Formik>
    </AppModal>
  );
};

export default VoucherAddNewView;
