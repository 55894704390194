import styled from '@emotion/styled';

import {
  ButtonPublicProps,
  Kinds,
  Variants,
} from 'components/primitives/Button/Button.props';
import Typography from 'components/primitives/Typography';
import { Colors } from 'types/Theme';
import { pxToRem } from 'utils/theme';

export const StyledButton = styled.button<ButtonPublicProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  background-color: ${({ kind, outline, color, theme }) => {
    if (outline) return 'transparent';
    if (color) return theme.colors[color];

    return kind === 'primary' ? theme.colors.primary : theme.colors.secondary;
  }};
  border-radius: ${({ variant }) => (variant === 'tiny' ? '100px' : '4px')};
  box-shadow: ${({ outline }) => (outline ? 'none' : '0px 4px 6px #32325d1c')};
  border: ${({ kind, outline, theme, color, transparentBorder }) => {
    if (transparentBorder) return `1px solid ${theme.colors.transparent}`;
    if (outline) return `1px solid ${theme.colors[color || 'primary']}`;
    if (kind === 'primary') return `1px solid ${theme.colors.primary}`;
    if (kind === 'secondary') return `1px solid ${theme.colors.secondary}`;

    return 'none';
  }};
  padding: ${({ variant }) => {
    if (variant === 'small') return '8px 12px';
    if (variant === 'tiny') return '6px 14px';

    return '12px';
  }};
  width: ${({ fullwidth }) => (fullwidth ? '100%' : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  &:hover {
    opacity: 0.5;
  }
`;

export const Text = styled(Typography)<{
  kind: Kinds;
  buttonVariant: Variants;
  outline: boolean;
  color?: keyof Colors;
}>`
  color: ${({ kind, outline, color, theme }) => {
    if (color) return color;

    const primary = theme.colors.primary;
    const secondary = theme.colors.secondary;

    if (outline) return theme.colors[color || 'primary'];
    return kind === 'primary' ? secondary : primary;
  }};
  ${({ buttonVariant }) =>
    buttonVariant === 'tiny' ? `font-size: ${pxToRem(10)}` : ''};
`;
