import React from 'react';

import { Box } from '@chakra-ui/react';
import PhoneInput from 'react-phone-input-2';

import Typography from '../Typography';
import { PhoneTextFieldProps } from './PhoneTextField.props';
import { Container, ErrorContainer, Error } from './PhoneTextField.style';

import 'react-phone-input-2/lib/style.css';

const PhoneTextFieldView = ({
  error = '',
  errorProps,
  label,
  ...props
}: PhoneTextFieldProps): JSX.Element => {
  return (
    <Box>
      {label && (
        <Box mb={2}>
          <Typography variant="type9" color="shade5">
            {label}
          </Typography>
        </Box>
      )}
      <Container error={error} disabled={props.disabled}>
        <PhoneInput {...props} specialLabel="" inputClass="phone-input" />
        <ErrorContainer>
          <Error color="special2" {...errorProps}>
            {error}
          </Error>
        </ErrorContainer>
      </Container>
    </Box>
  );
};

export default PhoneTextFieldView;
