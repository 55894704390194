import React, { useEffect, useState, useMemo } from 'react';

import { Box, Flex, HStack, SimpleGrid } from '@chakra-ui/react';
import { parseISO, set } from 'date-fns';
import { filter, intersection, isEmpty, isNil, reject, uniq } from 'ramda';
import { useHistory, useLocation } from 'react-router-dom';

import AppModal from 'components/modules/AppModal';
import Button from 'components/primitives/Button';
import DatePicker from 'components/primitives/DatePicker';
import Select from 'components/primitives/Select';
import Typography from 'components/primitives/Typography';
import locations from 'constants/locations';
import {
  PRICE_BY_NEGOTIATION,
  PropertyTypes,
  SET_DATE_OF_SALE,
} from 'constants/property';
import { DraftsFilters, PropertyDraftItem } from 'types/Property';

import { DraftsFiltersProps } from './DraftFilters.props';

const regions = locations.map((loc) => ({
  label: loc.region,
  value: loc.region,
}));

const DraftsFiltersView = (props: DraftsFiltersProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState<
    Partial<Record<keyof DraftsFilters, string>>
  >({});

  const [claimedAtFrom, setClaimedAtFrom] = useState<string>();
  const [claimedAtTo, setClaimedAtTo] = useState<string>();

  const onPropertyTypeSelect = (pt: PropertyTypes) => {
    if (filters.propertyType?.match(pt)) {
      setFilters({
        ...filters,
        propertyType: filter(
          (type) => type !== pt,
          [...(filters.propertyType || '').split(','), pt]
        ).join(','),
      });
    } else {
      setFilters({
        ...filters,
        propertyType: uniq([
          ...(filters.propertyType || '').split(','),
          pt,
        ]).join(','),
      });
    }
  };

  const onStatusSelect = (status: PropertyDraftItem['status']) => {
    if (filters.status?.match(status)) {
      setFilters({
        ...filters,
        status: filter(
          (stat) => stat !== status,
          [...(filters.status || '').split(','), status]
        ).join(','),
      });
    } else {
      setFilters({
        ...filters,
        status: uniq([...(filters.status || '').split(','), status]).join(','),
      });
    }
  };

  const onApply = () => {
    props.onClose();
    history.push({
      pathname: location.pathname,
      search:
        '?' +
        new URLSearchParams(
          reject(isNil)(
            reject(isEmpty)({
              ...filters,
              claimedAtFrom,
              claimedAtTo,
            })
          ) as Record<string, string>
        ).toString(),
    });
  };

  const isSpecialPrice = useMemo(
    () =>
      intersection(
        [filters.priceFrom, filters.priceTo],
        [PRICE_BY_NEGOTIATION, SET_DATE_OF_SALE]
      ).length > 0,
    [filters]
  );

  useEffect(() => {
    if (!props.isOpen) {
      setFilters({});
      setClaimedAtFrom(undefined);
      setClaimedAtTo(undefined);
    } else {
      const searches = Object.fromEntries(new URLSearchParams(location.search));
      setFilters(searches);
      setClaimedAtFrom(searches.claimedAtFrom || undefined);
      setClaimedAtTo(searches.claimedAtTo || undefined);
    }
  }, [props.isOpen]);

  return (
    <AppModal
      {...props}
      size="xl"
      title="Filter"
      FooterComponent={
        <HStack spacing={5}>
          <Button
            kind="secondary"
            onClick={() => {
              setFilters({});
              setClaimedAtFrom(undefined);
              setClaimedAtTo(undefined);
            }}
          >
            Reset
          </Button>
          <Button onClick={onApply}>Apply</Button>
        </HStack>
      }
    >
      <Typography variant="type8" weight="700" color="shade5">
        Property Type
      </Typography>
      <Flex my={2} flexWrap="wrap">
        <Flex m={1}>
          <Button
            onClick={() => onPropertyTypeSelect(PropertyTypes.HOUSE)}
            outline={!filters.propertyType?.match(PropertyTypes.HOUSE)}
            variant="tiny"
          >
            House
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() => onPropertyTypeSelect(PropertyTypes.SECTION)}
            outline={!filters.propertyType?.match(PropertyTypes.SECTION)}
            variant="tiny"
          >
            Section
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() => onPropertyTypeSelect(PropertyTypes.UNIT)}
            outline={!filters.propertyType?.match(PropertyTypes.UNIT)}
            variant="tiny"
          >
            Unit
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() => onPropertyTypeSelect(PropertyTypes.APARTMENT)}
            outline={!filters.propertyType?.match(PropertyTypes.APARTMENT)}
            variant="tiny"
          >
            Apartment
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() =>
              onPropertyTypeSelect(PropertyTypes.LIFESTYLE_BARELAND)
            }
            outline={
              !filters.propertyType?.match(PropertyTypes.LIFESTYLE_BARELAND)
            }
            variant="tiny"
          >
            Lifestyle Bareland
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() =>
              onPropertyTypeSelect(PropertyTypes.LIFESTYLE_DWELLING)
            }
            outline={
              !filters.propertyType?.match(PropertyTypes.LIFESTYLE_DWELLING)
            }
            variant="tiny"
          >
            Lifestyle Dwelling
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() =>
              onPropertyTypeSelect(PropertyTypes.HORTICULTURAL_PROPERTY)
            }
            outline={
              !filters.propertyType?.match(PropertyTypes.HORTICULTURAL_PROPERTY)
            }
            variant="tiny"
          >
            Horticultural
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() =>
              onPropertyTypeSelect(PropertyTypes.AGRICULTURAL_PROPERTY)
            }
            outline={
              !filters.propertyType?.match(PropertyTypes.AGRICULTURAL_PROPERTY)
            }
            variant="tiny"
          >
            Agricultural
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            onClick={() =>
              onPropertyTypeSelect(PropertyTypes.COMMERCIAL_PROPERTY)
            }
            outline={
              !filters.propertyType?.match(PropertyTypes.COMMERCIAL_PROPERTY)
            }
            variant="tiny"
          >
            Commercial
          </Button>
        </Flex>
      </Flex>
      <Typography variant="type8" weight="700" color="shade5">
        Draft Status
      </Typography>
      <Flex my={2} flexWrap="wrap">
        <Flex m={1}>
          <Button
            outline={!filters.status?.match('CREATED')}
            variant="tiny"
            onClick={() => onStatusSelect('CREATED')}
          >
            Claimed
          </Button>
        </Flex>
        <Flex m={1}>
          <Button
            outline={!filters.status?.match('FOR_CLAIM')}
            variant="tiny"
            onClick={() => onStatusSelect('FOR_CLAIM')}
          >
            Unclaimed
          </Button>
        </Flex>
      </Flex>
      <SimpleGrid columns={2} spacingX={5} spacingY={2}>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Location
          </Typography>
          <Flex flexDir="column" my={1}>
            <Select
              options={regions}
              onChange={(val) =>
                setFilters({ ...filters, locationRegion: val.target.value })
              }
              value={filters.locationRegion || ''}
              placeholder="Select Region"
            />
          </Flex>
        </Box>
        <Box />
        {/* <Box>
          <Typography variant="type8" weight="700" color="shade5">
          Created date from
          </Typography>
          <Flex flexDir="column" my={1}>
            <DatePicker
              value={createdAtFrom ? parseISO(createdAtFrom) : undefined}
              textFieldProps={{
                placeholder: 'MM/DD/YYYY',
              }}
              onChange={([date]) =>
                setCreatedAtFrom(
                  set(date, {
                    seconds: 0,
                    milliseconds: 0,
                  }).toISOString()
                )
              }
            />
          </Flex>
        </Box>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Created date to
          </Typography>
          <Flex flexDir="column" my={1}>
            <DatePicker
              value={createdAtTo ? parseISO(createdAtTo) : undefined}
              textFieldProps={{
                placeholder: 'MM/DD/YYYY',
              }}
              onChange={([date]) =>
                setCreatedAtTo(
                  set(date, {
                    seconds: 0,
                    milliseconds: 0,
                  }).toISOString()
                )
              }
            />
          </Flex>
        </Box> */}
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Date claimed from
          </Typography>
          <Flex flexDir="column" my={1}>
            <DatePicker
              value={claimedAtFrom ? parseISO(claimedAtFrom) : undefined}
              textFieldProps={{
                placeholder: 'MM/DD/YYYY',
              }}
              onChange={([date]) =>
                setClaimedAtFrom(
                  set(date, {
                    seconds: 0,
                    milliseconds: 0,
                  }).toISOString()
                )
              }
            />
          </Flex>
        </Box>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Date claimed to
          </Typography>
          <Flex flexDir="column" my={1}>
            <DatePicker
              value={claimedAtTo ? parseISO(claimedAtTo) : undefined}
              textFieldProps={{
                placeholder: 'MM/DD/YYYY',
              }}
              onChange={([date]) =>
                setClaimedAtTo(
                  set(date, {
                    seconds: 0,
                    milliseconds: 0,
                  }).toISOString()
                )
              }
            />
          </Flex>
        </Box>
      </SimpleGrid>
    </AppModal>
  );
};

export default DraftsFiltersView;
