import api from 'api/instance';
import { AgentApplicationsRow, AgentDetails } from 'types/Agent';
import { Note } from 'types/User';

const URL = `${process.env.REACT_APP_API_URL}/admin/agents`;

export type GetAllAgentsResponse = {
  data: AgentApplicationsRow[];
  meta: {
    count: number;
    page: number;
    limit: number;
  };
};

const getAll = async (payload?: string): Promise<GetAllAgentsResponse> => {
  const { data } = await api.get<GetAllAgentsResponse>(`${URL}${payload}`);
  return data;
};

const getDetails = async (id?: string): Promise<AgentDetails> => {
  const { data } = await api.get<{ data: any }>(`${URL}/${id}`);
  return data.data;
};

type RespondFields = {
  status: 'APPROVED' | 'REJECTED';
  updatedBy: string;
};

const doRespond = async (id: string, fields: RespondFields): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}/respond`, fields);
  return data;
};

const doCreateNote = async (id: string, content: string): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}/notes`, {
    content,
  });
  return data;
};

const getNotes = async (id: string): Promise<Note[]> => {
  const { data } = await api.get<{ data: Note[] }>(`${URL}/${id}/notes`);
  return data.data;
};

const doSuspend = async (id: string): Promise<void> => {
  const { data } = await api.delete(`${URL}/${id}`);
  return data;
};

type UpdateFields = {
  firstName?: string;
  lastName?: string;
  phoneCode?: string;
  phone?: string;
  profilePic?: string;
  region?: string;
  tradingAs?: string;
  specialities?: string[];
  about?: string;
  company?: string;
  licenseNo?: string;
};

const doUpdate = async (id: string, fields: UpdateFields): Promise<void> => {
  const { data } = await api.post(`${URL}/${id}`, fields);
  return data;
};

export default {
  getAll,
  getDetails,
  doCreateNote,
  getNotes,
  doSuspend,
  doRespond,
  doUpdate,
};
