import React from 'react';

import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import propertiesApi from 'api/properties';
import { ApiResponseError } from 'types/Api';

import { DraftSellProps } from './DraftSell.props';
import DraftSellView from './DraftSell.view';

const DraftSellContainer = (props: DraftSellProps): JSX.Element => {
  const toast = useToast();

  const onSuccess = (description: string) => {
    props.refetch();
    toast({
      description,
      title: 'Success',
      position: 'top-right',
      status: 'success',
    });
  };

  const onError = (e: ApiResponseError) => {
    toast({
      description: e.message,
      title: 'Error',
      position: 'top-right',
      status: 'error',
    });
  };

  const { mutate: deleteDraft } = useMutation(propertiesApi.doDeleteDraft, {
    onSuccess: () => onSuccess('Property has been DELETED successfully.'),
    onError,
  });

  const onDelete = () => {
    deleteDraft(props.data.id);
  };

  return (
    <DraftSellView
      data={props.data}
      onDelete={onDelete}
      refetch={props.refetch}
    />
  );
};

export default DraftSellContainer;
