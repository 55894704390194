import React, { useMemo, useState } from 'react';

import { ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Collapse, Flex, HStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

import properties from 'api/properties';
import AppButton from 'components/primitives/Button';
import Typography from 'components/primitives/Typography';
import { OPEN_HOME_STATUS } from 'constants/property';
import { FETCH_LISTING_OPEN_HOME_DETAILS } from 'constants/queries';
import { OpenHomeMatch } from 'types/Property';
import { useTheme } from 'utils/theme';

import { SellListingOpenHomesProps } from './SellListingOpenHomes.props';

const columnGroup1 = [
  'Schedule',
  'Invited',
  'Accepted',
  'Declined',
  'Undecided',
  'Attendees',
  'Created On',
];

const columnGroup2 = [' ', 'Buyer', 'Match %', 'Invited On'];
const columnGroup3 = [' ', 'Buyer', 'Match %', 'Invited On', 'Responded On'];
const columnGroup4 = [' ', 'Buyer', 'Attendee Type', 'Registration Date'];

const INIT = { id: '', category: '' };

const Matches = ({
  matches,
  category,
}: {
  matches: OpenHomeMatch[];
  category: any;
}) => {
  const theme = useTheme();

  return (
    <>
      {matches.map((m) => (
        <Flex key={m.id} backgroundColor={theme.colors.shade18}>
          <Flex flex={2} />
          <Flex flex={2} p={2}>
            <Typography variant="type8" color="shade5">
              {m.buyer.firstName} {m.buyer.lastName}
            </Typography>
          </Flex>
          <Flex flex={1} p={2} alignItems="center">
            <Typography variant="type8" color="shade5">
              {m.matchPercentage}%
            </Typography>
          </Flex>
          <Flex flex={2} p={2}>
            <Typography variant="type8" color="shade5">
              {format(new Date(m.invitedAt), 'MM/dd/y kk:mm:ss a')}
            </Typography>
          </Flex>
          {(category === OPEN_HOME_STATUS.ACCEPTED ||
            category === OPEN_HOME_STATUS.DECLINED) && (
            <Flex flex={2} p={2}>
              <Typography variant="type8" color="shade5">
                {m.respondedAt
                  ? format(new Date(m.respondedAt), 'MM/dd/y kk:mm:ss a')
                  : '-'}
              </Typography>
            </Flex>
          )}
        </Flex>
      ))}
    </>
  );
};

const SellListingOpenHomesView = (
  props: SellListingOpenHomesProps
): JSX.Element => {
  const theme = useTheme();
  const [current, setCurrent] = useState(INIT);

  const { data = [] } = useQuery(
    `${FETCH_LISTING_OPEN_HOME_DETAILS}${props.id}`,
    () => properties.getOpenHomeDetails(props.id)
  );

  const onClickCategory = (id: string, category: string) => {
    setCurrent((ps) => {
      if (ps.id === id && ps.category === category) return INIT;

      return {
        id: id,
        category: category,
      };
    });
  };

  const getAttendeeType = (type: any) => {
    if (typeof type === 'number') return `${type}% Match`;
    else if (type === ' direct') return 'Direct Contact';

    return 'Walk-in';
  };

  const allMatches = useMemo(
    () => data.find((d) => d.id === current.id)?.matches || [],
    [data, current.id]
  );

  const filteredMatches = useMemo(
    () =>
      data
        .find((d) => d.id === current.id)
        ?.matches.filter((d) => d.openHomeStatus === current.category) || [],
    [data, current.id, current.category]
  );

  const attendees = useMemo(
    () => data.find((d) => d.id === current.id)?.attendees || [],
    [data, current.id]
  );

  const expandedColumnGroup =
    current.category === 'attendees'
      ? columnGroup4
      : current.category === 'all' ||
        current.category === OPEN_HOME_STATUS.INVITE
      ? columnGroup2
      : columnGroup3;

  return (
    <>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            OPEN HOME SCHEDULES ({data?.length || 0})
          </Typography>
        </Flex>
        <Flex flexDirection="column">
          <Flex backgroundColor={theme.colors.shade12}>
            {columnGroup1.map((col, i) => (
              <Flex key={col} flex={i === 0 ? 2 : 1} p={2}>
                <Typography variant="type9" weight="600" color="shade5">
                  {col}
                </Typography>
              </Flex>
            ))}
          </Flex>

          {data.map((d) => (
            <Flex key={d.id} backgroundColor={theme.colors.shade18}>
              <Flex flex={2} p={2}>
                <Typography variant="type8" color="shade5">
                  {format(new Date(d.startTime), 'MM/dd/y k:mma')} -{' '}
                  {format(new Date(d.endTime), 'k:mma')}
                </Typography>
              </Flex>
              <Flex flex={1} p={2}>
                <Typography variant="type8" color="shade5">
                  {d.count.invited}
                </Typography>
                {d.count.invited > 0 && (
                  <Flex
                    pt={0.5}
                    cursor="pointer"
                    onClick={() => onClickCategory(d.id, 'all')}
                  >
                    {current.id === d.id && current.category === 'all' ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                )}
              </Flex>
              <Flex flex={1} p={2}>
                <Typography variant="type8" color="shade5">
                  {d.count.accepted}
                </Typography>
                {d.count.accepted > 0 && (
                  <Flex
                    pt={0.5}
                    cursor="pointer"
                    onClick={() =>
                      onClickCategory(d.id, OPEN_HOME_STATUS.ACCEPTED)
                    }
                  >
                    {current.id === d.id &&
                    current.category === OPEN_HOME_STATUS.ACCEPTED ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                )}
              </Flex>
              <Flex flex={1} p={2}>
                <Typography variant="type8" color="shade5">
                  {d.count.declined}
                </Typography>
                {d.count.declined > 0 && (
                  <Flex
                    pt={0.5}
                    cursor="pointer"
                    onClick={() =>
                      onClickCategory(d.id, OPEN_HOME_STATUS.DECLINED)
                    }
                  >
                    {current.id === d.id &&
                    current.category === OPEN_HOME_STATUS.DECLINED ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                )}
              </Flex>
              <Flex flex={1} p={2}>
                <Typography variant="type8" color="shade5">
                  {d.count.undecided}
                </Typography>
                {d.count.undecided > 0 && (
                  <Flex
                    pt={0.5}
                    cursor="pointer"
                    onClick={() =>
                      onClickCategory(d.id, OPEN_HOME_STATUS.INVITE)
                    }
                  >
                    {current.id === d.id &&
                    current.category === OPEN_HOME_STATUS.INVITE ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                )}
              </Flex>
              <Flex flex={1} p={2}>
                <Typography variant="type8" color="shade5">
                  {d.count.attendees}
                </Typography>
                {d.count.attendees > 0 && (
                  <Flex
                    pt={0.5}
                    cursor="pointer"
                    onClick={() => onClickCategory(d.id, 'attendees')}
                  >
                    {current.id === d.id && current.category === 'attendees' ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </Flex>
                )}
              </Flex>
              <Flex flex={1} p={2}>
                <Typography variant="type8" color="shade5">
                  {d.createdAt
                    ? format(new Date(d.createdAt), 'MM/dd/y kk:mm:ss a')
                    : '-'}
                </Typography>
              </Flex>
            </Flex>
          ))}
        </Flex>

        <Collapse in={!!current.id} animateOpacity>
          <Flex flexDirection="column">
            <Flex backgroundColor={theme.colors.shade12}>
              {current.id &&
                expandedColumnGroup.map((col, i) => (
                  <Flex key={col} flex={i !== 2 ? 2 : 1} py={2} px={1}>
                    <Typography variant="type9" weight="600" color="shade5">
                      {col}
                    </Typography>
                  </Flex>
                ))}
            </Flex>

            {current.category && current.category === 'all' && (
              <Matches matches={allMatches} category={current.category} />
            )}

            {current.category &&
              current.category !== 'all' &&
              current.category !== 'attendees' && (
                <Matches
                  matches={filteredMatches}
                  category={current.category}
                />
              )}

            {current.category && current.category === 'attendees' && (
              <>
                {attendees.map((a) => (
                  <Flex key={a.id} backgroundColor={theme.colors.shade18}>
                    <Flex flex={2} />
                    <Flex flex={2} p={2}>
                      <Typography variant="type8" color="shade5">
                        {a.firstName} {a.lastName}
                      </Typography>
                    </Flex>
                    <Flex flex={1} p={2} alignItems="center">
                      <Typography variant="type8" color="shade5">
                        {getAttendeeType(a.type)}
                      </Typography>
                    </Flex>
                    <Flex flex={2} p={2}>
                      <Typography variant="type8" color="shade5">
                        {format(new Date(a.registeredAt), 'MM/dd/y kk:mm:ss a')}
                      </Typography>
                    </Flex>
                  </Flex>
                ))}
              </>
            )}
          </Flex>
        </Collapse>
      </Flex>
      <Flex justifyContent="flex-end" mt={5}>
        <HStack spacing={5}>
          <AppButton onClick={props.onBack} kind="secondary" variant="small">
            Go Back
          </AppButton>
        </HStack>
      </Flex>
    </>
  );
};

export default SellListingOpenHomesView;
