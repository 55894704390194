import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const Logo = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color, accent } = props;

  return (
    <svg
      width={width || '14.525'}
      height={height || '13.33'}
      viewBox="0 0 14.525 13.33"
    >
      <g
        id="Yelsa_Logo_Icon"
        data-name="Yelsa Logo Icon"
        transform="translate(0.703 0.453)"
      >
        <g
          id="Yelsa_Logo_Icon-2"
          data-name="Yelsa Logo Icon"
          transform="translate(-0.703 -0.453)"
        >
          <path
            id="Path_1217"
            data-name="Path 1217"
            d="M25.262-.335l-1.215.05.56-.67Z"
            transform="translate(-10.879 4.721)"
            fill={accent ? theme.colors[accent] : '#45faa7'}
          />
          <path
            id="Path_1209"
            data-name="Path 1209"
            d="M3.312,1.527,5.076,0H9.442L5.292,1.359h.834V4.143L5.649,5.609,4.1,6.946,2.754,10.115Z"
            transform="translate(3.869 1.597)"
            fill={accent ? theme.colors[accent] : '#45faa7'}
          />
          <path
            id="Path_75"
            data-name="Path 75"
            d="M98.283-3.895a4,4,0,0,0,.444-1.22,3.019,3.019,0,0,0-.548-2.445.182.182,0,0,0-.145-.071.182.182,0,0,0-.145.071,2.932,2.932,0,0,0-.555,1.231H92.69a2.042,2.042,0,0,0-2.031,1.779H84.35a.05.05,0,0,0-.049.06c.383,1.712,2.27,3.08,6.341,3.523V5.648a.05.05,0,0,0,.1.017l1.694-4.438a.174.174,0,0,1,.055-.075l.562-.445a4.411,4.411,0,0,0,1.687-3.474V-4.972c1.842-.214,3-.8,3.343-1.275a.052.052,0,0,0-.043-.083H97.7a2.549,2.549,0,0,1,.33-.806A2.653,2.653,0,0,1,98.37-5.18a3.687,3.687,0,0,1-.337.988,3.656,3.656,0,0,1-.379-1.288q-.155.091-.344.179a3.968,3.968,0,0,0,.465,1.393,1.2,1.2,0,0,0-.534.986,2.533,2.533,0,0,1,1.583,0A1.213,1.213,0,0,0,98.283-3.895ZM94.237-2.766a3.921,3.921,0,0,1-1.5,3.088L92.084.84a.247.247,0,0,0-.078.106l-.83,2.192a.018.018,0,0,1-.034-.006V1.753A1.733,1.733,0,0,1,91.551.637h0a3.035,3.035,0,0,0,.714-1.951V-2.6a1.962,1.962,0,0,1,1.972-1.952h0Zm-.25-2.667a.248.248,0,0,1-.25-.247.248.248,0,0,1,.25-.247.248.248,0,0,1,.25.247A.248.248,0,0,1,93.987-5.433ZM96.855-5.9a6.161,6.161,0,0,1-2.118.468v-.494h2.1C96.855-5.927,96.867-5.912,96.855-5.9Zm.813,2.609a.752.752,0,0,1,.361-.352.857.857,0,0,1,.362.35A1.259,1.259,0,0,0,97.667-3.292Z"
            transform="translate(-84.3 7.632)"
            fill={theme.colors[color || 'primary']}
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
