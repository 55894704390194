import React from 'react';

import { Box, Flex, SimpleGrid } from '@chakra-ui/react';

import Button from 'components/primitives/Button';
import DatePicker from 'components/primitives/DatePicker';
import Select from 'components/primitives/Select';
import Slider from 'components/primitives/Slider';
import TextArea from 'components/primitives/Textarea';
import TextField from 'components/primitives/TextField';
import Typography from 'components/primitives/Typography';

import AppModal from '../AppModal';
import { SendGroupEmailGeneratedProps } from './SendGroupEmail.props';

const SendGroupEmailView = (
  props: SendGroupEmailGeneratedProps
): JSX.Element => {
  return (
    <AppModal
      {...props}
      size="xl"
      title="Send a Group Email"
      FooterComponent={<Button>Send</Button>}
    >
      <Typography variant="type8" weight="700" color="shade5">
        Property Type
      </Typography>
      <Flex my={2} flexWrap="wrap">
        <Flex m={1}>
          <Button outline variant="tiny">
            Home
          </Button>
        </Flex>
        <Flex m={1}>
          <Button outline variant="tiny">
            Section
          </Button>
        </Flex>
        <Flex m={1}>
          <Button outline variant="tiny">
            Unit
          </Button>
        </Flex>
        <Flex m={1}>
          <Button outline variant="tiny">
            Apartment
          </Button>
        </Flex>
        <Flex m={1}>
          <Button outline variant="tiny">
            Lifestyle Bareland
          </Button>
        </Flex>
        <Flex m={1}>
          <Button outline variant="tiny">
            Lifestyle Dwelling
          </Button>
        </Flex>
        <Flex m={1}>
          <Button outline variant="tiny">
            Horticultural
          </Button>
        </Flex>

        <Flex m={1}>
          <Button outline variant="tiny">
            Agricultural
          </Button>
        </Flex>
      </Flex>
      <Typography variant="type8" weight="700" color="shade5">
        Listing Type
      </Typography>
      <Flex my={2} flexWrap="wrap">
        <Flex m={1}>
          <Button outline variant="tiny">
            All
          </Button>
        </Flex>
        <Flex m={1}>
          <Button outline variant="tiny">
            Buying
          </Button>
        </Flex>
        <Flex m={1}>
          <Button outline variant="tiny">
            Selling
          </Button>
        </Flex>
      </Flex>
      <SimpleGrid columns={2} spacing={5}>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Location
          </Typography>
          <Flex flexDir="column" my={1}>
            <Select
              options={[
                {
                  label: '1',
                  value: '1',
                },
                {
                  label: '2',
                  value: '2',
                },
              ]}
              onChange={console.log}
              value=""
              placeholder="Select Region"
            />
          </Flex>
        </Box>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Price
          </Typography>
          <Flex flexDir="column" my={1}>
            <Slider min={0} max={10} />
          </Flex>
        </Box>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Date from
          </Typography>
          <Flex flexDir="column" my={1}>
            <DatePicker value={new Date()} onChange={console.log} />
          </Flex>
        </Box>
        <Box>
          <Typography variant="type8" weight="700" color="shade5">
            Date to
          </Typography>
          <Flex flexDir="column" my={1}>
            <DatePicker value={new Date()} onChange={console.log} />
          </Flex>
        </Box>
      </SimpleGrid>
      <Flex py={5} justifyContent="flex-end">
        <Button kind="secondary">Reset</Button>
      </Flex>
      <Flex py={5} flexDirection="column" borderTop="0.3px solid #eee">
        <TextField placeholder="Email Subject" />
        <TextField placeholder="Email Header" />
        <TextArea placeholder="Email Body" />
      </Flex>
    </AppModal>
  );
};

export default SendGroupEmailView;
