import styled from '@emotion/styled';

import Typography from 'components/primitives/Typography';
import { pxToRem } from 'utils/theme';

export const Container = styled.div<{ error: string; disabled?: boolean }>`
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  .phone-input {
    font-size: ${pxToRem(14)};
    line-height: 19px;
    width: 100%;
    &::placeholder {
      color: ${({ theme }) => theme.colors.shade10};
    }
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.colors.special2 : theme.colors.shade9};
    border-radius: 4px;
    padding: 22.5px 22.5px 22.5px 48px;
  }
`;

export const ErrorContainer = styled.div`
  min-height: ${pxToRem(14)};
  margin-top: 4px;
  padding-left: 1px;
`;

export const Error = styled(Typography)`
  font-size: ${pxToRem(10)};
  line-height: 14px;
`;
