/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo } from 'react';

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  CircularProgress,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { isEmpty, props } from 'ramda';
import { useHistory } from 'react-router';
import { useTable, useSortBy } from 'react-table';

import VoucherAddNew from 'components/modules/VoucherAddNew';
import VoucherDetails from 'components/modules/VoucherDetails';
import Button from 'components/primitives/Button';
import PaginationView from 'components/primitives/Pagination';
import { Listing } from 'components/primitives/SVG';
import Typography from 'components/primitives/Typography';
import { routesSettings } from 'constants/routes';
import ucFirst from 'utils/string/ucFirst';

import { VouchersGeneratedProps } from './Vouchers.props';

const widthPerAccessor = {
  undefined: undefined,
};

const VouchersView = ({
  data,
  meta,
  isLoading,
  tableOptions,
  setTableOptions,
  setVoucher,
  refetch,
}: VouchersGeneratedProps): JSX.Element => {
  const history = useHistory();
  const addNewModal = useDisclosure();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const id = useMemo(() => query.get('id'), [location.search]);

  const onViewPromo = (id: string) => {
    history.push(`${routesSettings.MAIN_VOUCHERS.path}?id=${id}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Voucher Code',
        accessor: 'code',
      },
      {
        Header: 'Max Use Per Code',
        accessor: 'maxUseCount',
      },
      {
        Header: 'Max Use Per User',
        accessor: 'maxPerUser',
      },
      {
        Header: 'No. of Times Redeemed',
        accessor: 'timesUsed',
      },
      {
        Header: 'Last Used',
        accessor: 'lastUsed',
        Cell: ({ row }: any) =>
          row.original.lastUsed
            ? format(new Date(row.original.lastUsed), 'MM/dd/y kk:mm:ss a')
            : '-',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => ucFirst(row.original.status),
      },
    ],
    []
  );

  const currentIndex = useMemo(
    () => (id ? data.findIndex((d) => d.id === id) : -1),
    [data, id]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      data,
      // @ts-ignore
      columns,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortBy: isLoading,
    },
    useSortBy
  );

  const onPaginate = (page: number, pageSize: number) => {
    setTableOptions((prevState) => {
      return {
        ...prevState,
        page: prevState.limit !== pageSize ? 1 : page,
        limit: pageSize,
      };
    });
  };

  const onPrev = () => {
    if (currentIndex > 0) setVoucher(data[currentIndex - 1]);
  };

  const onNext = () => {
    if (currentIndex < data.length - 1) {
      setVoucher(data[currentIndex + 1]);
    }
  };

  useEffect(() => {
    setTableOptions((prevState) => {
      if (!isEmpty(sortBy)) {
        const { id, desc } = sortBy[0];
        return {
          ...prevState,
          sort: id,
          direction: desc ? 'desc' : 'asc',
        };
      } else if (isEmpty(sortBy) && !!prevState.sort) {
        const psCopy = { ...prevState };
        delete psCopy.sort;
        delete psCopy.direction;

        return psCopy;
      }

      return prevState;
    });
  }, [sortBy]);

  return (
    <Flex flexDirection="column">
      <Flex flex={1} justifyContent="space-between">
        <Typography variant="type5" weight="700" color="primary">
          VOUCHERS
        </Typography>
        <Button variant="small" onClick={addNewModal.onOpen}>
          Add New Voucher Code
        </Button>
      </Flex>
      <Flex
        mt={5}
        flex={1}
        borderRadius={10}
        p={5}
        minHeight="500px"
        bg="#fff"
        filter="drop-shadow(0px 2px 10px rgba(149, 149, 149, 0.18))"
        flexDirection="column"
      >
        <Flex
          flexDir="row"
          width="100%"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Flex height="30px" alignItems="center">
            <Listing color="shade10" width={12} height={12} />
            <Flex mx={3}>
              <Typography variant="type8" weight="600" color="shade10">
                ALL VOUCHERS
              </Typography>
            </Flex>
            {isLoading ? (
              <CircularProgress isIndeterminate size="20px" color="primary" />
            ) : (
              <Typography variant="type10" color="shade5">
                {meta.count} voucher{meta.count > 1 && 's'}
              </Typography>
            )}
          </Flex>
        </Flex>
        <Table size="sm" mt={7} {...getTableProps()}>
          <Thead backgroundColor="shade12">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {
                      // @ts-ignore
                      ...column.getHeaderProps(column.getSortByToggleProps())
                    }
                  >
                    <Flex flexDirection="row">
                      <Typography variant="type9" weight="600" color="shade5">
                        {column.Header}
                      </Typography>
                      <Flex ml={2}>
                        {
                          // @ts-ignore
                          column.isSorted ? (
                            <Box>
                              {
                                // @ts-ignore
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              }
                            </Box>
                          ) : (
                            <Box width="12px" height="5px" />
                          )
                        }
                      </Flex>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  backgroundColor={i % 2 !== 0 ? 'shade18' : 'transparent'}
                  cursor="pointer"
                  onClick={() => onViewPromo(row.original.id)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      // @ts-ignore
                      width={widthPerAccessor[cell.column.id]}
                    >
                      <Typography variant="type8" color="shade5">
                        {cell.render('Cell')}
                      </Typography>
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <VoucherDetails
          id={id || undefined}
          onClose={() => {
            setVoucher(undefined);
          }}
          onNext={onNext}
          onPrev={onPrev}
          isOpen={Boolean(id)}
          disablePrev={currentIndex === 0}
          disableNext={currentIndex === data.length - 1}
        />
        <VoucherAddNew {...addNewModal} refetch={refetch} />
        <Flex mt={5} flexDir="row" width="100%" justifyContent="flex-end">
          <PaginationView
            disabled={isLoading}
            total={meta.count}
            current={meta.page}
            pageSize={tableOptions.limit}
            onChange={onPaginate}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default VouchersView;
