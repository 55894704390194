import styled from '@emotion/styled';

import Typography from 'components/primitives/Typography';

export const Container = styled.label<{ disabled?: boolean }>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.5)};
  }
`;

export const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
`;

export const InnerCheck = styled.span<{
  scale: number;
}>`
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%) rotate(45deg)
    ${({ scale }) => `scale(${scale}, ${scale})`};
`;

export const Checkbox = styled.span<{
  size: number;
  checked: boolean;
  disabled?: boolean;
}>`
  border-radius: 4px;
  position: relative;
  min-height: ${({ size }) => `${size}px`};
  min-width: ${({ size }) => `${size}px`};
  border: ${({ checked, disabled, theme }) => {
    if (!checked && disabled) return `1px solid ${theme.colors.shade8}`;
    else if (checked && disabled) return `1px solid ${theme.colors.shade2}`;
    else if (checked) return `1px solid transparent`;

    return `1px solid ${theme.colors.shade9}`;
  }};
  background-color: ${({ checked, disabled, theme }) => {
    if (!checked && disabled) return theme.colors.shade8;
    else if (checked && disabled) return theme.colors.shade2;
    else if (checked) return theme.colors.primary;

    return 'transparent';
  }};
`;

export const Label = styled(Typography)`
  margin-left: 8px;
`;
