import React from 'react';

import { Switch } from 'react-router-dom';

import RouteWithSubRoutes from 'components/core/RouteWithSubRoutes';
// IMPORT ROUTE
import ROUTES, { routesSettings } from 'constants/routes';
import Login from 'routes/auth/Login';

const routeConfig = Object.freeze({
  // AUTH ROUTE ENTRY
  [ROUTES.AUTH_LOGIN]: {
    component: Login,
    path: routesSettings.AUTH_LOGIN.path,
  },
});

const AuthRoutes = (): JSX.Element => {
  return (
    <>
      <Switch>
        {Object.entries(routeConfig).map(([key, val]) => (
          <RouteWithSubRoutes key={key} {...val} />
        ))}
      </Switch>
    </>
  );
};

export default AuthRoutes;
