import React from 'react';

import { Box, Flex, Image } from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import Button from 'components/primitives/Button';
import FormikTextField from 'components/primitives/FormikTextField';
import { Logo } from 'components/primitives/SVG';

import { LoginGeneratedProps } from './Login.props';
const LoginView = (props: LoginGeneratedProps): JSX.Element => (
  <Flex
    width="100vw"
    height="100vh"
    bgColor="#F2F5FA"
    alignItems="center"
    justifyContent="center"
  >
    <Flex
      p={35}
      flexDir="column"
      alignItems="center"
      width="100%"
      maxW="350px"
      bgColor="#fff"
    >
      <Box my={30}>
        <Logo width={72} height={72} />
      </Box>
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={props.login}
      >
        <Form style={{ width: '100%' }}>
          <Flex width="100%" flexDir="column">
            <FormikTextField fieldName="username" placeholder="Username" />
            <FormikTextField
              fieldName="password"
              placeholder="Password"
              type="password"
            />
          </Flex>
          <Button
            type="submit"
            loading={props.isLoading}
            fullwidth
            propagateEvent
          >
            Login
          </Button>
        </Form>
      </Formik>
    </Flex>
  </Flex>
);

export default LoginView;
