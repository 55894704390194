import { format } from 'date-fns';

import {
  BathroomOptions,
  BedroomOptions,
  FenceOptions,
  GarageOptions,
  GlazingOptions,
  HeatingOptions,
  LivingAreaOptions,
  MarketingMethodLabel,
  SellPropertyStatusOptions,
  ToiletOptions,
  OtherFeaturesOptions,
  SET_DATE_OF_SALE,
  PRICE_BY_NEGOTIATION,
} from 'constants/property';
import { SellListingSummary } from 'types/Property';
import getFullPropertyTitle from 'utils/getFullPropertyTitle';
import formatAmount from 'utils/string/formatAmount';
import formatAmountMultiple from 'utils/string/formatAmountMultiple';
import { formatLandUnit } from 'utils/string/formatLandUnit';
import ucFirst from 'utils/string/ucFirst';

const landUnitFriendlyLabels = {
  m2: 'm²',
  hectares: 'hectares',
};

export const displayFields = [
  {
    field: 'location',
    label: 'LOCATION',
    getValue: (listingSummary: SellListingSummary): string =>
      getFullPropertyTitle(listingSummary.title, listingSummary.location),
  },
  {
    field: 'isPrivate',
    label: 'PRIVACY SETTING',
    getValue: (listingSummary: SellListingSummary): string =>
      listingSummary.isPrivate ? 'Private' : 'Public',
  },
  {
    field: 'propertyType',
    label: 'PROPERTY TYPE',
    getValue: (listingSummary: SellListingSummary): string =>
      ucFirst(listingSummary.propertyType),
  },
  {
    field: 'status',
    label: 'STATUS',
    getValue: (listingSummary: SellListingSummary): string =>
      SellPropertyStatusOptions.find((ss) => ss.value === listingSummary.status)
        ?.label || '-',
  },
  {
    field: 'shortId',
    label: 'PROPERTY CODE',
    getValue: (listingSummary: SellListingSummary): string =>
      listingSummary.shortId,
  },
  {
    field: 'availableAt',
    label: 'PRE-REGISTRATION AVAILABILITY DATE',
    getValue: (listingSummary: SellListingSummary): string =>
      listingSummary.availableAt
        ? format(new Date(listingSummary.availableAt), 'MM/dd/y kk:mm:ss a')
        : '-',
  },
  {
    field: 'marketPrice',
    label: 'PRICE',
    getValue: (listingSummary: SellListingSummary): string =>
      formatAmountMultiple(listingSummary.marketPrice),
  },
  {
    field: 'displayPrice',
    label: 'DISPLAY PRICE',
    getValue: (listingSummary: SellListingSummary): string =>
      ucFirst(listingSummary.displayPrice || '-'),
  },
  {
    field: 'preferredPrice',
    label: 'PREFERRED PRICE',
    getValue: (listingSummary: SellListingSummary): string =>
      [SET_DATE_OF_SALE, PRICE_BY_NEGOTIATION].includes(
        listingSummary.marketPrice
      )
        ? formatAmount(`${listingSummary.preferredPrice}`)
        : '-',
  },
  {
    field: 'marketingMethod',
    label: 'MARKETING METHOD',
    getValue: (listingSummary: SellListingSummary): string =>
      listingSummary.marketPrice === SET_DATE_OF_SALE &&
      listingSummary.marketingMethod
        ? MarketingMethodLabel[listingSummary.marketingMethod]
        : '-',
  },
  {
    field: 'toilets',
    label: 'CLOSING DATE',
    getValue: (listingSummary: SellListingSummary): string =>
      listingSummary.closingDateTime
        ? format(new Date(listingSummary.closingDateTime), 'MM/dd/y kk:mm:ss a')
        : '-',
  },
  {
    field: 'unlessSoldPrior',
    label: 'Unless Sold Prior',
    getValue: (listingSummary: SellListingSummary): string =>
      listingSummary.unlessSoldPrior ? 'Yes' : 'No',
  },
  {
    field: 'landArea',
    label: 'LAND AREA',
    getValue: (listingSummary: SellListingSummary): string =>
      listingSummary.landArea.to
        ? `${formatLandUnit(`${listingSummary.landArea.to}`)} ${
            landUnitFriendlyLabels[listingSummary.landUnit]
          }`
        : '-',
  },
  {
    field: 'bedrooms',
    label: 'BEDROOMS',
    getValue: (listingSummary: SellListingSummary): string =>
      BedroomOptions.find((a) => a.value === listingSummary?.bedrooms.to)
        ?.label || '-',
  },
  {
    field: 'living_area',
    label: 'LIVING AREAS',
    getValue: (listingSummary: SellListingSummary): string =>
      LivingAreaOptions.find((a) => a.value === listingSummary.livingAreas)
        ?.label || '-',
  },
  {
    field: 'bathrooms',
    label: 'BATHROOMS',
    getValue: (listingSummary: SellListingSummary): string =>
      BathroomOptions.find((a) => a.value === listingSummary.bathroom)?.label ||
      '-',
  },
  {
    field: 'toilets',
    label: 'TOILETS',
    getValue: (listingSummary: SellListingSummary): string =>
      ToiletOptions.find((a) => a.value === listingSummary.toilets)?.label ||
      '-',
  },
  {
    field: 'garage',
    label: 'CAR GARAGE',
    getValue: (listingSummary: SellListingSummary): string =>
      GarageOptions.find((a) => a.value === listingSummary.garage.to)?.label ||
      '-',
  },
  // {
  //   field: 'offStreetParking',
  //   label: 'OFF STREET PARKING',
  //   getValue: (listingSummary: SellListingSummary): string =>
  //     OffStreetParkingOptions.find(
  //       (a) => a.value === listingSummary.offStreetParking
  //     )?.label || '-',
  // },
  {
    field: 'glazing',
    label: 'GLAZING',
    getValue: (listingSummary: SellListingSummary): string =>
      GlazingOptions.find((a) => a.value === listingSummary.glazing)?.label ||
      '-',
  },
  {
    field: 'fencing',
    label: 'FENCING',
    getValue: (listingSummary: SellListingSummary): string =>
      FenceOptions.find((a) => a.value === listingSummary.fencing)?.label ||
      '-',
  },
  {
    field: 'heating',
    label: 'HEATING',
    getValue: (listingSummary: SellListingSummary): string =>
      (listingSummary.heating || [])
        .map((h) => HeatingOptions.find((ho) => ho.value === h)?.value || '-')
        .join('\n') || '-',
  },
  {
    field: 'otherFeatures',
    label: 'OTHER FEATURES',
    getValue: (listingSummary: SellListingSummary): string =>
      (listingSummary.otherFeatures || [])
        .map(
          (of) =>
            OtherFeaturesOptions.find((ofo) => ofo.value === of)?.label || '-'
        )
        .join('\n') || '-',
  },
  {
    field: 'createdAt',
    label: 'DATE LISTED',
    getValue: (listingSummary: SellListingSummary): string =>
      format(new Date(listingSummary.createdAt), 'MM/dd/y kk:mm:ss a'),
  },
  {
    field: 'updatedAt',
    label: 'LAST UPDATED',
    getValue: (listingSummary: SellListingSummary): string => {
      const updatedAt = listingSummary.updatedAt || listingSummary.createdAt;

      return format(new Date(updatedAt), 'MM/dd/y kk:mm:ss a');
    },
  },
  {
    field: 'toilets',
    label: 'SELLER / AGENT',
    getValue: (listingSummary: SellListingSummary): string =>
      listingSummary.isSellerLessAgentManaged && listingSummary.facilitator
        ? `Agent - ${listingSummary.facilitator.firstName} ${listingSummary.facilitator.lastName}`
        : listingSummary.user
        ? `${listingSummary.user.firstName} ${listingSummary.user.lastName}`
        : '-',
  },
  {
    field: 'toilets',
    label: 'DATE CLAIMED',
    getValue: (listingSummary: SellListingSummary): string =>
      listingSummary.claimedAt
        ? format(new Date(listingSummary.claimedAt), 'MM/dd/y kk:mm:ss a')
        : '-',
  },
];

export enum MODES {
  EDIT = 'edit',
  MATCHES = 'matches',
  OPEN_HOMES = 'openhomes',
  PRE_REGS = 'prereg',
  TRANSACTIONS = 'transactions',
}
