import React from 'react';

import ReactFlatpickr from 'react-flatpickr';

import { Clock } from 'components/primitives/SVG';
import TextField from 'components/primitives/TextField';

import { TimePickerProps } from './TimePicker.props';

import './TimePicker.css';
const TimePickerView = ({
  textFieldProps,
  onChange,
  value,
  flatpickrProps,
}: TimePickerProps): JSX.Element => {
  return (
    <ReactFlatpickr
      value={value}
      onChange={onChange}
      options={{
        enableTime: true,
        noCalendar: true,
        minuteIncrement: 30,
      }}
      render={({ value }, ref) => (
        <TextField
          reactRef={ref}
          value={String(value) || ''}
          RightComponent={<Clock />}
          {...textFieldProps}
        />
      )}
      {...flatpickrProps}
    />
  );
};

export default TimePickerView;
