import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const AgentApplications = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color } = props;

  return (
    <svg width={width || '20'} height={height || '20'} viewBox="0 0 20 20">
      <defs>
        <style>
          {`.prefix_agent_applications_icon{fill:${
            theme.colors[color || 'primary']
          }}`}
        </style>
      </defs>
      <path fill="none" d="M0 0h20v20H0z" />
      <path
        className="prefix_agent_applications_icon"
        d="M9.763 9.091a4.4 4.4 0 003.214-1.332 4.4 4.4 0 001.332-3.214 4.4 4.4 0 00-1.333-3.214 4.544 4.544 0 00-6.428 0 4.4 4.4 0 00-1.332 3.214 4.4 4.4 0 001.332 3.214 4.4 4.4 0 003.215 1.332zm0 0M10.523 20.003H5.275a3.452 3.452 0 01-2.49-.929 3.365 3.365 0 01-.967-2.512c0-.441.014-.838.039-1.181a11.214 11.214 0 01.162-1.267 10.318 10.318 0 01.3-1.267 6.261 6.261 0 01.516-1.186 4.278 4.278 0 01.773-1.025 3.325 3.325 0 011.105-.714 3.828 3.828 0 011.416-.263 1.414 1.414 0 01.768.333l.8.526a4.76 4.76 0 001.035.467 4.055 4.055 0 001.268.209 4 4 0 001.266-.209 4.8 4.8 0 001.037-.467c.254-.163.516-.335.8-.526a1.425 1.425 0 01.768-.333 3.846 3.846 0 011.418.263 3.33 3.33 0 011.105.714 4.22 4.22 0 01.773 1.025 5.908 5.908 0 01.51 1.186l.02.06.066.225-2.357-1.621a.594.594 0 00-.686 0l-4.2 2.888c-.238.167-.234 1.125-.227 3.436v1.492a.887.887 0 00.227.674z"
      />
      <path
        className="prefix_agent_applications_icon"
        d="M18.743 15.348l-3.482-2.4a.5.5 0 00-.571 0l-3.482 2.4a.541.541 0 00-.143.736.5.5 0 00.714.147l.07-.048v3.254a.523.523 0 00.514.531h2.024v-1.02a.593.593 0 111.186 0v1.02h2.014a.523.523 0 00.515-.531v-3.254l.07.048a.5.5 0 00.714-.147.541.541 0 00-.143-.736z"
      />
    </svg>
  );
};

export default AgentApplications;
