import React, { useRef } from 'react';

import placeholder from 'res/avatar-placeholder.svg';

import { AvatarProps } from './Avatar.props';
import { Image } from './Avatar.style';

const AvatarView = ({ src, ...props }: AvatarProps): JSX.Element => {
  const ref = useRef<HTMLImageElement | null>(null);

  return (
    <Image
      ref={ref}
      alt=""
      src={src || placeholder}
      onError={(e) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = placeholder;
      }}
      {...props}
    />
  );
};

export default AvatarView;
