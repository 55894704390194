import styled from '@emotion/styled';

import { pxToRem } from 'utils/theme';

import { Variants, TypographyProps } from './Typography.props';

const styles: Record<Variants, string> = {
  type1: `
        font-size: ${pxToRem(34)};
        line-height: 46px;
    `,
  type2: `
        font-size: ${pxToRem(26)};
        line-height: 36px;
    `,
  type3: `
        font-size: ${pxToRem(22)};
        line-height: 30px;
        letter-spacing: 0.59px;
    `,
  type4: `
        font-size: ${pxToRem(24)};
        line-height: 33px;
    `,
  type5: `
        font-size: ${pxToRem(20)};
        line-height: 27px;
    `,
  type6: `
        font-size: ${pxToRem(16)};
        line-height: 22px;
    `,
  type7: `
        font-size: ${pxToRem(15)};
        line-height: 20px;
        letter-spacing: 0.15px;
    `,
  type8: `
        font-size: ${pxToRem(14)};
        line-height: 19px;
        letter-spacing: 0.37px;
    `,
  type9: `
        font-size: ${pxToRem(12)};
        line-height: 17px;
        letter-spacing: 0.37px;
    `,
  type10: `
        font-size: ${pxToRem(10)};
        line-height: 14px;
        letter-spacing: 0.37px;
    `,
  type11: `
        font-size: ${pxToRem(8)};
        line-height: 11px;
        letter-spacing: 0.37px;
    `,
};

const weights: Record<string, string> = {
  Light: '300',
  Regular: '400',
  Semibold: '600',
  Bold: '700',
};

const font: Record<Variants, string> = {
  type1: weights.Bold,
  type2: weights.Bold,
  type3: weights.Bold,
  type4: weights.Regular,
  type5: weights.Semibold,
  type6: weights.Regular,
  type7: weights.Regular,
  type8: weights.Regular,
  type9: weights.Regular,
  type10: weights.Regular,
  type11: weights.Regular,
};

export const P = styled.p<TypographyProps>`
  ${({ variant }) => styles[variant || 'type6']};
  font-weight: ${({ variant, weight }) => weight || font[variant || 'type6']};
  color: ${({ theme, color = 'primary' }) => ({ ...theme.colors }[color])};
  text-align: ${({ align }) => align || 'left'};
  margin: 0;
  white-space: pre-line;
`;
