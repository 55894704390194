import React from 'react';

import { parseISO, set } from 'date-fns';
import { useField, useFormikContext } from 'formik';

import TimePicker from 'components/primitives/TimePicker';

import { FormikDatePickerProps } from './FormikTimePicker.props';

const FormikDatePickerView = ({
  fieldName,
  error,
  ...props
}: FormikDatePickerProps): JSX.Element => {
  const [{ value }, meta] = useField(fieldName);
  const formik = useFormikContext();

  return (
    <TimePicker
      {...props}
      value={parseISO(value)}
      onChange={([date]) => {
        const sanitizedDate = set(date, { seconds: 0, milliseconds: 0 });
        formik.setFieldValue(fieldName, sanitizedDate.toISOString());
      }}
      textFieldProps={{
        error: meta.error || error,
        name: fieldName,
        ...props.textFieldProps,
      }}
    />
  );
};

export default FormikDatePickerView;
