import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import Typography from 'components/primitives/Typography';

import { AppModalProps } from './AppModal.props';

const AppModalView = (props: AppModalProps): JSX.Element => {
  const {
    title,
    contentProps,
    FooterComponent,
    children,
    HeaderRight,
    ...modalProps
  } = props;
  return (
    <Modal {...modalProps}>
      <ModalOverlay />
      <ModalContent borderRadius={10} {...contentProps}>
        <ModalHeader
          borderTopLeftRadius={10}
          borderTopRightRadius={10}
          height="50px"
          backgroundColor="#E5ECFF"
        >
          <Typography variant="type8" weight="700" color="shade5">
            {title}
          </Typography>
          {HeaderRight}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody position="relative">{children}</ModalBody>
        <ModalFooter>{FooterComponent}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AppModalView;
