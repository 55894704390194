/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useMemo, useState } from 'react';

import {
  CheckCircleIcon,
  ChevronDownIcon,
  LockIcon,
  UnlockIcon,
} from '@chakra-ui/icons';
import {
  Button,
  chakra,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import ImageViewer from 'react-simple-image-viewer';

import StaticTable from 'components/layouts/StaticTable';
import SellListingEdit from 'components/modules/SellListingEdit';
import Avatar from 'components/primitives/Avatar';
import AppButton from 'components/primitives/Button';
import Label from 'components/primitives/Label';
import { Logo } from 'components/primitives/SVG';
import Tabs from 'components/primitives/Tabs';
import Typography from 'components/primitives/Typography';
import { routesSettings } from 'constants/routes';
import { useTheme } from 'utils/theme';

import SellListingAdActivities from '../SellListingAdActivities';
import SellListingAdvisory from '../SellListingAdvisory';
import SellListingMatches from '../SellListingMatch';
import SellListingOpenHomes from '../SellListingOpenHomes';
import SellListingTransactions from '../SellListingTransactions';
import SellPreRegActivities from '../SellPreRegActivities';
import { MODE, SellListingGeneratedProps } from './SellListing.props';
import {
  directMatchesColumns,
  displayFields,
  matchesColumns,
  matchesTabs,
  transactionHistoryColumns,
  openHomeColumns,
  preregColumns,
  advisoryColumns,
} from './SellListing.utils';

const SellListingView = (props: SellListingGeneratedProps): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const [mode, setMode] = useState(MODE.DEFAULT);
  const [matchesTab, setMatchesTab] = useState('matches');
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const onViewProfile = () => {
    if (props.data.user)
      history.push({
        pathname: routesSettings.MAIN_USERS.path,
        search: `?id=${props.data?.user.id}`,
      });
  };

  const transactionHistorySummary = useMemo(
    () =>
      (props.data?.transactions?.data.slice(0, 5) || []).map((tr) => [
        tr.label,
        `$${tr.amount}`,
        format(new Date(tr.createdAt), 'dd/MM/y'),
      ]),
    [props.data?.transactions]
  );

  const matchesSummary = useMemo(
    () =>
      matchesTab === 'matches'
        ? props.data?.matches?.matched.data.map((m) => [
            `${m.buyer.firstName} ${m.buyer.lastName}`,
            `${m.matchPercentage}%`,
            format(new Date(m.createdAt), 'dd/MM/y HH:mm:ss a'),
          ])
        : props.data?.matches?.direct.data.map((m) => [
            `${m.buyer.firstName} ${m.buyer.lastName}`,
            format(new Date(m.createdAt), 'dd/MM/y HH:mm:ss a'),
          ]),
    [matchesTab, props.data?.matches]
  );

  const openHomeSummary = useMemo(
    () =>
      (props.data?.openHomes || []).map((oh) => [
        `${format(new Date(oh.startTime), 'dd/MM/y hh:mma')} - ${format(
          new Date(oh.endTime),
          'hh:mma'
        )}`,
        `${oh.counts.invited}`,
        `${oh.counts.accepted}`,
        `${oh.counts.declined}`,
        `${oh.counts.undecided}`,
        `${oh.counts.attendees}`,
        oh.createdAt ? format(new Date(oh.createdAt), 'dd/MM/y') : '-',
      ]),
    [props.data?.transactions]
  );

  const preRegSummary = useMemo(
    () =>
      (props.data?.prePregistrationActivities?.data || []).map((pr) => [
        `Invited ${pr.buyer.first_name} ${pr.buyer.last_name} to Pre-register ${pr.activity}`,
        pr.invitedAt ? format(new Date(pr.invitedAt), 'dd/MM/y hh:mma') : '-',
        pr.responedAt ? format(new Date(pr.responedAt), 'dd/MM/y hh:mma') : '-',
        pr.response,
      ]),
    [props.data?.transactions]
  );

  const advisorySummary = useMemo(
    () =>
      (props.data?.sellerAdvisories || []).map((ad) => [
        ad.endAt ? format(new Date(ad.endAt), 'dd/MM/y hh:mma') : '-',
        ad.TOTAL + '',
        ad.YES + '',
        ad.NO + '',
        ad.PENDING + '',
        ad.createdAt ? format(new Date(ad.createdAt), 'dd/MM/y hh:mma') : '-',
      ]),
    [props.data?.sellerAdvisories]
  );

  const adPageVisits = props.data?.propertyAdActivity?.adPageVisits || 0;
  const addedToList = props.data?.propertyAdActivity?.addedToList || 0;
  const enteredEmail = props.data?.propertyAdActivity?.enteredEmail || 0;
  const propertyCodeActivityTotal = adPageVisits + addedToList + enteredEmail;

  if (!props.data) {
    return <Flex>Something went wrong</Flex>;
  }

  if (mode === MODE.EDIT) {
    return (
      <SellListingEdit
        onEndEdit={() => {
          setMode(MODE.DEFAULT);
          props.refetch();
          setTimeout(() => {
            window.scrollTo({
              top: 0,
            });
          }, 300);
        }}
        data={props.data}
      />
    );
  }

  if (mode === MODE.TRANSACTIONS) {
    return (
      <SellListingTransactions
        transactions={props.data.transactions}
        onBack={() => setMode(MODE.DEFAULT)}
      />
    );
  }

  if (mode === MODE.MATCHES) {
    return (
      <SellListingMatches
        id={props.data.id}
        onBack={() => setMode(MODE.DEFAULT)}
      />
    );
  }

  if (mode === MODE.PREREG_ACTIVITIES) {
    return (
      <SellPreRegActivities
        id={props.data.id}
        onBack={() => setMode(MODE.DEFAULT)}
      />
    );
  }

  if (mode === MODE.OPEN_HOMES) {
    return (
      <SellListingOpenHomes
        id={props.data.id}
        onBack={() => setMode(MODE.DEFAULT)}
      />
    );
  }

  if (mode === MODE.ADVISORY) {
    return (
      <SellListingAdvisory
        id={props.data.id}
        onBack={() => setMode(MODE.DEFAULT)}
      />
    );
  }

  if (mode === MODE.AD_ACTIVITIES) {
    return (
      <SellListingAdActivities
        id={props.data.id}
        adPageVisits={adPageVisits}
        addedToList={addedToList}
        enteredEmail={enteredEmail}
        total={propertyCodeActivityTotal}
        onBack={() => setMode(MODE.DEFAULT)}
      />
    );
  }

  return (
    <Flex flexDirection="column">
      <HStack spacing={3} alignItems="flex-start">
        <Flex flexDir="column" border="1px solid #ddd" p={4} flex={4}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #ddd"
            m={-4}
            px={4}
            py={2}
            mb={4}
          >
            <Typography variant="type8" color="shade5" weight="600">
              SELLING PROFILE
            </Typography>
            <Flex>
              {props.data?.unlocked || props.data?.payment ? (
                <Flex ml={2}>
                  <Label
                    typographyProps={{
                      variant: 'type11',
                    }}
                    color="shade16"
                    LeftComponent={
                      <Flex mr={1}>
                        <UnlockIcon color="shade1" />
                      </Flex>
                    }
                  >
                    UNLOCKED
                  </Label>
                </Flex>
              ) : (
                <Flex ml={2}>
                  <Label
                    typographyProps={{
                      variant: 'type11',
                    }}
                    color="shade16"
                    LeftComponent={
                      <Flex mr={1}>
                        <LockIcon color="shade1" width="8.22px" />
                      </Flex>
                    }
                  >
                    LOCKED
                  </Label>
                </Flex>
              )}
              {props.data?.isVerified && (
                <Flex ml={2}>
                  <Label
                    typographyProps={{
                      variant: 'type11',
                    }}
                    color="shade13"
                    LeftComponent={
                      <Flex mr={1}>
                        <Logo />
                      </Flex>
                    }
                  >
                    VERIFIED
                  </Label>
                </Flex>
              )}
              <Flex ml={2}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon color={theme.colors.primary} />}
                    border={`1px solid ${theme.colors.primary}`}
                    backgroundColor="#fff"
                  >
                    <Typography color="primary" weight="600">
                      Actions
                    </Typography>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => setMode(MODE.EDIT)}>Edit</MenuItem>
                    <MenuItem
                      onClick={
                        props.data?.isVerified
                          ? props.onUnverify
                          : props.onVerify
                      }
                    >
                      Mark as{' '}
                      {props.data?.isVerified ? 'Unverified' : 'Verified'}
                    </MenuItem>
                    <MenuItem
                      onClick={
                        props.data?.unlocked || props.data?.payment
                          ? props.onLock
                          : props.onUnlock
                      }
                    >
                      Mark as{' '}
                      {props.data?.unlocked || props.data?.payment
                        ? 'Lock'
                        : 'Unlock'}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
          </Flex>
          <Typography variant="type10" color="shade17">
            PROPERTY PHOTOS
          </Typography>
          <HStack spacing={2} mt={2} mb={4} width="100%" overflowX="auto">
            {[
              ...(props.data!.mainImage ? [props.data!.mainImage] : []),
              ...props.data!.images,
            ].map((imgl, i) => (
              <Image
                onClick={() => openImageViewer(i)}
                key={imgl.path}
                borderRadius="5px"
                height="80px"
                src={imgl.thumbnail}
              />
            ))}
          </HStack>
          <Typography variant="type10" color="shade17">
            HEADING
          </Typography>
          <Flex mt={2} mb={4} wordBreak="break-word">
            <Typography variant="type8" color="shade5">
              {props.data?.heading || '-'}
            </Typography>
          </Flex>
          <Typography variant="type10" color="shade17">
            DESCRIPTION
          </Typography>
          <Flex mt={2} mb={4} wordBreak="break-word">
            <Typography variant="type8" color="shade5">
              “{props.data?.description || '-'}”
            </Typography>
          </Flex>
          <SimpleGrid columns={2}>
            {displayFields.map((field, i) => (
              <VStack
                key={`${field.field}${i}`}
                spacing={2}
                alignItems="flex-start"
                mt={5}
              >
                <Typography variant="type10" color="shade17">
                  {field.label}
                </Typography>
                <Typography
                  style={{ whiteSpace: 'pre-wrap' }}
                  variant="type8"
                  color="shade5"
                >
                  {field.getValue(props.data!)}
                </Typography>
              </VStack>
            ))}
          </SimpleGrid>
        </Flex>
        <Flex flexDir="column" flex={3}>
          {props.data.user ? (
            <Flex flexDir="column" border="1px solid #ddd" p={4}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #ddd"
                m={-4}
                px={4}
                py={2}
                mb={4}
              >
                <Typography variant="type8" color="shade5" weight="600">
                  {props.data.isSellerLessAgentManaged
                    ? 'AGENT DETAILS'
                    : 'SELLER DETAILS'}
                </Typography>
                <Button
                  onClick={onViewProfile}
                  border={`1px solid ${theme.colors.primary}`}
                  backgroundColor="#fff"
                >
                  <Typography color="primary" weight="600">
                    View Profile
                  </Typography>
                </Button>
              </Flex>
              <Flex>
                <Avatar
                  size={80}
                  src={props.data.user.profilePic?.medium || ''}
                />
                <VStack spacing={2} ml={5} alignItems="flex-start">
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data.user.firstName} {props.data.user.lastName}
                  </Typography>
                  <Typography variant="type9" color="shade5">
                    {props.data.user.email}
                  </Typography>
                  <Typography variant="type9" color="shade5">
                    {props.data.user.phone}
                    {props.data.user.phoneVerified && (
                      <chakra.span ml={2}>
                        <CheckCircleIcon color={theme.colors.special1} />
                      </chakra.span>
                    )}
                  </Typography>
                </VStack>
              </Flex>
              <HStack mt={5} mx={-2} mb={-2} spacing={1}>
                <VStack
                  width="100%"
                  spacing={2}
                  py={2}
                  backgroundColor={theme.colors.shade4}
                >
                  <Typography variant="type10" color="shade1" weight="500">
                    SELLING
                  </Typography>
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data.user.userPropertiesCounts?.selling}
                  </Typography>
                </VStack>
                <VStack
                  width="100%"
                  spacing={2}
                  py={2}
                  backgroundColor={theme.colors.shade4}
                >
                  <Typography variant="type10" color="shade1" weight="500">
                    BUYING
                  </Typography>
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data.user.userPropertiesCounts?.buying}
                  </Typography>
                </VStack>
                <VStack
                  width="100%"
                  spacing={2}
                  py={2}
                  backgroundColor={theme.colors.shade4}
                >
                  <Typography variant="type10" color="shade1" weight="500">
                    FACILITATING
                  </Typography>
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data.user.userPropertiesCounts?.facilitating}
                  </Typography>
                </VStack>
              </HStack>
            </Flex>
          ) : null}
          <Flex mt={3} flexDir="column" border="1px solid #ddd">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              px={4}
              py={4}
            >
              <Typography variant="type8" color="shade5" weight="600">
                TRANSACTION HISTORY ({props.data.transactions?.count})
              </Typography>
              <Flex></Flex>
            </Flex>
            <StaticTable
              columns={transactionHistoryColumns}
              data={transactionHistorySummary}
            />
            <Flex flexDir="column" m={4}>
              <AppButton
                onClick={() => setMode(MODE.TRANSACTIONS)}
                variant="small"
                outline
              >
                See All
              </AppButton>
            </Flex>
          </Flex>
          <Flex mt={3} flexDir="column" border="1px solid #ddd">
            <Flex flexDir="column" justifyContent="space-between" px={4} py={4}>
              <Typography variant="type8" color="shade5" weight="600">
                MATCHES (
                {(props.data?.matches?.direct.count || 0) +
                  (props.data?.matches?.matched.count || 0)}
                )
              </Typography>
              <Flex flexDir="column" mx={-4}>
                <Tabs
                  px={2}
                  tabItemTypography={{
                    variant: 'type9',
                  }}
                  tabItems={matchesTabs}
                  activeTab={matchesTab}
                  onActiveTabChange={setMatchesTab}
                />
              </Flex>
            </Flex>
            <StaticTable
              columns={
                matchesTab === 'matches' ? matchesColumns : directMatchesColumns
              }
              data={matchesSummary || []}
            />
            <Flex flexDir="column" m={4}>
              <AppButton
                variant="small"
                outline
                onClick={() => setMode(MODE.MATCHES)}
              >
                See All
              </AppButton>
            </Flex>
          </Flex>
          <Flex mt={3} flexDir="column" border="1px solid #ddd">
            <Flex flexDir="column" justifyContent="space-between" px={4} py={4}>
              <Typography variant="type8" color="shade5" weight="600">
                PROPERTY CODE AD ACTIVITY ({propertyCodeActivityTotal})
              </Typography>
            </Flex>
            <HStack mt={5} mb={-2} px={2} spacing={1}>
              <VStack
                width="100%"
                spacing={2}
                py={2}
                backgroundColor={theme.colors.shade4}
              >
                <Typography variant="type10" color="shade1" weight="500">
                  AD PAGE VISITS
                </Typography>
                <Typography variant="type8" color="shade5" weight="600">
                  {adPageVisits}
                </Typography>
              </VStack>
              <VStack
                width="100%"
                spacing={2}
                py={2}
                backgroundColor={theme.colors.shade4}
              >
                <Typography variant="type10" color="shade1" weight="500">
                  ADDED TO LIST
                </Typography>
                <Typography variant="type8" color="shade5" weight="600">
                  {addedToList}
                </Typography>
              </VStack>
              <VStack
                width="100%"
                spacing={2}
                py={2}
                backgroundColor={theme.colors.shade4}
              >
                <Typography variant="type10" color="shade1" weight="500">
                  ENTERED EMAIL
                </Typography>
                <Typography variant="type8" color="shade5" weight="600">
                  {enteredEmail}
                </Typography>
              </VStack>
            </HStack>
            <Flex flexDir="column" m={4}>
              <AppButton
                variant="small"
                outline
                onClick={() => setMode(MODE.AD_ACTIVITIES)}
              >
                See All
              </AppButton>
            </Flex>
          </Flex>
        </Flex>
      </HStack>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
          <Typography variant="type8" color="shade5" weight="600">
            OPEN HOME SCHEDULES ({openHomeSummary.length})
          </Typography>
          <Flex>
            <AppButton
              variant="small"
              outline
              onClick={() => setMode(MODE.OPEN_HOMES)}
            >
              See All
            </AppButton>
          </Flex>
        </Flex>
        <StaticTable columns={openHomeColumns} data={openHomeSummary} />
      </Flex>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
          <Typography variant="type8" color="shade5" weight="600">
            PRE-REGISTRATION ACTIVITY (
            {props.data?.prePregistrationActivities?.count})
          </Typography>
          <Flex>
            <AppButton
              variant="small"
              outline
              onClick={() => setMode(MODE.PREREG_ACTIVITIES)}
            >
              See All
            </AppButton>
          </Flex>
        </Flex>
        <StaticTable columns={preregColumns} data={preRegSummary} />
      </Flex>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex justifyContent="space-between" alignItems="center" px={4} py={4}>
          <Typography variant="type8" color="shade5" weight="600">
            BUYER ADVISORY ({advisorySummary.length})
          </Typography>
          <Flex>
            <AppButton
              variant="small"
              outline
              onClick={() => setMode(MODE.ADVISORY)}
            >
              See All
            </AppButton>
          </Flex>
        </Flex>
        <StaticTable columns={advisoryColumns} data={advisorySummary} />
      </Flex>
      {isViewerOpen && (
        <ImageViewer
          backgroundStyle={{ zIndex: 3000 }}
          src={[
            ...(props.data!.mainImage ? [props.data!.mainImage] : []),
            ...props.data!.images,
          ].map((img) => img.original)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
        />
      )}
    </Flex>
  );
};

export default SellListingView;
