import React, { useState } from 'react';

import { Flex } from '@chakra-ui/layout';
import { CircularProgress } from '@chakra-ui/progress';
import { useToast } from '@chakra-ui/toast';
import { useMutation, useQuery } from 'react-query';

import agentsApi from 'api/agents';
import usersApi from 'api/users';
import Button from 'components/primitives/Button';
import { Close } from 'components/primitives/SVG';
import TextField from 'components/primitives/TextField';
import Typography from 'components/primitives/Typography';
import { FETCH_AGENT_NOTES, FETCH_USER_NOTES } from 'constants/queries';
import { ApiResponseError } from 'types/Api';
import { useTheme } from 'utils/theme';

import { NotesProps } from './Notes.props';

const NotesView = (props: NotesProps): JSX.Element => {
  const theme = useTheme();
  const toast = useToast();
  const [note, setNote] = useState('');

  const { type = 'user' } = props;
  const { queryKey, query, mutation } =
    type === 'agent'
      ? {
          queryKey: FETCH_AGENT_NOTES,
          query: agentsApi.getNotes,
          mutation: agentsApi.doCreateNote,
        }
      : {
          queryKey: FETCH_USER_NOTES,
          query: usersApi.getNotes,
          mutation: usersApi.doCreateNote,
        };

  const {
    isLoading,
    refetch,
    data = [],
  } = useQuery(`${queryKey}${props.id}`, () => query(props.id));

  const { mutate: createNote, isLoading: isCreateLoading } = useMutation(
    () => mutation(props.id, note),
    {
      onSuccess: () => {
        refetch();
        toast({
          description: 'Note added successfully.',
          title: 'Success',
          position: 'top-right',
          status: 'success',
        });
        setNote('');
      },
      onError: (e: ApiResponseError) => {
        toast({
          description: e.message,
          title: 'Error',
          position: 'top-right',
          status: 'error',
        });
      },
    }
  );

  const onAdd = () => {
    if (!note) {
      toast({
        description: 'Note is blank',
        title: 'Error',
        position: 'top-right',
        status: 'error',
      });
    } else {
      createNote();
    }
  };

  return (
    <Flex
      position="absolute"
      top={2}
      right={3}
      zIndex={201}
      height={300}
      width={500}
      borderRadius={10}
      boxShadow="0px 2px 10px #888888"
      backgroundColor={theme.colors.secondary}
      flexDir="column"
      overflow="hidden"
    >
      <Flex
        pl={6}
        pr={3}
        py={3}
        backgroundColor={theme.colors.shade4}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="type8" color="shade5" weight="600">
          Notes
        </Typography>
        <Flex onClick={props.onClose} cursor="pointer">
          <Close width={12} height={12} color="shade5" />
        </Flex>
      </Flex>
      <Flex px={6} py={3}>
        <Flex flex={1} flexDir="column" mr={5}>
          <TextField
            value={note}
            placeholder="Your notes..."
            onChange={(e) =>
              setNote(
                // @ts-ignore
                e.target.value as string
              )
            }
          />
        </Flex>
        <Flex flexDir="column">
          <Button onClick={onAdd} loading={isCreateLoading}>
            Add
          </Button>
        </Flex>
      </Flex>
      <Flex flexDir="column" px={6} overflowY="auto" flex={1}>
        {isLoading ? (
          <Flex flex={1} alignItems="center" justifyContent="center">
            <CircularProgress isIndeterminate color={theme.colors.primary} />
          </Flex>
        ) : (
          data.map((d) => (
            <Flex key={d.id} flexDir="column" mb={4}>
              <Flex mb={1}>
                <Typography variant="type9" weight="600" color="shade17">
                  {d.author.username}
                </Typography>
                <Flex ml={2}>
                  <Typography variant="type9" color="shade17">
                    02/21/2021 11:05:54 AM
                  </Typography>
                </Flex>
              </Flex>
              <Typography variant="type8" color="shade5">
                {d.content}
              </Typography>
            </Flex>
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default NotesView;
