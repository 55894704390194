import api from 'api/instance';
import { FeedbackRow } from 'types/Feedback';

const URL = `${process.env.REACT_APP_API_URL}/admin/feedbacks`;

export type GetAllUsersResponse = {
  data: FeedbackRow[];
  meta: {
    count: number;
    page: number;
    limit: number;
  };
};

const getAll = async (payload?: string): Promise<GetAllUsersResponse> => {
  const { data } = await api.get<GetAllUsersResponse>(`${URL}${payload}`);
  return data;
};

export default {
  getAll,
};
