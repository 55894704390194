import React from 'react';

import { format as formatDate } from 'date-fns';
import ReactFlatpickr from 'react-flatpickr';

import { Calendar } from 'components/primitives/SVG';
import TextField from 'components/primitives/TextField';

import { DatePickerProps } from './DatePicker.props';
import './DatePicker.css';

const DatePickerView = ({
  textFieldProps,
  onChange,
  value,
  flatpickrProps,
  format = 'MM/dd/yyyy',
}: DatePickerProps): JSX.Element => {
  return (
    <ReactFlatpickr
      value={value}
      onChange={onChange}
      render={({ value }, ref) => (
        <TextField
          reactRef={ref}
          value={value ? formatDate(value as Date, format) : undefined}
          RightComponent={<Calendar />}
          {...textFieldProps}
        />
      )}
      {...flatpickrProps}
    />
  );
};

export default DatePickerView;
