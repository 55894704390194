import * as Yup from 'yup';

import { BuyListingSummary } from 'types/Property';

export const transformToFormValues = (property?: BuyListingSummary): any => {
  if (property) {
    return {
      ...(property as any),
      bedrooms: {
        from: property.bedrooms?.from || undefined,
        to: property.bedrooms?.to || undefined,
      },
      garage: {
        from: property.garage?.from || undefined,
        to: property.garage?.to || undefined,
      },
      landArea: {
        from: property.landArea?.from || undefined,
        to: property.landArea?.to || undefined,
      },
    };
  }

  return {
    location: {
      region: '',
      district: [],
    },
  };
};

export const Schema = Yup.object().shape({
  bedrooms: Yup.object().shape({
    from: Yup.number(),
    to: Yup.number().when('from', {
      is: (from: any) => from,
      then: Yup.number()
        .required('Please enter a max value.')
        .moreThan(Yup.ref('from'), 'Max value should be greater than min.'),
    }),
  }),
  garage: Yup.object().shape({
    from: Yup.number(),
    to: Yup.number().when('from', {
      is: (from: number) => from || from === 0,
      then: Yup.number()
        .required('Please enter a max value.')
        .moreThan(Yup.ref('from'), 'Max value should be greater than min.'),
    }),
  }),
  landArea: Yup.object().shape({
    from: Yup.number(),
    to: Yup.number().when('from', {
      is: (from: number) => from && from !== 0,
      then: Yup.number()
        .required('Please enter a max value.')
        .moreThan(Yup.ref('from'), 'Max land area should be greater than min.'),
    }),
  }),
  marketPrice: Yup.string().required(
    'Please select the price upper limit of your property.'
  ),
  status: Yup.string().required('Please select a finance status.'),
  propertyType: Yup.string().required('Please select a property type.'),
  location: Yup.object().shape({
    region: Yup.string().required('Region is required.'),
  }),
  specificRequirement: Yup.string().required(
    'Please tell us the summary of this profile.'
  ),
});
