export const LOCATIONS = [
  {
    region: 'Northland',
    district: [
      {
        name: 'Far North',
        suburb: [
          'Ahipara',
          'Awanui',
          'Awarua',
          'Cable Bay',
          'Coopers Beach',
          'Haruru',
          'Herekino',
          'Hokianga Surrounds',
          'Horeke',
          'Houhora',
          'Kaeo',
          'Kaikohe',
          'Kaikohe Surrounds',
          'Kaikohe West',
          'Kaingaroa',
          'Kaitaia',
          'Kaitaia Surrounds',
          'Karikari Peninsula',
          'Kawakawa',
          'Kawakawa Surrounds',
          'Kerikeri',
          'Kerikeri Surrounds',
          'Kohukohu',
          'Mangamuka',
          'Mangonui',
          'Mangonui Surrounds',
          'Maromaku',
          'Matauri Bay',
          'Matawaia',
          'Moerewa',
          'Motatau',
          'Ngawha Springs',
          'North Hokianga',
          'Nukutawhiti',
          'Ohaeawai',
          'Okaihaui',
          'Omapere',
          'Opononi',
          'Opua',
          'Paewhenua Island',
          'Paihia',
          'Paihia Surrounds',
          'Pakaraka',
          'Pakotai',
          'Peria',
          'Pipiwai',
          'Pukenui',
          'Pukewharariki',
          'Punakitere Valley',
          'Rawene',
          'Russell',
          'Russell Surrounds',
          'South Hokianga',
          'Taipa',
          'Takahue',
          'Taupo Bay/Totara North',
          'Towai',
          'Waimate North',
          'Waiomi',
          'Waitangi',
          'Whangaroa',
          'Whangaroa/Kaeo Surrounds',
        ],
      },
      {
        name: 'Kaipara',
        suburb: [
          'Arapohue',
          'Dargaville',
          'Dargaville Surrounds',
          'Kaiwaka',
          'Mangawhai',
          'Mangawhai Heads',
          'Mareretu',
          'Matakohe',
          'Maungaturoto',
          'Otamatea Surrounds',
          'Paparoa',
          'Pouto',
          'Ruawai',
          'Ruawai Surrounds',
          'Tangiteroria',
          'Te Kopuru',
          'Tinopai',
          'Topuni',
          'Waipoua',
          'Whakapirau',
        ],
      },
      {
        name: 'Whangarei',
        suburb: [
          'Avenues',
          'Glenbervie',
          'Hikurangi',
          'Horahora',
          'Hukerenui and Surrounds',
          'Kamo',
          'Kauri',
          'Kensington',
          'Kiripaka',
          'Kokopu',
          'Langs Beach',
          'Mangapal and Surrounds',
          'Marsden Point',
          'Matapouri',
          'Maungakaramea',
          'Maungatapere',
          'Maunu',
          'Morningside',
          'Ngunguru',
          'Oakura Coast',
          'Okara',
          'One Tree Point',
          'Onerahi',
          'Otaika',
          'Otangarei',
          'Parahaki',
          'Parua Bay',
          'Pataua',
          'Poroti',
          'Pot Whangarei',
          'Portland',
          'Raumanga',
          'Regent',
          'Riverside',
          'Ruakaka',
          'Ruatangata',
          'Tamaterau',
          'Tikipunga',
          'Tutukaka',
          'Waipu',
          'Whakapara',
          'Whananaki',
          'Whangarei Area',
          'Whangarei Central',
          'Whangarei Heads',
          'Whangarei Surrounds',
          'Whareora',
          'Whau Valley',
          'Woodhill',
        ],
      },
    ],
  },
  {
    region: 'Auckland',
    district: [
      {
        name: 'Auckland City',
        suburb: [
          'Auckland Central',
          'Avondale',
          'Blockhouse Bay',
          'Eden Terrace',
          'Ellerslie',
          'Epsom',
          'Freemans Bay',
          'Glen Innes',
          'Glendowie',
          'Grafton',
          'Greenlane',
          'Grey Lynn',
          'Herne Bay',
          'Hillbrough',
          'Kingsland',
          'Kohimarama',
          'Lynfield',
          'Meadowbank',
          'Mission Bay',
          'Morningside',
          'Mount Albert',
          'Mount Eden',
          'Mount Roskill',
          'Mount Wellington',
          'New Windsor',
          'Newmarket',
          'Newton',
          'One Tree Hill',
          'Onehunga',
          'Orakei',
          'Otahuhu',
          'Panmure',
          'Parnell',
          'Penrose',
          'Point Chevalier',
          'Point England',
          'Ponsonby',
          'Remuera',
          'Royal Oak',
          'Saint Heliers',
          'Saint Johns',
          'Saint Marys Bay',
          'Sandringham',
          'Stonefields',
          'Three Kings',
          'Waiotaiki Bay',
          'Waterview',
          'Western Springs',
          'Westmere',
          'Wynyard Quarter',
        ],
      },
      {
        name: 'Franklin',
        suburb: [
          'Aka Aka',
          'Ararimu',
          'Awhitu',
          'Bombay',
          'Buckland',
          'Clarks Beach',
          'Glen Murray',
          'Glenbrook',
          'Hunua',
          'Kaiaua',
          'Karaka',
          'Kingseat',
          'Mangatangi',
          'Mangatawhiri',
          'Manukau Heads',
          'Mauku',
          'Mercer',
          'Miranda',
          'Onewhero',
          'Otaua',
          'Paerata',
          'Patumahoe',
          'Pokeno',
          'Pollok',
          'Port Waikato',
          'Pukekawa',
          'Pukekohe',
          'Pukekohe East',
          'Puni',
          'Ramarama',
          'Runciman',
          'Te Kohanga',
          'Tuakau',
          'Waiau Pa',
          'Waiuku',
          'Whakatiwai',
          'Whangape',
        ],
      },
      {
        name: 'Hauraki Gulf Islands',
        suburb: ['Great Barrier Island', 'Kawau Island', 'Rakino Island'],
      },
      {
        name: 'Manukau City',
        suburb: [
          'Alfriston',
          'Auckland Airport',
          'Beachlands',
          'Botany Downs',
          'Brookby',
          'Bucklands Beach',
          'Burswood',
          'Clendon Park',
          'Clevedon',
          'Clover Park',
          'Cockle Bay',
          'Dannemora',
          'East Tamaki',
          'East Tamaki Heights',
          'Eastern Beach',
          'Farm Cove',
          'Favona',
          'Flat Bush',
          'Golflands',
          'Goodwood Heights',
          'Half Moon Bay',
          'Highland Park',
          'Hillpark',
          'Howick',
          'Huntington Park',
          'Kawakawa Bay',
          'Mangere',
          'Mangere Bridge',
          'Mangere East',
          'Manukau',
          'Manukau Heights',
          'Manurewa',
          'Manurewa East',
          'Maraetai',
          'Mellons Bay',
          'Mission Heights',
          'Ness Valley',
          'Northpark',
          'Orere Point',
          'Otara',
          'Pakuranga',
          'Pakuranga Heights',
          'Papatoetoe',
          'Randwick Park',
          'Shamrock Park',
          'Shelly Park',
          'Somerville',
          'Sunnyhills',
          'The Gardens',
          'Totara Heights',
          'Totara Park',
          'Wattle Downs',
          'Weymouth',
          'Whitford',
          'Wiri',
        ],
      },
      {
        name: 'North Shore',
        suburb: [
          'Albany',
          'Bayswater',
          'Bayview',
          'Beach Haven',
          'Belmont',
          'Birkdale',
          'Birkenhead',
          'Browns Bay',
          'Campbells Bay',
          'Castor Bay',
          'Chatswood',
          'Devonport',
          'Fairview Heights',
          'Forrest Hill',
          'Glenfield',
          'Greenhithe',
          'Hauraki',
          'Hillcrest',
          'Long Bay',
          'Lucas Heights',
          'Mairangi Bay',
          'Milford',
          'Murrays Bay',
          'Narrow Neck',
          'Northcote',
          'Northcote Point',
          'Northcross',
          'Okura',
          'Oteha',
          'Paremoremo',
          'Pinehill',
          'Rosedale',
          'Rothesay Bay',
          'Schnapper Rock',
          'Stanley Point',
          'Sunnynook',
          'Takapuna',
          'Torbay',
          'Totara Vale',
          'Unsworth Heights',
          'Waiake',
          'Wairau Valley',
          'Windsor Park',
        ],
      },
      {
        name: 'Papakura',
        suburb: [
          'Ardmore',
          'Conifer Grove',
          'Drury',
          'Hingaia',
          'Opaheke',
          'Pahurehure',
          'Papakura',
          'Red Hill',
          'Rosehill',
          'Takanini',
        ],
      },
      {
        name: 'Rodney',
        suburb: [
          'Albany Heights',
          'Algies Bay',
          'Arkles Bay',
          'Army Bay',
          'Coatesville',
          'Dairy Flat',
          'Gulf Harbour',
          'Hatfields Beach',
          'Helensville',
          'Hibiscus Coast Surrounds',
          'Huapai',
          'Kaipara Flats',
          'Kaukapakapa',
          'Kumeu',
          'Leigh',
          'Mahurangi East',
          'Mahurangi West',
          'Makarau',
          'Manly',
          'Matakana',
          'Matakatia',
          'Millwater',
          'Muriwai',
          'Omaha',
          'Orewa',
          'Pakiri',
          'Parakai',
          'Point Wells',
          'Port Albert',
          'Puhoi',
          'Red Beach',
          'Redvale',
          'Riverhead',
          'Rodney Surrounds',
          'Sandspit',
          'Shelly Beach',
          'Silverdale',
          'Snells Beach',
          'South Head',
          'Stanmore Bay',
          'Stillwater',
          'Tapora',
          'Tauhoa',
          'Taupaki',
          'Tawharanui Peninsula',
          'Te Arai',
          'Te Hana',
          'Ti Point',
          'Tindalls Beach',
          'Tomarata',
          'Waimauku',
          'Wainui',
          'Waitoki',
          'Waiwera',
          'Warkworth',
          'Wayby Valley',
          'Wellsford',
          'Whangaparaoa',
          'Whangaripo',
          'Whangateau',
          'Wharehine',
          'Woodhill Forest',
        ],
      },
      {
        name: 'Waiheke Island',
        suburb: [
          'Omiha',
          'Oneroa',
          'Onetangi',
          'Ostend',
          'Palm Beach',
          'Surfdale',
          'Waiheke Island',
        ],
      },
      {
        name: 'Waitakere City',
        suburb: [
          'Bethells Beach',
          'Cornwalls',
          'Glen Eden',
          'Glendene',
          'Green Bay',
          'Henderson',
          'Henderson Valley',
          'Herald Island',
          'Hobsonville',
          'Huia',
          'Karekare',
          'Kelston',
          'Laingholm',
          'Massey',
          'New Lynn',
          'Oratia',
          'Parau',
          'Piha',
          'Ranui',
          'Sunnyvale',
          'Swanson',
          'Te Atatu Peninsula',
          'Te Atatu South',
          'Titirangi',
          'Waiatarua',
          'Waitakere',
          'West Harbour',
          'Westgate',
          'Whenuapai',
        ],
      },
    ],
  },
  {
    region: 'Bay of Plenty',
    district: [
      {
        name: 'Kawerau',
        suburb: [],
      },
      {
        name: 'Opotiki',
        suburb: [
          'Opotiki Coastal',
          'Opotiki Surrounds',
          'Te kaha',
          'Waihau Bay',
        ],
      },
      {
        name: 'Rotorua',
        suburb: [
          'Atiamuri',
          'Broadlands',
          'Fairy Springs',
          'Fenton Park',
          'Fordlands',
          'Glenholme',
          'Hamurana',
          'Hannahs Bay',
          'Hillcrest',
          'Holdens Bay',
          'Horohoro',
          'Kawaha Point',
          'Koutu',
          'Lake Areas',
          'Lake Okareka',
          'Lake Rotoehu',
          'Lake Rotoiti',
          'Lake Rotoma',
          'Lake Tarawera',
          'Lynmore',
          'Mamaku',
          'Mangakakahi',
          'Mourea',
          'Ngakuru',
          'Nagpuna',
          'Ngongotaha',
          'Ohinemutu',
          'Okere Falls',
          'Owhata',
          'Pukehangi',
          'Reporoa and Surrounds',
          'Rerewhakaaitu',
          'Rotoiti Forest',
          'Rotorua Central',
          'Rotorua Surrounds',
          'Selwyn Heights',
          'Springfield',
          'Tihiotanga',
          'Tikitere',
          'Utuhina',
          'Victoria',
          'Waikite Valley',
          'Westbrook',
          'Western Heights',
          'Whakarewarewa',
        ],
      },
      {
        name: 'Tauranga',
        suburb: [
          'Avenues',
          'Bellevue',
          'Bethlehem',
          'Bookfield',
          'Gate Pa',
          'Greerton',
          'Hairini',
          'Judea',
          'Matapihi',
          'Matua',
          'Maungatapu',
          'Mount Maunganui',
          'Ohauiti',
          'Oropi',
          'Otumoetai',
          'Papamoa',
          'Parkvale',
          'Poike',
          'Pyes Pa',
          'Tauranga Central',
          'Tauranga South',
          'Tauranga Surrounds',
          'Tauriko',
          'Welcome Bay',
        ],
      },
      {
        name: 'Western Bay Of Plenty',
        suburb: [
          'Aongatete',
          'Athenree',
          'Kaimai',
          'Katikati',
          'Maketu',
          'Ohauiti',
          'Omokoroa',
          'Oropi',
          'Paengaroa',
          'Pahoia',
          'Pongakawa',
          'Pukehina',
          'Pyes Pa',
          'Te Puke',
          'Te Puna',
          'Waihi Beach',
          'Wairoa',
          'Western BOP Surrounds',
          'Whakamarama',
        ],
      },
      {
        name: 'Whakatane',
        suburb: [
          'Awakeri',
          'Coastlands',
          'Edgecumbe',
          'Galatea',
          'Manawahe',
          'Matahi',
          'Matata',
          'Murupara',
          'Murupara Surrounds',
          'Nukuhou',
          'Ohope',
          'Otakiri',
          'Pikowai',
          'Poroporo',
          'Rotoma',
          'Ruatoki',
          'Taneatua',
          'Te Teko',
          'Thornton',
          'Waimana',
          'Waiohau',
          'Whakatane',
          'Whakatane Surrounds',
          'White Pine Bush',
        ],
      },
    ],
  },
  {
    region: 'Waikato',
    district: [
      {
        name: 'Hamilton',
        suburb: [
          'Avalon',
          'Bader',
          'Baverstock',
          'Beerescourt',
          'Burbush',
          'Chartwell',
          'Chedworth',
          'Claudelands',
          'Deanwell',
          'Dinsdale',
          'Enderley',
          'Fairfield',
          'Fairview Downs',
          'Fitzroy',
          'Flagstaff',
          'Forest Lake',
          'Frankton',
          'Glenview',
          'Grandview Heights',
          'Hamilton Central',
          'Hamilton East',
          'Hamilton Lake',
          'Hamilton Surrounds',
          'Harrowfield',
          'Hillcrest',
          'Huntington',
          'Maeroa',
          'Melville',
          'Nawton',
          'Northgate',
          'Pukete',
          'Queenwood',
          'Riverlea',
          'Rototuna',
          'Rototuna North',
          'Ruakura',
          'Saint Andrews',
          'Silverdale',
          'Te Rapa',
          'Te Rapa Park',
          'Temple View',
          'Western Heights',
          'Whitiora',
        ],
      },
      {
        name: 'Hauraki',
        suburb: [
          'Hauraki Surrounds',
          'Kaihere',
          'Karangahake',
          'Kerepehi',
          'Mangatarata',
          'Ngatea',
          'Paeroa',
          'Pipiroa',
          'Turua',
          'Waihi',
          'Waikino',
          'Waitakaruru',
        ],
      },
      {
        name: 'Matamata-Piako',
        suburb: [
          'Gordon',
          'Manawaru',
          'Matamata',
          'Morrinsville',
          'Ngarua',
          'Okauia',
          'Richmond Downs',
          'Tahuna',
          'Te Aroha',
          'Te Poi',
          'Turangaomoana',
          'Waharoa',
          'Waihou',
          'Waitoa',
          'Wardville',
        ],
      },
      {
        name: 'Otorohanga',
        suburb: [
          'Kawhia',
          'Maihiihi',
          'Oparau',
          'Otorohanga',
          'Otorohanga Surrounds',
          'Taharoa',
        ],
      },
      {
        name: 'South Waikato',
        suburb: [
          'Arapuni',
          'Kinleith',
          'Lichfield',
          'Putaruru',
          'South Waikato Surrounds',
          'Tapapa',
          'Tirau',
          'Tokoroa',
        ],
      },
      {
        name: 'Taupo',
        suburb: [
          'Acacia Bay',
          'Broadlands Forest',
          'Hatepe',
          'Hilltop',
          'Iwitahi',
          'Kinloch',
          'Kuratau',
          'Lake Taupo',
          'Lake Taupo (East)',
          'Lake Taupo (West)',
          'Mangakino',
          'Maunganamu',
          'Motuoapa',
          'Nukuhau',
          'Omori',
          'Oruanui',
          'Oruatua',
          'Pukawa',
          'Rainbow Point',
          'Rangatira Park',
          'Richmond Heights',
          'Tahorakuri',
          'Tauhara',
          'Taupo',
          'Taupo Surrounds',
          'Te Rangiita',
          'Tokaanu',
          'Turangi',
          'Waipahihi',
          'Wairakei and Surrounds',
          'Waitahanui',
          'Waitetoko',
          'Whareroa',
          'Wharewaka',
        ],
      },
      {
        name: 'Thames-Coromandel',
        suburb: [
          'Cooks Beach',
          'Coroglen',
          'Coromandel',
          'Coromandel Coast',
          'Coromandel Surrounds',
          'East Coast Surrounds',
          'Hahei',
          'Hikuai',
          'Hikutaia',
          'Hot Water Beach',
          'Kaimarama',
          'Kopu',
          'Kuaotunu',
          'Matarangi',
          'Matatoki',
          'Motutapere Island',
          'Onemana',
          'Opito Bay',
          'Opoutere',
          'Pauanui',
          'Port Charles',
          'Puriri',
          'Tairua',
          'Tapu',
          'Te Kouma',
          'Te Mata',
          'Te Puru',
          'Thames',
          'Thames Coast',
          'Thames Surrounds',
          'Thornton Bay',
          'Tuateawa',
          'Waikawau',
          'Waiomu',
          'Waitete Bay',
          'Whakatete Bay',
          'Whangamata',
          'Whnagapoua',
          'Whenuakite',
          'Whiritoa',
          'Whitianga',
        ],
      },
      {
        name: 'Waikato',
        suburb: [
          'Churchill',
          'Eureka',
          'Glen Massey',
          'Gordonton',
          'Hampton Downs',
          'Horotiu',
          'Horsham Downs',
          'Huntly',
          'Maramarua',
          'Matangi',
          'Meremere',
          'Newstead',
          'Ngaruawahia',
          'Orini',
          'Pukemoremore',
          'Puketaha',
          'Raglan',
          'Rangiriri',
          'Rangiriri West',
          'Rotokauri',
          'Rotongaro',
          'Ruawaro',
          'Tamahere',
          'Taupiri',
          'Tauwhare',
          'Te Akau',
          'Te Hoe',
          'Te Kauwhata',
          'Te Kowhai',
          'Waerenga',
          'Waikato Surrounds',
          'Waiterimu',
          'Whatawhata',
          'Whitikahu',
        ],
      },
      {
        name: 'Waipa',
        suburb: [
          'Cambridge',
          'Kaipaki',
          'Karapiro',
          'Kihikihi',
          'Leamington',
          'Ngahinapouri',
          'Ohaupo',
          'Pirongia',
          'Pukeatua',
          'Rotoorangi',
          'Rukuhia',
          'Te Awamutu',
          'Te Miro',
          'Te Pahu',
          'Waipa Surrounds',
          'Wharepapa South',
        ],
      },
      {
        name: 'Waitomo',
        suburb: [
          'Aria',
          'Awakino',
          'Benneydale',
          'Mahoenui',
          'Marokopa',
          'Piopio',
          'Te Kuiti',
          'Waitomo',
        ],
      },
    ],
  },
  {
    region: 'Gisborne',
    district: [
      {
        name: 'Gisborne',
        suburb: [
          'Awapuni',
          'East Cape',
          'Elgin',
          'Gisborne',
          'Gisborne City Surrounds',
          'Gisborne Coastal',
          'Gisborne Country',
          'Hexton',
          'Hicks Bay',
          'Inner Kaiti',
          'Lytton West',
          'Makaraka',
          'Mangapapa',
          'Manutuke',
          'Matawai',
          'Matokitoki',
          'Muriwai',
          'Ngatapa',
          'Okitu',
          'Ormond',
          'Outer Kaiti',
          'Patutahi',
          'Pouawa',
          'Riverdale',
          'Ruatoria and Surrounds',
          'Tamarau',
          'Taruheru',
          'Te Araroa',
          'Te Hapara',
          'Te Karaka',
          'Tiniroto',
          'Tokomaru Bay',
          'Tolaga Bay',
          'Wainui',
          'Waipaoa',
          'Whangara',
          'Whatatutu',
          'Whataupoko',
        ],
      },
    ],
  },
  {
    region: 'Hawkes Bay',
    district: [
      {
        name: 'Central Hawkes Bay',
        suburb: [
          'Central Hawkes Bay Coastal',
          'Central Hawkes Bay Country',
          'Elsthorpe',
          'Mangaorapa',
          'Ongaonga',
          'Ormondville',
          'Otane',
          'Porangahau',
          'Takapau',
          'Tikokino',
          'Waipawa',
          'Waipukurau and Surrounds',
        ],
      },
      {
        name: 'Hastings',
        suburb: [
          'Akina',
          'Bridge Pa',
          'Camberley',
          'Clive',
          'Crownthorpe',
          'Dartmoor',
          'Eskdale',
          'Fernhill',
          'Flaxmere',
          'Frimley',
          'Hastings',
          'Hastings Country',
          'Haumoana',
          'Havelock North',
          'Hawkes Bay Area',
          'Karamu',
          'Kuripapango',
          'Longlands',
          'Mahora',
          'Mangateretere',
          'Maraekakaho',
          'Mayfair',
          'Omahu',
          'Otamauri',
          'Pakipaki',
          'Pakowhai',
          'Parkvale',
          'Poukawa',
          'Puketapu',
          'Puketitiri',
          'Raukawa',
          'Raureka',
          'Roys Hill',
          'Saint Leonards',
          'Sherenden',
          'Tangoio',
          'Te Awanga',
          'Te Haroto',
          'Tomoana',
          'Tutira',
          'Twyford',
          'Waimarama',
          'Waipatu',
          'Waiwhare',
          'Whakatu',
          'Whanawhana',
          'Woolwich',
        ],
      },
      {
        name: 'Napier',
        suburb: [
          'Ahuriri',
          'Awatoto',
          'Bay View',
          'Greenmeadows',
          'Jervoistown',
          'Maraenui',
          'Marewa',
          'Meeanee',
          'Napier Central',
          'Napier Hill Napier Port',
          'Napier South',
          'Onekawa',
          'Pandora',
          'Pirimai',
          'Poraiti',
          'Tamatea',
          'Taradale',
          'Te Awa',
          'Westshore',
        ],
      },
      {
        name: 'Wairoa',
        suburb: [
          'Kotemaori',
          'Mahia',
          'Nuhaka/Morere',
          'Raupunga',
          'Ruakituri',
          'Tuai/Ohuka',
          'Wairoa',
          'Wairoa Country',
        ],
      },
    ],
  },
  {
    region: 'Taranaki',
    district: [
      {
        name: 'New Plymouth',
        suburb: [
          'Bell Block',
          'Blagdon',
          'Brooklands',
          'Egmont Village',
          'Ferndale',
          'Fitzroy',
          'Frankleigh Park',
          'Glen Avon',
          'Highlands Park',
          'Hillsborough',
          'Hurdon',
          'Hurworth',
          'Inglewood',
          'Korito',
          'Lepperton',
          'Lynmouth',
          'Mangorei',
          'Marfell',
          'Merrilands',
          'Mokau',
          'Motumahanga (Saddleback)',
          'Moturoa',
          'New Plymouth Area Surrounds',
          'New Plymouth Central',
          'New Plymouth City Surrounds',
          'New Plymouth Coastal',
          'Oakura',
          'Okato',
          'Okoki',
          'Omata',
          'Onaero',
          'Spotswood',
          'Strandon',
          'Tarata',
          'Tarurutangi',
          'Tikorangi',
          'Tongaporutu',
          'Urenui',
          'Uruti',
          'Vogeltown',
          'Waitara',
          'Waiwhakaiho',
          'Welbourn',
          'Westown',
          'Whalers Gate',
        ],
      },
      {
        name: 'South Taranaki',
        suburb: [
          'Eltham',
          'Hawera',
          'Kaponga',
          'Manaia',
          'Manutahi',
          'Matemateaonga',
          'Normanby',
          'Ohangai',
          'Okaiawa',
          'Opunake',
          'Patea',
          'Rahotu',
          'South Taranaki Surrounds',
          'Waitotara',
          'Waverley',
        ],
      },
      {
        name: 'Stratford',
        suburb: [
          'Douglas',
          'Midhirst',
          'Pohokura and Surrounds',
          'Stratford',
          'Stratford Surrounds',
          'Tahora',
        ],
      },
    ],
  },
  {
    region: 'Manawatu / Wanganui',
    district: [
      {
        name: 'Horowhenua',
        suburb: [
          'Foxton',
          'Foxton Beach',
          'Hokio Beach',
          'Horowhenua Surrounds',
          'Levin',
          'Manakau',
          'Moutoa',
          'Ohau',
          'Shannon',
          'Tokomaru',
          'Waikawa Beach',
          'Waitarere',
          'Waitarere Beach',
        ],
      },
      {
        name: 'Manawatu',
        suburb: [
          'Apiti',
          'Bunnythorpe',
          'Cheltenham',
          'Feilding',
          'Halcombe',
          'Himatangi',
          'Himatangi Beach',
          'Kairanga',
          'Kimbolton',
          'Manawatu Surrounds',
          'Newbury',
          'Ohakea',
          'Ohingaiti',
          'Opiki',
          'Pohangina',
          'Rangiwahia',
          'Rongotea',
          'Sanson',
          'Tangimoana',
          'Tiakitahuna',
          'Waituna West',
        ],
      },
      {
        name: 'Palmerston North City',
        suburb: [
          'Aokautere',
          'Ashhurst',
          'Awapuni',
          'Cloverlea',
          'Fitzherbert',
          'Highbury',
          'Hokowhitu',
          'Kelvin Grove',
          'Linton',
          'Longburn',
          'Massey University',
          'Milson',
          'Palmerston North Central',
          'Palmerston North Surrounds',
          'Parklands',
          'Riverdale',
          'Roslyn',
          'Takaro',
          'Terrace End',
          'Turitea',
          'West End',
          'Westbrook',
          'Whakarongo',
        ],
      },
      {
        name: 'Rangitikei',
        suburb: [
          'Bulls',
          'Hunterville',
          'Koitiata',
          'Lake Alice',
          'Mangaweka',
          'Marton',
          "Parewanui/Scott's Ferry",
          'Rangitikei Surrounds',
          'Ratana',
          'Taihape and Surrounds',
          'Turakina',
        ],
      },
      {
        name: 'Ruapehu',
        suburb: [
          'Kakahi',
          'Manunui',
          'National Park',
          'Ohakune',
          'Ohura',
          'Owhango',
          'Raetihi',
          'Raupehu-King Country',
          'Taumaruruni',
          'Tongariro',
          'Waimiha-Ongarue',
          'Waiouru',
        ],
      },
      {
        name: 'Tararua',
        suburb: [
          'Dannevirke',
          'Eketahuna',
          'Norsewood',
          'Pahiatua',
          'Pongaroa',
          'Tiraumea',
          'Woodville',
        ],
      },
      {
        name: 'Wanganui',
        suburb: [
          'Aramoho',
          'Bastia Hill',
          'Brunswick',
          'Castlecliff',
          'College Estate',
          'Durie Hill',
          'Fordell',
          'Gonville',
          'Kai Iwi',
          'Kaitoke',
          'Kakatahi',
          'Mangamahu',
          'Marybank',
          'Matahiwi',
          'Okoia',
          'Otamatea',
          'Papaiti',
          'Parikino',
          'Putiki',
          'Saint Johns Hill',
          'Springvale',
          'Tawhero',
          'Wanganui Central',
          'Wanganui East',
          'Westmere',
          'Whangaehu',
        ],
      },
    ],
  },
  {
    region: 'Wellington',
    district: [
      {
        name: 'Carterton',
        suburb: ['Carterton', 'Carterton Surrounds', 'Gladstone', 'Te Wharau'],
      },
      {
        name: 'Kapiti Coast',
        suburb: [
          'Kapiti Coast Surrounds',
          'Otaihanga',
          'Otaki',
          'Otaki Beach',
          'Paekakariki',
          'Paraparaumu',
          'Paraparaumu Beach',
          'Peka Peka',
          'Raumati Beach',
          'Raumati South',
          'Te Horo',
          'Waikanae',
          'Waikanae Beach',
        ],
      },
      {
        name: 'Lower Hutt',
        suburb: [
          'Alicetown',
          'Avalon',
          'Belmont',
          'Boulcott',
          'Central Hutt',
          'Days Bay',
          'Eastbourne',
          'Epuni',
          'Fairfield',
          'Gracefield',
          'Harbour View',
          'Haywards',
          'Kelson',
          'Orokoro',
          'Lower Hutt',
          'Lowry Bay',
          'Mahina Bay',
          'Manor Park',
          'Maungaraki',
          'Melling',
          'Moera',
          'Naenae',
          'Normandale',
          'Pencarrow Head',
          'Petone',
          'Point Howard',
          'Seaview',
          'Stokes Valley',
          'Taita',
          'Tirohanga',
          'Wainuiomata',
          'Waiwhetu',
          'Waterloo',
          'Woburn',
          'York Bay',
        ],
      },
      {
        name: 'Masterton',
        suburb: [
          'Bideford',
          'Blairlogie',
          'Castlepoint',
          'Kiriwhakapapa',
          'Mangapakeha',
          'Masterton',
          'Masterton Surrounds',
          'Matahiwi',
          'Mataikona',
          'Mauriceville',
          'Opaki',
          'Otahome',
          'Riversdale Beach',
          'Solway',
          'Te Whiti',
          'Tinui',
          'Upper Plain',
          'Wainuioru',
          'Whangaehu Valley',
        ],
      },
      {
        name: 'Porirua',
        suburb: [
          'Aotea',
          'Ascot Park',
          'Camborne',
          'Cannons Creek',
          'Elsdon',
          'Judgeford',
          'Mana',
          'Papakowhai',
          'Paremata',
          'Pauatahanui',
          'Plimmerton',
          'Porirua',
          'Porirua East',
          'Pukerua Bay',
          'Ranui Heights',
          'Takapuwahia',
          'Titahi Bay',
          'Waitangirua',
          'Whitby',
        ],
      },
      {
        name: 'South Wairarapa',
        suburb: [
          'Cape Palliser',
          'Featherston',
          'Greytown',
          'Hinakura',
          'Lake Ferry',
          'Martinborough',
          'Pirinoa',
          'Tora',
          'Tuturumuri',
        ],
      },
      {
        name: 'Upper Hutt',
        suburb: [
          'Akatarawa',
          'Birchville',
          'Blue Mountains',
          'Brown Owl',
          'Clouston Park',
          'Craigs Flat',
          'Ebdentown',
          'Elderslea',
          'Heretaunga',
          'Kaitoke',
          'Kingsley Heights',
          'Mangaroa',
          'Maoribank',
          'Maymorn',
          'Moonshine Valley',
          'Mount Marua',
          'Pakuratahi',
          'Pinehaven',
          'Rimutaka Hill',
          'Riverstone Terraces',
          'Silverstream',
          'Te Marua',
          'Timberlea',
          'Totara Park',
          'Trentham',
          'Upper Hutt',
          'Wallaceville',
          'Whitemans Valley',
        ],
      },
      {
        name: 'Wellington City',
        suburb: [
          'Aro Valley',
          'Berhampore',
          'Breaker Bay',
          'Broadmeadows',
          'Brooklyn',
          'Churton Park',
          'Crofton Downs',
          'Glenside',
          'Grenada North',
          'Grenada Village',
          'Hataitai',
          'Highbury',
          'Horokiwi',
          'Houghton Bay',
          'Island Bay',
          'Johnsonville',
          'Kaiwharawhara',
          'Karaka Bays',
          'Karori',
          'Kelburn',
          'Khandallah',
          'Kilbirnie',
          'Kingston',
          'Lyall Bay',
          'Makara',
          'Maupuia',
          'Melrose',
          'Miramar',
          'Mornington',
          'Mount Cook',
          'Mount Victoria',
          'Newlands',
          'Newtown',
          'Ngaio',
          'Ngauranga',
          'Northland',
          'Ohariu',
          'Oriental Bay',
          'Owhiro Bay',
          'Paparangi',
          'Rongotai',
          'Roseneath',
          'Seatoun',
          'Southgate',
          'Strathmore Park',
          'Tawa',
          'Te Aro',
          'Thorndon',
          'Vogeltown',
          'Wadestown',
          'Wellington Central',
          'Wilton',
          'Woodridge',
        ],
      },
    ],
  },
  {
    region: 'Marlborough',
    district: [
      {
        name: 'Marlborough',
        suburb: [
          'Anakiwa and Surrounds',
          'Awatere Valley',
          'Blenheim Central',
          'Burleigh',
          "D'Urville Island",
          'Fairhall',
          'Grovetown',
          'Havelock',
          'Islington',
          'Kenepuru Sound',
          'Mahau Sound',
          'Marlborough Country',
          'Marlborough Sounds',
          'Mayfield',
          'Okiwi Bay',
          'Omaka',
          'Pelorus Sounds',
          'Picton',
          'Port Underwood',
          'Queen Charlotte Sound',
          'Rai Valley',
          'Rapaura',
          'Rarangi',
          'Redwoodtown',
          'Renwick',
          'Riverlands',
          'Rotorua',
          'Seddon',
          'Spring Creek',
          'Springlands',
          'Stephens Island (Takapourewa)',
          'Tuamarina',
          'Waihopai Valley',
          'Waikawa',
          'Wairau Valley',
          'Ward',
          'Witherlea',
          'Woodbourne',
        ],
      },
    ],
  },
  {
    region: 'Nelson/Tasman',
    district: [
      {
        name: 'Nelson',
        suburb: [
          'Annesbrook',
          'Atawhai',
          'Beachville',
          'Bishopdale',
          'Britannia Heights',
          'Cable Bay',
          'Delaware Bay',
          'Enner Glynn',
          'Glenduan',
          'Hira',
          'Maitai',
          'Maitlands',
          'Marsden Valley',
          'Marybank',
          'Moana',
          'Monaco',
          'Nayland',
          'Nelson City',
          'Nelson South',
          'Port Hills',
          'Port Nelson',
          'Stepneyville',
          'Stoke',
          'Tahunanui',
          'The Brook',
          'The Wood',
          'Todds Valley',
          'Toi Toi',
          'Wakapuaka',
          'Wakatu',
          'Washington Valley',
          'Whangamoa',
        ],
      },
      {
        name: 'Tasman',
        suburb: [
          'Abel Tasman',
          'Aniseed Valley',
          'Appleby',
          'Belgrove',
          'Best Island',
          'Brightwater',
          'Collingwood',
          'Dovedale',
          'East Takaka',
          'Glenhope',
          'Golden Bay',
          'Golden Downs',
          'Hope',
          'Kaiteriteri',
          'Kikiwa',
          'Kohatu',
          'Korere',
          'Little Kaiteriteri',
          'Lower Moutere',
          'Mahana',
          'Mapua',
          'Marahau',
          'Motueka',
          'Motupiko',
          'Motupipi',
          'Murchison',
          'Neudorf',
          'Ngatimoti',
          'Onekaka',
          'Pakawau',
          'Parapara',
          'Patons Rock',
          'Pohara',
          'Rangihaeata',
          'Redwood Valley',
          'Richmond',
          'Ruby Bay',
          'St Arnaud',
          'Takaka',
          'Tapawera',
          'Tasman',
          'Tata Beach',
          'Thorpe',
          'Upper Moutere',
          'Wakefield',
          'Woodstock',
        ],
      },
    ],
  },
  {
    region: 'West Coast',
    district: [
      {
        name: 'Buller',
        suburb: [
          'Buller Surrounds',
          'Cape Foulwind',
          'Carters Beach',
          'Charleston',
          'Denniston',
          'Granity',
          'Hector',
          'Inangahua',
          'Kahurangi National Park',
          'Karamea',
          'Little Wanganui',
          'Maruia',
          'Punakaiki',
          'Reefton',
          'Seddonville',
          'Springs Junction',
          'Stockton',
          'Waimangaroa',
          'Westport',
        ],
      },
      {
        name: 'Grey',
        suburb: [
          'Ahaura',
          'Atarau',
          'Barrytown',
          'Blackball',
          'Blaketown',
          'Coal Creek',
          'Cobden',
          'Dobson',
          'Dunollie',
          'Gladstone',
          'Greymouth',
          'Haupiri',
          'Hohonu',
          'Ikamatua',
          'Kaiata',
          'Karoro',
          'Marsden',
          'Moana / Lake Brunner',
          'Ngahere',
          'Nine Mile',
          'Paroa',
          'Rapahoe',
          'Runanga',
          'Stillwater',
          'Taylorville',
          'Totara Flat',
        ],
      },
      {
        name: 'Westland',
        suburb: [
          'Awatuna',
          'Bruce Bay',
          'Fox Glacier',
          'Franz Josef Glacier',
          'Haast',
          'Harihari',
          'Hokitika',
          'Jackson Bay',
          'Kaniere',
          'Kokatahi',
          'Kumara',
          'Okarito',
          'Otira',
          'Ross',
          'Westland Area',
          'Whataroa',
        ],
      },
    ],
  },
  {
    region: 'Canterbury',
    district: [
      {
        name: 'Ashburton',
        suburb: [
          'Allenton',
          'Ashburton',
          'Chertsey',
          'Eiffelton',
          'Elgin',
          'Fairton',
          'Hampstead',
          'Hinds',
          'Huntingdon',
          'Lauriston',
          'Lismore',
          'Mayfield',
          'Methven',
          'Mount Somers',
          'Rakaia',
          'Tinwald',
          'Westerfield',
          'Winchmore',
          'Winslow',
        ],
      },
      {
        name: 'Banks Peninsula',
        suburb: [
          'Akaroa',
          'Birdlings Flat',
          'Cass Bay',
          'Charteris Bay',
          'Corsair Bay',
          'Diamond Harbour',
          'Duvauchelle',
          'French Farm',
          'Governors Bay',
          'Le Bons Bay',
          'Little Akaloa',
          'Little River',
          'Lyttelton',
          'Okains Bay',
          'Pigeon Bay',
          'Port Levy',
          'Purau',
          'Robinsons Bay',
          'Takamatua',
          'Wainui',
        ],
      },
      {
        name: 'Christchurch',
        suburb: [
          'Addington',
          'Aidanfield',
          'Aranui',
          'Avondale',
          'Avonhead',
          'Avonside',
          'Beckenham',
          'Belfast',
          'Bexley',
          'Bishopdale',
          'Bromley',
          'Brooklands',
          'Broomfield',
          'Bryndwr',
          'Burnside',
          'Burwood',
          'Casebrook',
          'Cashmere',
          'Christchurch Airport',
          'Christchurch Central',
          'Clifton',
          'Cracroft',
          'Dallington',
          'Edgeware',
          'Fendalton',
          'Ferrymead',
          'Halswell',
          'Harewood',
          'Heathcote Valley',
          'Hei Hei',
          'Hillmorton',
          'Hillsborough',
          'Hoon Hay',
          'Hornby',
          'Huntsbury',
          'Ilam',
          'Islington',
          'Kainga',
          'Kennedys Bush',
          'Linwood',
          'Mairehau',
          'Marshland',
          'Merivale',
          'Middleton',
          'Moncks Bay',
          'Mount Pleasant',
          'New Brighton North',
          'New Brighton',
          'Northcote',
          'Northwood',
          'Opawa',
          'Papanui',
          'Parklands',
          'Phillipstown',
          'Redcliffs',
          'Redwood',
          'Riccarton',
          'Richmond',
          'Richmond Hill',
          'Russley',
          'Saint Albans',
          'Saint Martins',
          'Shirley',
          'Sockburn',
          'Somerfield',
          'South New Brighton',
          'Southshore',
          'Spencerville',
          'Spreydon',
          'Strowan',
          'Styx',
          'Sumner',
          'Sydenham',
          'Templeton',
          'Upper Riccarton',
          'Waimairi Beach',
          'Wainoni',
          'Waltham',
          'Westmorland',
          'Wigram',
          'Woolston',
          'Yaldhurst',
        ],
      },
      {
        name: 'Hurunui',
        suburb: [
          'Amberley',
          'Balcairn',
          'Cheviot',
          'Culverden',
          'Domett',
          'Gore Bay',
          'Greta Valley',
          'Hanmer Springs',
          'Hawarden',
          'Hundalee',
          'Hurunui',
          'Leithfield',
          'Leslie Hills',
          'Lewis Pass',
          'Lyford',
          'MacDonald Downs',
          'Motunau',
          'Pyramid Valley',
          'Rotherham',
          'Scargill',
          'Virginia',
          'Waiau',
          'Waikari',
          'Waipara',
        ],
      },
      {
        name: 'Kaikoura',
        suburb: ['Clarence', 'Hapuku', 'Kaikoura', 'Oaro', 'Stag and Spey'],
      },
      {
        name: 'Mackenzie',
        suburb: ['Albury', 'Fairlie', 'Lake Tekapo', 'Mt Cook', 'Twizel'],
      },
      {
        name: 'Selwyn',
        suburb: [
          "Arthur's Pass",
          'Burnham',
          'Castle Hill',
          'Craigieburn',
          'Darfield',
          'Doyleston',
          'Dunsandel',
          'Glentunnel',
          'Hororata',
          'Kirwee',
          'Lake Coleridge',
          'Lake Pearson',
          'Lansdowne',
          'Leeston',
          'Lincoln',
          'Malvern Hills',
          'Motukarara',
          'Prebbleton',
          'Rolleston',
          'Sheffield',
          'Southbridge',
          'Springfield',
          'Springston',
          'Tai Tapu',
          'Waddington',
          'Weedons',
          'West Melton',
          'Windwhistle',
        ],
      },
      {
        name: 'Timaru',
        suburb: [
          'Clandeboye',
          'Fairview',
          'Geraldine',
          'Gleniti',
          'Glenwood',
          'Hadlow',
          'Highfield',
          'Kensington',
          'Levels',
          'Maori Hill',
          'Marchwiel',
          'Mesopotamia',
          'Pareora',
          'Parkside',
          'Pleasant Point',
          'Redruth',
          'Rosewill',
          'Seaview',
          'Smithfield',
          'Temuka',
          'Timaru Central',
          'Waimataitai',
          'Washdyke',
          'Watlington',
          'West End',
          'Winchester',
        ],
      },
      {
        name: 'Waimakariri',
        suburb: [
          'Ashley',
          'Clarkville',
          'Cust',
          'Fernside',
          'Glentui',
          'Kaiapoi',
          'Loburn',
          'Ohoka',
          'Okuku',
          'Oxford',
          'Pegasus',
          'Rangiora',
          'Ravenswood',
          'Sefton',
          'Swannanoa',
          'The Pines Beach',
          'Tuahiwi',
          'Waikuku',
          'Waikuku Beach',
          'West Eyreton',
          'Woodend',
        ],
      },
      {
        name: 'Waimate',
        suburb: [
          'Elephant Hill',
          'Glenavy',
          'Hakataramea',
          'Hakataramea Valley',
          'Hunter',
          'Hunters Hills',
          'Ikawai',
          'Kirkliston',
          'Makikihi',
          'Maungati',
          'Morven',
          'Otaio',
          'Saint Andrews',
          'Waihao Downs',
          'Waihaorunga',
          'Waimate',
          'Waitangi',
        ],
      },
    ],
  },
  {
    region: 'Otago',
    district: [
      {
        name: 'Central Otago',
        suburb: [
          'Alexandra',
          'Bannockburn',
          'Clyde',
          'Cromwell',
          'Ettrick',
          'Lindis Pass',
          'Naseby',
          'Nevis',
          'Omakau',
          'Ophir',
          'Oturehau',
          'Paerau',
          'Queensberry',
          'Ranfurly',
          'Roxburgh',
          'Roxburgh East',
        ],
      },
      {
        name: 'Clutha',
        suburb: [
          'Balclutha',
          'Chaslands',
          'Clinton',
          'Greenfield',
          'Kaitangata',
          'Kaka Point',
          'Lawrence',
          'Milton',
          'Owaka',
          'Papatowai',
          'Pounawea',
          'South Otago Coastal',
          'South Otago Country',
          'Taieri Mouth',
          'Tapanui',
          'Waihola',
          'Waitahuna',
        ],
      },
      {
        name: 'Dunedin',
        suburb: [
          'Abbotsford',
          'Allanton',
          'Andersons Bay',
          'Aramoana',
          'Balaclava',
          'Belleknowes',
          'Berwick',
          'Berwick Forest',
          'Blackhead',
          'Bradford',
          'Brighton',
          'Broad Bay',
          'Brockville',
          'Burnside',
          'Calton Hill',
          'Careys Bay',
          'Caversham',
          'Chain Hills',
          'Clyde Hill',
          'Company Bay',
          'Concord',
          'Corstorphine',
          'Dalmore',
          'Deborah Bay',
          'Dunedin Central',
          'East Otago',
          'East Taieri',
          'Fairfield',
          'Forbury',
          'Glenleith',
          'Glenross',
          'Green Island',
          'Halfway Bush',
          'Harington Point',
          'Harwood',
          'Helensburgh',
          'Henley',
          'Highcliff',
          'Hyde',
          'Kaikorai',
          'Karitane',
          'Kenmure',
          'Kensington',
          'Kew',
          'Leith Valley',
          'Liberton',
          'Lookout Point',
          'Macandrew Bay',
          'Maia',
          'Maori Hill',
          'Maryhill',
          'Middlemarch',
          'Mornington',
          'Mosgiel',
          'Mount Cargill',
          'Mount Grand',
          'Musselburgh',
          'Normanby',
          'North Dunedin',
          'North East Valley',
          'North Taieri',
          'Ocean Grove',
          'Ocean View',
          'Opoho',
          'Outram',
          'Pine Hill',
          'Port Chalmers',
          'Portobello',
          'Purakaunui',
          'Ravensbourne',
          'Roseneath',
          'Roslyn',
          'Saddle Hill',
          'Saint Clair',
          'Saint Kilda',
          'Saint Leonards',
          'Sawyers Bay',
          'Scroggs Hill',
          'Shiel Hill',
          'South Dunedin',
          'Tainui',
          'The Cove',
          'The Glen',
          'Upper Waitati',
          'Vauxhall',
          'Waikouaiti',
          'Waipori Falls',
          'Waitati',
          'Wakari',
          'Waldronville',
          'Warrington',
          'Waverley',
          'Westwood',
          'Woodhaugh',
        ],
      },
      {
        name: 'Queenstown Lake',
        suburb: [
          'Albert Town',
          'Arrow Junction',
          'Arrowtown',
          'Arthurs Point',
          'Ben Lomond',
          'Boydtown',
          'Cardrona',
          'Closeburn',
          'Crown Terrace',
          'Dalefield',
          'Fernhill',
          'Frankton',
          'Gibbston',
          'Glenorchy',
          'Hawea Flat',
          'Jacks Point',
          'Kelvin Heights',
          'Kingston',
          'Lake Hayes Estate',
          'Lower Shotover',
          'Queenstown',
          'Queenstown Hill',
          'Sunshine Bay',
          'Treble Cone',
          'Wanaka',
        ],
      },
      {
        name: 'Waitaki',
        suburb: [
          'Deborah',
          'Duntroon',
          'Enfield and Surrounds',
          'Five Forks and Surrounds',
          'Hampden',
          'Herbert',
          'Kakanui',
          'Kurow',
          'Lake Ohau',
          'Maheno',
          'Moeraki',
          'North Otago',
          'Oamaru',
          'Omarama',
          'Otematata',
          'Palmerston',
          'Pukeuri',
          'Richmond',
          'Totara',
          'Waianakarua',
          'Waihemo',
          'Weston',
        ],
      },
    ],
  },
  {
    region: 'Southland',
    district: [
      {
        name: 'Gore',
        suburb: ['Gore', 'Mataura', 'Pukerau', 'Waikaka'],
      },
      {
        name: 'Invercargill',
        suburb: [
          'Appleby',
          'Ascot',
          'Avenal',
          'Awarua',
          'Bluff',
          'Clifton',
          'Georgetown',
          'Gladstone',
          'Glengarry',
          'Grasmere',
          'Hargest',
          'Hawthorndale',
          'Heidelberg',
          'Invercargill',
          'Kew',
          'Kingswell',
          'Lorneville',
          'Makarewa',
          'Mill Road',
          'Myross Bush',
          'Newfield',
          'Oreti Beach',
          'Otatara',
          'Prestonville',
          'Richmond',
          'Rockdale',
          'Rosedale',
          'Roslyn Bush',
          'Ryal Bush',
          'Strathern',
          'Tisbury',
          'Turnbull Thomson Park',
          'Underwood',
          'Waikiwi',
          'Waimatua',
          'Waverley',
          'West Plains',
          'Windsor',
        ],
      },
      {
        name: 'Southland',
        suburb: [
          'Balfour',
          'Castlerock',
          'Colac Bay',
          'Dipton',
          'Edendale',
          'Garston',
          'Halfmoon Bay',
          'Hedgehope',
          'Lumsden',
          'Manapouri',
          'Mossburn',
          'Nightcaps',
          'Ohai',
          'Orepuki',
          'Otautau',
          'Riversdale',
          'Riverton',
          'Stewart Island',
          'Te Anau',
          'Te Anau Surrounds',
          'Thornbury',
          'Tuatapere',
          'Waianiwa',
          'Waikaia',
          'Wallacetown',
          'Winton',
          'Woodlands',
          'Wyndham',
        ],
      },
    ],
  },
  {
    region: 'Chatham Islands',
    district: [
      {
        name: 'Chatham Island',
        suburb: [],
      },
      {
        name: 'Pitt Island',
        suburb: [],
      },
    ],
  },
];

export const TEST_LOCATIONS = [
  {
    region: 'Confidential',
    district: [
      {
        name: 'Confidential',
        suburb: ['Confidential'],
      },
    ],
  },
];

const locations =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? LOCATIONS
    : [...LOCATIONS, ...TEST_LOCATIONS];

export default locations;
