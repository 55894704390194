/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useState } from 'react';

import { CheckCircleIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  chakra,
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';
import ImageViewer from 'react-simple-image-viewer';

import DraftSendEmail from 'components/modules/DraftSendEmail';
import Avatar from 'components/primitives/Avatar';
import AppButton from 'components/primitives/Button';
import Typography from 'components/primitives/Typography';
import { YELSA_APP } from 'constants/links';
import { routesSettings } from 'constants/routes';
import { useTheme } from 'utils/theme';

import DraftSellEdit from '../DraftSellEdit';
import { MODE, DraftSellGeneratedProps } from './DraftSell.props';
import { displayFields } from './DraftSell.utils';

const SellListingView = (props: DraftSellGeneratedProps): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const toast = useToast();
  const [mode, setMode] = useState(MODE.DEFAULT);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const onViewProfile = () => {
    if (props.data.user)
      history.push({
        pathname: routesSettings.MAIN_USERS.path,
        search: `?id=${props.data?.user.id}`,
      });
  };

  const onViewSellingProfile = () => {
    if (props.data)
      history.push({
        pathname: routesSettings.MAIN_LISTINGS.path,
        search: `?id=${props.data.id}`,
      });
  };

  const copySellerLink = () => {
    const sellerLink = `${YELSA_APP}/quick-listing?shortId=${props.data.shortId}&user=seller`;
    try {
      navigator.clipboard.writeText(sellerLink).then(() => {
        toast({
          title: 'Link copied',
        });
      });
    } catch (err) {
      console.log(
        '🚀 ~ file: DraftSell.view.tsx ~ line 68 ~ copySellerLink ~ err',
        err
      );
    }
  };

  const copyAgentLink = () => {
    const agentLink = `${YELSA_APP}/quick-listing?shortId=${props.data.shortId}&user=agent`;
    try {
      navigator.clipboard.writeText(agentLink).then(() => {
        toast({
          title: 'Link copied',
        });
      });
    } catch (err) {
      console.log(
        '🚀 ~ file: DraftSell.view.tsx ~ line 82 ~ copyAgentLink ~ err',
        err
      );
    }
  };

  if (!props.data) {
    return <Flex>Something went wrong</Flex>;
  }

  if (mode === MODE.EDIT) {
    return (
      <DraftSellEdit
        onEndEdit={() => {
          setMode(MODE.DEFAULT);
          props.refetch();
          setTimeout(() => {
            window.scrollTo({
              top: 0,
            });
          }, 300);
        }}
        data={props.data}
      />
    );
  }

  return (
    <Flex flexDirection="column">
      <HStack spacing={3} alignItems="flex-start">
        <Flex flexDir="column" border="1px solid #ddd" p={4} flex={4}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #ddd"
            m={-4}
            px={4}
            py={2}
            mb={4}
          >
            <Typography variant="type8" color="shade5" weight="600">
              SELLING PROFILE
            </Typography>
            {props.data.claimedAt ? (
              <Button
                onClick={onViewSellingProfile}
                border={`1px solid ${theme.colors.primary}`}
                backgroundColor="#fff"
              >
                <Typography color="primary" weight="600">
                  View Selling Profile
                </Typography>
              </Button>
            ) : (
              <Flex>
                <Flex ml={2}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={
                        <ChevronDownIcon color={theme.colors.primary} />
                      }
                      border={`1px solid ${theme.colors.primary}`}
                      backgroundColor="#fff"
                    >
                      <Typography color="primary" weight="600">
                        Actions
                      </Typography>
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => setMode(MODE.EDIT)}>
                        Edit
                      </MenuItem>
                      <MenuItem onClick={props.onDelete}>Delete</MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </Flex>
            )}
          </Flex>
          <Typography variant="type10" color="shade17">
            PROPERTY PHOTOS
          </Typography>
          <HStack spacing={2} mt={2} mb={4} width="100%" overflowX="auto">
            {[
              ...(props.data!.mainImage ? [props.data!.mainImage] : []),
              ...props.data!.images,
            ].map((imgl, i) => (
              <Image
                onClick={() => openImageViewer(i)}
                key={imgl.path}
                borderRadius="5px"
                height="80px"
                src={imgl.thumbnail}
              />
            ))}
          </HStack>
          <Typography variant="type10" color="shade17">
            HEADING
          </Typography>
          <Flex mt={2} mb={4} wordBreak="break-word">
            <Typography variant="type8" color="shade5">
              {props.data?.heading || '-'}
            </Typography>
          </Flex>
          <Typography variant="type10" color="shade17">
            DESCRIPTION
          </Typography>
          <Flex mt={2} mb={4} wordBreak="break-word">
            <Typography variant="type8" color="shade5">
              “{props.data?.description || '-'}”
            </Typography>
          </Flex>
          <SimpleGrid columns={2}>
            {displayFields.map((field, i) => (
              <VStack
                key={`${field.field}${i}`}
                spacing={2}
                alignItems="flex-start"
                mt={5}
              >
                <Typography variant="type10" color="shade17">
                  {field.label}
                </Typography>
                <Typography
                  style={{ whiteSpace: 'pre-wrap' }}
                  variant="type8"
                  color="shade5"
                >
                  {field.getValue(props.data!)}
                </Typography>
              </VStack>
            ))}
          </SimpleGrid>
        </Flex>
        <Flex flexDir="column" flex={3}>
          {props.data.user ? (
            <Flex flexDir="column" border="1px solid #ddd" p={4}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #ddd"
                m={-4}
                px={4}
                py={2}
                mb={4}
              >
                <Typography variant="type8" color="shade5" weight="600">
                  SELLER DETAILS
                </Typography>
                <Button
                  onClick={onViewProfile}
                  border={`1px solid ${theme.colors.primary}`}
                  backgroundColor="#fff"
                >
                  <Typography color="primary" weight="600">
                    View Profile
                  </Typography>
                </Button>
              </Flex>
              <Flex>
                <Avatar
                  size={80}
                  src={props.data.user.profilePic?.medium || ''}
                />
                <VStack spacing={2} ml={5} alignItems="flex-start">
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data.user.firstName} {props.data.user.lastName}
                  </Typography>
                  <Typography variant="type9" color="shade5">
                    {props.data.user.email}
                  </Typography>
                  <Typography variant="type9" color="shade5">
                    {props.data.user.phone}
                    {props.data.user.phoneVerified && (
                      <chakra.span ml={2}>
                        <CheckCircleIcon color={theme.colors.special1} />
                      </chakra.span>
                    )}
                  </Typography>
                </VStack>
              </Flex>
              <HStack mt={5} mx={-2} mb={-2} spacing={1}>
                <VStack
                  width="100%"
                  spacing={2}
                  py={2}
                  backgroundColor={theme.colors.shade4}
                >
                  <Typography variant="type10" color="shade1" weight="500">
                    SELLING
                  </Typography>
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data.user.userPropertiesCounts?.selling}
                  </Typography>
                </VStack>
                <VStack
                  width="100%"
                  spacing={2}
                  py={2}
                  backgroundColor={theme.colors.shade4}
                >
                  <Typography variant="type10" color="shade1" weight="500">
                    BUYING
                  </Typography>
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data.user.userPropertiesCounts?.buying}
                  </Typography>
                </VStack>
                <VStack
                  width="100%"
                  spacing={2}
                  py={2}
                  backgroundColor={theme.colors.shade4}
                >
                  <Typography variant="type10" color="shade1" weight="500">
                    FACILITATING
                  </Typography>
                  <Typography variant="type8" color="shade5" weight="600">
                    {props.data.user.userPropertiesCounts?.facilitating}
                  </Typography>
                </VStack>
              </HStack>
            </Flex>
          ) : (
            <Flex w="100%" flexDir="column" border="1px solid #ddd">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #ddd"
                px={4}
                py="19px"
              >
                <Typography variant="type8" color="shade5" weight="600">
                  OWNER DETAILS
                </Typography>
              </Flex>
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                px="32px"
                py="30px"
              >
                <Typography color="shade17" variant="type10" align="center">
                  Share this unique link to a Seller or Agent to claim this
                  listing
                </Typography>
                <Flex
                  my={3}
                  mt={5}
                  bg="#F9F7FF"
                  py={3}
                  justifyContent="center"
                  rounded="5px"
                >
                  <Typography variant="type10" align="center" component="span">
                    {YELSA_APP}/quick-listing?shortId={props.data.shortId}&
                    &user=seller
                  </Typography>
                </Flex>
                <AppButton fullwidth onClick={copySellerLink} variant="small">
                  Copy Link for Seller
                </AppButton>
                <Flex
                  my={3}
                  mt={5}
                  bg="#F9F7FF"
                  py={3}
                  justifyContent="center"
                  rounded="5px"
                >
                  <Typography variant="type10" align="center" component="span">
                    {YELSA_APP}/quick-listing?shortId={props.data.shortId}
                    &user=agent
                  </Typography>
                </Flex>
                <AppButton fullwidth onClick={copyAgentLink} variant="small">
                  Copy Link for Agent
                </AppButton>
              </Flex>
            </Flex>
          )}

          <Flex w="100%" flexDir="column" border="1px solid #ddd" mt={3}>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #ddd"
              px={4}
              py="19px"
            >
              <Typography variant="type8" color="shade5" weight="600">
                SEND EMAIL
              </Typography>
            </Flex>

            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              px="32px"
              py="30px"
            >
              <DraftSendEmail
                id={props.data.id}
                data={props.data.emailLogs}
                hideForm={!!props.data.claimedAt}
              />
            </Flex>
          </Flex>
        </Flex>
      </HStack>
      {isViewerOpen && (
        <ImageViewer
          backgroundStyle={{ zIndex: 3000 }}
          src={[
            ...(props.data!.mainImage ? [props.data!.mainImage] : []),
            ...props.data!.images,
          ].map((img) => img.original)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
        />
      )}
    </Flex>
  );
};

export default SellListingView;
