import React from 'react';

import { useTheme } from 'utils/theme';

import { SVGComponentProps } from './SVG.props';

const Close = (props: SVGComponentProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, color, accent } = props;

  return (
    <svg width={width || '10'} height={height || '10'} viewBox="0 0 10 10">
      <path
        d="M6.65 5l3.094-3.093a.875.875 0 000-1.237L9.331.257a.875.875 0 00-1.237 0L5 3.351 1.907.256a.875.875 0 00-1.237 0L.256.669a.875.875 0 000 1.237L3.351 5 .257 8.094a.875.875 0 000 1.237l.412.412a.875.875 0 001.237 0L5 6.65l3.094 3.094a.875.875 0 001.237 0l.412-.412a.875.875 0 000-1.237zm0 0"
        fill={theme.colors[color || 'shade7']}
      />
    </svg>
  );
};

export default Close;
