import React from 'react';

import { useField, useFormikContext } from 'formik';

import Select from '../Select';
import { FormikSelectProps } from './FormikSelect.props';

const FormikSelectView = ({
  fieldName,
  error,
  ...props
}: FormikSelectProps): JSX.Element => {
  const [{ value }, meta] = useField(fieldName);
  const formik = useFormikContext();

  return (
    <Select
      {...props}
      value={value}
      onChange={(val) => formik.setFieldValue(fieldName, val.target.value)}
      error={meta.error || error}
    />
  );
};

export default FormikSelectView;
