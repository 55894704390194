/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { parseISO } from 'date-fns';
import { isEmpty } from 'ramda';
import * as Yup from 'yup';

import { SellDetailsRequest } from 'api/properties';
import locations from 'constants/locations';
import {
  CONFIDENTIAL_REGION,
  PRICE_BY_NEGOTIATION,
  SellPropertyStatus,
  SET_DATE_OF_SALE,
  typesThatExcludeBedroom,
  typesThatExcludeGarage,
} from 'constants/property';
import { SellFormValues } from 'types/Forms';
import {
  District,
  PropertyLocation,
  Region,
  SellListingSummary,
} from 'types/Property';

import { FormProps } from './SellListingEdit.props';

export const Schema = Yup.object().shape({
  propertyType: Yup.string().required('This field is required.'),
  bedrooms: Yup.string().when('propertyType', {
    is: (propertyType: any) => !typesThatExcludeBedroom.includes(propertyType),
    then: Yup.string().required('This field is required.'),
  }),
  garage: Yup.string().when('propertyType', {
    is: (propertyType: any) => !typesThatExcludeGarage.includes(propertyType),
    then: Yup.string().required('This field is required.'),
  }),
  landArea: Yup.number().positive('Invalid field.'),
  landUnit: Yup.string().when('landArea', {
    is: (la: any) => Boolean(la),
    then: Yup.string().required('This field is required.'),
  }),
  marketPrice: Yup.string().required('This field is required.'),
  preferredPrice: Yup.string().when('marketPrice', {
    is: (marketPrice: string) =>
      [PRICE_BY_NEGOTIATION, SET_DATE_OF_SALE].includes(marketPrice),
    then: Yup.string().required('This field is required.'),
  }),
  marketingMethod: Yup.string().when('marketPrice', {
    is: (marketPrice: string) => marketPrice === SET_DATE_OF_SALE,
    then: Yup.string().required('This field is required.'),
  }),
  closingDateTime: Yup.string().when('marketPrice', {
    is: (marketPrice: string) => marketPrice === SET_DATE_OF_SALE,
    then: Yup.string().required('This field is required.'),
  }),
  unlessSoldPrior: Yup.boolean().when('marketPrice', {
    is: (marketPrice: string) => marketPrice === SET_DATE_OF_SALE,
    then: Yup.boolean().required('This field is required.'),
  }),
  region: Yup.string().required('This field is required.'),
  district: Yup.string().when('region', {
    is: (region: any) => region !== CONFIDENTIAL_REGION,
    then: Yup.string().required('This fields is required.'),
  }),
  street: Yup.string().when('region', {
    is: (region: any) => region !== CONFIDENTIAL_REGION,
    then: Yup.string().required('This field is required.'),
  }),
  description: Yup.string().required('This field is required.'),
  heading: Yup.string().required('This field is required.'),
  availableAt: Yup.string().when('status', {
    is: (status: SellPropertyStatus) =>
      status === SellPropertyStatus.PRE_REGISTRATION,
    then: Yup.string().required('This field is required.'),
  }),
});

export const transformToFormValues = (data: SellListingSummary): FormProps => ({
  region: data.location.region.toLowerCase(),
  district: data.location.district[0].name.toLowerCase(),
  suburb: data.location.district[0].suburb[0]
    ? data.location.district[0].suburb[0].toLowerCase()
    : '',
  street: data.title,
  propertyType: data.propertyType,
  landArea: data.landArea.to || undefined,
  shortId: data.shortId,
  landUnit: data.landUnit,
  marketPrice: data.marketPrice,
  displayPrice: data.displayPrice,
  preferredPrice: data.preferredPrice || undefined,
  marketingMethod: data.marketingMethod || undefined,
  closingDateTime: data.closingDateTime || undefined,
  unlessSoldPrior: data.unlessSoldPrior,
  status: data.status as SellPropertyStatus,
  isPrivate: data.isPrivate,
  bedrooms: data.bedrooms.to,
  garage: data.garage.to,
  fencing: data.fencing,
  otherFeatures: data.otherFeatures
    ? data.otherFeatures.map((v) => v.toLowerCase())
    : [],
  description: data.description || undefined,
  heading: data.heading || undefined,
  livingAreas: data.livingAreas,
  bathroom: data.bathroom,
  toilets: data.toilets,
  glazing: data.glazing,
  heating: data.heating ? data.heating.map((v) => v.toLowerCase()) : [],
  availableAt: data.availableAt ? parseISO(data.createdAt) : undefined,
});

export const transformToDetails = (
  values: SellFormValues
): SellDetailsRequest => {
  const suburbs = getSuburbs(values.region, values.district);

  return {
    location: {
      region: values.region,
      district: [
        {
          name: values.district,
          suburb: isEmpty(suburbs) ? [] : [values.suburb as string],
        },
      ],
    },
    street: values.street,
    propertyType: values.propertyType,
    landArea: values.landArea,
    landUnit: values.landUnit,
    marketPrice: values.marketPrice,
    displayPrice: [SET_DATE_OF_SALE, PRICE_BY_NEGOTIATION].includes(
      values.marketPrice
    )
      ? ''
      : values.displayPrice,
    preferredPrice: [SET_DATE_OF_SALE, PRICE_BY_NEGOTIATION].includes(
      values.marketPrice
    )
      ? values.preferredPrice
      : undefined,
    marketingMethod:
      values.marketPrice === SET_DATE_OF_SALE
        ? values.marketingMethod
        : undefined,
    closingDateTime:
      values.marketPrice === SET_DATE_OF_SALE && values.closingDateTime
        ? values.closingDateTime
        : undefined,
    unlessSoldPrior:
      values.marketPrice === SET_DATE_OF_SALE
        ? values.unlessSoldPrior
        : undefined,
    status: values.status,
    shortId: values.shortId,
    isPrivate: values.isPrivate,
    bedrooms: values.bedrooms,
    garage: values.garage,
    fencing: values.fencing,
    otherFeatures: values.otherFeatures
      ? values.otherFeatures.map((v: string) => v.toLowerCase())
      : [],
    description: values.description,
    heading: values.heading,
    livingAreas: values.livingAreas,
    bathroom: values.bathroom,
    toilets: values.toilets,
    glazing: values.glazing,
    heating: values.heating
      ? values.heating.map((v: string) => v.toLowerCase())
      : [],
  };
};

export const getRegions = (): { label: string; value: string }[] => {
  return locations.map((l) => ({
    label: l.region,
    value: l.region.toLowerCase(),
  }));
};

export const getDistricts = (
  region: string
): { label: string; value: string }[] => {
  if (!region)
    return [
      {
        label: '',
        value: '',
      },
    ];
  const loc: PropertyLocation[] = locations;

  const currentRegion: Region = loc.find(
    (l) => region.toLowerCase() === l.region.toLowerCase()
  ) || {
    region: '',
    district: [{ name: '', suburb: [''] }],
  };

  return currentRegion.district.map((d: { name: string }) => ({
    label: d.name,
    value: d.name.toLowerCase(),
  }));
};

export const getSuburbs = (
  region: string,
  district: string
): { label: string; value: string }[] => {
  if (!region || !district)
    return [
      {
        label: '',
        value: '',
      },
    ];
  const loc: PropertyLocation[] = locations;

  const currentRegion: Region = loc.find(
    (l) => region.toLowerCase() === l.region.toLowerCase()
  ) || {
    region: '',
    district: [{ name: '', suburb: [''] }],
  };

  const currentDistrict: District = currentRegion.district.find(
    (d) => district.toLowerCase() === d.name.toLowerCase()
  ) || { name: '', suburb: [''] };

  return currentDistrict.suburb.map((s) => ({
    label: s,
    value: s.toLowerCase(),
  }));
};
