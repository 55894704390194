import React from 'react';

import Spinner from 'components/primitives/Spinner';

import { ButtonPublicProps } from './Button.props';
import { StyledButton, Text } from './Button.style';

const Button = ({
  children,
  variant = 'regular',
  kind = 'primary',
  outline = false,
  LeftComponent,
  RightComponent,
  typographyProps,
  disabled,
  loading,
  onClick,
  propagateEvent,
  ...props
}: ButtonPublicProps): JSX.Element => {
  const d = () => {
    return {
      width: variant === 'regular' ? 19 : 17,
      height: variant === 'regular' ? 19 : 17,
    };
  };

  return (
    <StyledButton
      {...props}
      variant={variant}
      kind={kind}
      outline={outline}
      disabled={loading || disabled}
      type={props.type || 'button'}
      onClick={(event) => {
        !propagateEvent && event.stopPropagation();
        onClick && onClick(event);
      }}
    >
      {loading ? (
        <Spinner width={d().width} height={d().height} />
      ) : (
        <>
          {LeftComponent || null}
          <Text
            kind={kind}
            variant={variant === 'regular' ? 'type8' : 'type9'}
            buttonVariant={variant}
            outline={outline}
            weight="700"
            {...typographyProps}
            color={
              outline
                ? typographyProps?.color || props.color
                : typographyProps?.color
            }
          >
            {children}
          </Text>
          {RightComponent || null}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
