import React, { useState } from 'react';

import { ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Collapse, Flex, HStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

import properties from 'api/properties';
import AppButton from 'components/primitives/Button';
import Typography from 'components/primitives/Typography';
import { FETCH_LISTING_BUYER_ADVISORY } from 'constants/queries';
import { BuyerAdvisory } from 'types/Property';

import { SellListingOpenHomesProps } from './SellListingAdvisory.props';

const columnGroup1 = [
  'Schedule',
  'Advised',
  'Interested',
  'Uninterested',
  'Did Not Respond',
  'Created On',
];

const columnGroup2 = [' ', 'Buyer', '', ''];

const Item = (advisory: BuyerAdvisory) => {
  const [opened, setOpened] = useState<'YES' | 'NO' | 'PENDING' | 'TOTAL'>();

  const matches = advisory.responses.filter((d) => {
    if (opened === 'TOTAL') return true;
    return d.status === opened;
  });

  return (
    <>
      <Flex backgroundColor="shade18">
        <Flex flex={2} p={2}>
          <Typography variant="type8" color="shade5">
            {format(new Date(advisory.endAt), 'MM/dd/y k:mma')}
          </Typography>
        </Flex>
        <Flex flex={1} p={2}>
          <Typography variant="type8" color="shade5">
            {advisory.TOTAL}
          </Typography>
          {advisory.TOTAL > 0 && (
            <Flex
              pt={0.5}
              cursor="pointer"
              onClick={() =>
                setOpened(opened === 'TOTAL' ? undefined : 'TOTAL')
              }
            >
              {opened === 'TOTAL' ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Flex>
          )}
        </Flex>
        <Flex flex={1} p={2}>
          <Typography variant="type8" color="shade5">
            {advisory.YES}
          </Typography>
          {advisory.YES > 0 && (
            <Flex
              pt={0.5}
              cursor="pointer"
              onClick={() => setOpened(opened === 'YES' ? undefined : 'YES')}
            >
              {opened === 'YES' ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Flex>
          )}
        </Flex>
        <Flex flex={1} p={2}>
          <Typography variant="type8" color="shade5">
            {advisory.NO}
          </Typography>
          {advisory.NO > 0 && (
            <Flex
              pt={0.5}
              cursor="pointer"
              onClick={() => setOpened(opened === 'NO' ? undefined : 'NO')}
            >
              {opened === 'NO' ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </Flex>
          )}
        </Flex>
        <Flex flex={1} p={2}>
          <Typography variant="type8" color="shade5">
            {advisory.PENDING}
          </Typography>
          {advisory.PENDING > 0 && (
            <Flex
              pt={0.5}
              cursor="pointer"
              onClick={() =>
                setOpened(opened === 'PENDING' ? undefined : 'PENDING')
              }
            >
              {opened === 'PENDING' ? (
                <ChevronDownIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </Flex>
          )}
        </Flex>
        <Flex flex={1} p={2}>
          <Typography variant="type8" color="shade5">
            {advisory.message}
          </Typography>
        </Flex>
      </Flex>
      <Collapse in={!!opened} animateOpacity>
        <Flex flexDirection="column">
          <Flex backgroundColor="shade12">
            {columnGroup2.map((col, i) => (
              <Flex key={col} flex={i !== 2 ? 2 : 1} py={2} px={1}>
                <Typography variant="type9" weight="600" color="shade5">
                  {col}
                </Typography>
              </Flex>
            ))}
          </Flex>
          {(matches || []).map((m) => (
            <Flex key={m.buyerId.id} backgroundColor="shade18">
              <Flex flex={2} />
              <Flex flex={2} p={2}>
                <Typography variant="type8" color="shade5">
                  {m.buyerId.firstName} {m.buyerId.lastName}
                </Typography>
              </Flex>
              <Flex flex={3} />
            </Flex>
          ))}
        </Flex>
      </Collapse>
    </>
  );
};

const SellListingAdvisoryView = (
  props: SellListingOpenHomesProps
): JSX.Element => {
  const { data = [] } = useQuery(
    `${FETCH_LISTING_BUYER_ADVISORY}${props.id}`,
    () => properties.getBuyerAdvisory(props.id)
  );

  return (
    <>
      <Flex mt={3} flexDir="column" border="1px solid #ddd">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={4}
          borderBottom="1px solid #ddd"
        >
          <Typography variant="type8" color="shade5" weight="600">
            BUYER ADVISORY
          </Typography>
        </Flex>
        <Flex flexDirection="column">
          <Flex backgroundColor="shade12">
            {columnGroup1.map((col, i) => (
              <Flex key={col} flex={i === 0 ? 2 : 1} p={2}>
                <Typography variant="type9" weight="600" color="shade5">
                  {col}
                </Typography>
              </Flex>
            ))}
          </Flex>
          {data.map((d) => (
            <Item key={d._id} {...d} />
          ))}
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end" mt={5}>
        <HStack spacing={5}>
          <AppButton onClick={props.onBack} kind="secondary" variant="small">
            Go Back
          </AppButton>
        </HStack>
      </Flex>
    </>
  );
};

export default SellListingAdvisoryView;
